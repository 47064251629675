import WarningStatus from './ComponentType/WarningStatus'

const staticNumberWarningCheck = ({
  sensorNumberStatus,
  controlRange,
  threshold,
}) => {
  const controlUpper = controlRange?.upper
  const controlLower = controlRange?.lower
  // Threshold = Hard Alarm
  const thresholdUpper = threshold?.on
  const thresholdLower = threshold?.off
  const thresholdEnable = threshold?.enable
  if (!sensorNumberStatus) {
    return WarningStatus.ERROR
  }

  if (thresholdEnable) {
    if (sensorNumberStatus > thresholdUpper) {
      return WarningStatus.DANGER_HIGH
    }
    if (
      sensorNumberStatus < thresholdUpper &&
      sensorNumberStatus > controlUpper
    ) {
      return WarningStatus.WARNING_HIGH
    }
    if (
      sensorNumberStatus < controlLower &&
      sensorNumberStatus > thresholdLower
    ) {
      return WarningStatus.WARNING_LOW
    }
    if (sensorNumberStatus < thresholdLower) {
      return WarningStatus.DANGER_LOW
    } else {
      return WarningStatus.NORMAL
    }
  } else {
    if (sensorNumberStatus > controlUpper) {
      return WarningStatus.WARNING_HIGH
    }
    if (sensorNumberStatus < controlLower) {
      return WarningStatus.WARNING_LOW
    } else {
      return WarningStatus.NORMAL
    }
  }
}

export default staticNumberWarningCheck

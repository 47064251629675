import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderDataFiber = (data, sampleMap) => {
  const content = []
  _.map(sampleMap, (Sample, index) => {
    let indexTime = false
    if (data?.length === 0) {
      return content.push([
        { text: 'Fiber No. ..........', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    } else {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (Sample === eachData?.Process) {
          indexTime = true
          content.push([
            { text: eachData?.Sample || '' },
            { text: eachData?.Moisture || '', style: 'tableContent' },
            { text: eachData?.OilNos || '', style: 'tableContent' },
            { text: eachData?.DryBasis || '', style: 'tableContent' },
            { text: eachData?.OilDry || '', style: 'tableContent' },
            { text: eachData?.OilBasis || '', style: 'tableContent' },
            { text: eachData?.Remark || '', style: 'tableContent' },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: 'Fiber No. ..........', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    }
  })
  return content
}

const renderDataKernel = (data, KernelMap) => {
  const content = []
  let indexTime = false
  if (data?.length === 0) {
    content.push(
      [
        { text: 'Step A (Mix)', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ],
      [
        { text: 'Kernel Screw No. ..........', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ],
      [
        { text: 'Kernel Screw No. ..........', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ],
      [
        { text: 'Kernel Screw No. ..........', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ]
    )
    return content
  } else {
    _.map(KernelMap, (Sample, index) => {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (Sample === eachData?.Process) {
          indexTime = true
          content.push([
            { text: eachData?.Sample || '' },
            { text: eachData?.Moisture || '', style: 'tableContent' },
            { text: eachData?.OilNos || '', style: 'tableContent' },
            { text: eachData?.DryBasis || '', style: 'tableContent' },
            { text: eachData?.OilDry || '', style: 'tableContent' },
            { text: eachData?.OilBasis || '', style: 'tableContent' },
            { text: eachData?.Remark || '', style: 'tableContent' },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: 'Kernel Screw No. ..........', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    })
  }
  return content
}

const renderdataDecanter = (data, DecenterMap) => {
  const content = []

  let indexTime = false
  if (data?.length === 0) {
    content.push(
      [
        { text: 'Before Decanter N0.1', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ],
      [
        { text: 'Before Decanter N0.2', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ],
      [
        { text: 'Sludge Decanter N0.1', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ],
      [
        { text: 'Sludge Decanter N0.2', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ]
    )
    return content
  } else {
    _.map(DecenterMap, (Sample, index) => {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (Sample === eachData?.Process) {
          indexTime = true
          content.push([
            { text: eachData?.Sample || '' },
            { text: eachData?.Moisture || '', style: 'tableContent' },
            { text: eachData?.OilNos || '', style: 'tableContent' },
            { text: eachData?.DryBasis || '', style: 'tableContent' },
            { text: eachData?.OilDry || '', style: 'tableContent' },
            { text: eachData?.OilBasis || '', style: 'tableContent' },
            { text: eachData?.Remark || '', style: 'tableContent' },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    })
  }
  return content
}

const renderdataSCD = (data, SCDMap) => {
  const content = []

  let indexTime = false
  if (data?.length === 0) {
    content.push(
      [
        { text: 'SCD N0.1', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ],
      [
        { text: 'SCD N0.2', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ]
    )
    return content
  } else {
    _.map(SCDMap, (Sample, index) => {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (Sample === eachData?.Process) {
          indexTime = true
          content.push([
            { text: eachData?.Sample || '' },
            { text: eachData?.Moisture || '', style: 'tableContent' },
            { text: eachData?.OilNos || '', style: 'tableContent' },
            { text: eachData?.DryBasis || '', style: 'tableContent' },
            { text: eachData?.OilDry || '', style: 'tableContent' },
            { text: eachData?.OilBasis || '', style: 'tableContent' },
            { text: eachData?.Remark || '', style: 'tableContent' },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    })
  }
  return content
}

const renderdataFeed = (data, FeedterMap) => {
  const content = []

  let indexTime = false
  if (data?.length === 0) {
    content.push(
      [
        { text: 'Feed', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ],
      [
        { text: 'Separater N0.1', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ],
      [
        { text: 'Separater N0.2', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ],
      [
        { text: 'Separater N0.3', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ],
      [
        { text: 'Separater N0.4', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ]
    )
    return content
  } else {
    _.map(FeedterMap, (Sample, index) => {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (Sample === eachData?.Process) {
          indexTime = true
          content.push([
            { text: eachData?.Sample || '' },
            { text: eachData?.Moisture || '', style: 'tableContent' },
            { text: eachData?.OilNos || '', style: 'tableContent' },
            { text: eachData?.DryBasis || '', style: 'tableContent' },
            { text: eachData?.OilDry || '', style: 'tableContent' },
            { text: eachData?.OilBasis || '', style: 'tableContent' },
            { text: eachData?.Remark || '', style: 'tableContent' },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    })
  }
  return content
}

const renderdataCooling = (data, CoolingMap) => {
  const content = []

  let indexTime = false
  if (data?.length === 0) {
    content.push([
      { text: 'cooling', style: 'tableContent' },
      { text: '', style: 'tableContent' },
      { text: '', style: 'tableContent' },
      { text: '', style: 'tableContent' },
      { text: '', style: 'tableContent' },
      { text: '', style: 'tableContent' },
      { text: '', style: 'tableContent' },
    ])
    return content
  } else {
    _.map(CoolingMap, (Sample, index) => {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (Sample === eachData?.Process) {
          indexTime = true
          content.push([
            { text: eachData?.Sample || '' },
            { text: eachData?.Moisture || '', style: 'tableContent' },
            { text: eachData?.OilNos || '', style: 'tableContent' },
            { text: eachData?.DryBasis || '', style: 'tableContent' },
            { text: eachData?.OilDry || '', style: 'tableContent' },
            { text: eachData?.OilBasis || '', style: 'tableContent' },
            { text: eachData?.Remark || '', style: 'tableContent' },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    })
  }
  return content
}

const renderDataTable = (data) => {
  const content = _.map(data, (row) => [
    { text: row?.Sample || '' },
    { text: row?.Moisture || '', style: 'tableContent', fontSize: 16 },
    { text: row?.OilNos || '', style: 'tableContent' },
    { text: row?.DryBasis || '', style: 'tableContent' },
    { text: row?.OilDry || '', style: 'tableContent' },
    { text: row?.OilBasis || '', style: 'tableContent' },
    { text: row?.Remark || '', style: 'tableContent' },
  ])
  return content
}

const header = (colors) => {
  const content = [
    {
      text: 'Sample',
      style: 'headerTable',
      fillColor: colors,
    },
    { text: 'Moisture', style: 'headerTable', fillColor: colors },
    { text: 'Oil/Nos.', style: 'headerTable', fillColor: colors },
    { text: 'Dry/Basis', style: 'headerTable', fillColor: colors },
    { text: 'Oil/Dry', style: 'headerTable', fillColor: colors },
    { text: 'Oil/Basis', style: 'headerTable', fillColor: colors },
    {
      text: 'Remark',
      style: 'headerTable',
      fillColor: colors,
      rowSpan: 2,
      margin: [-3, 10, -3, -10],
    },
  ]
  return content
}

const renderHeaderBody = (data, date) => {
  if (data?.length === 0) {
    return {
      text: [
        {
          text: 'วันที่.......................................  ',
          style: 'header2',
        },
        {
          text: 'Shift\t',
          style: 'header2',
        },
        { text: '', style: 'icon' },
        { text: '   A\t', style: 'header2' },
        { text: '', style: 'icon' },
        { text: '   B       ', style: 'header2' },
        {
          text: 'เวลา.......................................',
          style: 'header2',
        },
        {
          text: 'เวลาเก็บตัวอย่าง...............................',
          style: 'header2',
        },
      ],
      alignment: 'center',
      margin: [10, 0, 10, 0],
    }
  } else {
    return {
      text: [
        {
          text: `วันที่ ${dayjs(date).format('D เดือน MMMM พ.ศ. YYYY \t')}`,
          style: 'header2',
        },
        {
          text: 'Shift\t',
          style: 'header2',
        },
        { text: data?.[0].Shift.A ? '' : '', style: 'icon' },
        { text: '   A\t', style: 'header2' },
        { text: data?.[0].Shift.B ? '' : '', style: 'icon' },
        { text: '   B       ', style: 'header2' },
        {
          text: 'เวลา ' + data?.[0].Time + ' น.\t',
          style: 'header2',
        },
        {
          text: 'เวลาเก็บตัวอย่าง ' + data?.[0].TimeEx + ' น.',
          style: 'header2',
        },
      ],
      alignment: 'center',
      margin: [10, 0, 10, 0],
    }
  }
}

const FM_QC_007 = async (form, formInput, subForm, system, date) => {
  const colors = '#dddddd'
  const sampleMap = ['Fiber No.1', 'Fiber No.2', 'Fiber No.3']
  const KernelMap = [
    'Step A (Mix)',
    'Kernel Screw No.1',
    'Kernel Screw No.2',
    'Kernel Screw No.3',
  ]
  const DecenterMap = [
    'Before Decenter No.1',
    'Before Decenter No.2',
    'Sludge Decenter No.1',
    'Sludge Decenter No.2',
  ]

  const SCDMap = ['SCD No.1', 'SCD No.2']

  const FeedterMap = [
    'Feed',
    'SeparaterNo.1',
    'SeparaterNo.1',
    'SeparaterNo.2',
    'SeparaterNo.3',
    'SeparaterNo.4',
  ]

  const CoolingMap = ['Cooling']
  const headTable = header(colors)

  //find data for map in table
  const dataFiber = _.filter(formInput?.arr, {
    subForm_name: 'Fiber',
  })
  const dataKernelScrew = _.filter(formInput?.arr, {
    subForm_name: 'Kernel Screw',
  })
  const dataBunch = _.filter(formInput?.arr, {
    subForm_name: 'Bunch',
  })
  const dataSCD = _.filter(formInput?.arr, {
    subForm_name: 'SCD',
  })
  const dataBeforeDecanter = _.filter(formInput?.arr, {
    subForm_name: 'Before Decanter',
  })
  const dataFeedNozzle = _.filter(formInput?.arr, {
    subForm_name: 'Feed & Nozzle',
  })
  const dataCooling = _.filter(formInput?.arr, {
    subForm_name: 'Cooling',
  })
  const headerBody = renderHeaderBody(formInput.arr, date)
  const contentFiber = renderDataFiber(dataFiber, sampleMap)
  const contentKernelScrew = renderDataKernel(dataKernelScrew, KernelMap)
  const contentDataDecanter = renderdataDecanter(
    dataBeforeDecanter,
    DecenterMap
  )
  const contentDataSCD = renderdataSCD(dataSCD, SCDMap)
  const contentDataFeed = renderdataFeed(dataFeedNozzle, FeedterMap)
  const contentDataCooling = renderdataCooling(dataCooling, CoolingMap)
  // const contentDecenter = renderdataDecenter()
  const contentBunch = renderDataTable(dataBunch)

  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 120, 20, 10],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      headerBody,

      {
        margin: [0, 2],
        table: {
          widths: ['20%', '12%', '12%', '12%', '12%', '12%', '20%'],
          body: [
            headTable,
            [
              { text: 'STD', style: 'headerTable', fillColor: colors },
              { text: '40-50', style: 'headerTable', fillColor: colors },
              { text: '<9', style: 'headerTable', fillColor: colors },
              { text: '50-60', style: 'headerTable', fillColor: colors },
              { text: '<7', style: 'headerTable', fillColor: colors },
              { text: '<4.5', style: 'headerTable', fillColor: colors },
              { text: '', style: 'headerTable', fillColor: colors },
            ],
            ...contentFiber,
            [
              //3
              {
                text: 'Sample',
                style: 'headerTable',
                fillColor: colors,
              },
              { text: 'Moisture', style: 'headerTable', fillColor: colors },
              { text: 'Oil/Nos.', style: 'headerTable', fillColor: colors },
              { text: 'Dry/Basis', style: 'headerTable', fillColor: colors },
              { text: 'Oil/Dry', style: 'headerTable', fillColor: colors },
              { text: 'Oil/Basis', style: 'headerTable', fillColor: colors },
              {
                text: 'Remark',
                style: 'headerTable',
                fillColor: colors,
                rowSpan: 2,
                margin: [-3, 10, -3, -10],
              },
            ],
            [
              //4
              { text: 'STD', style: 'headerTable', fillColor: colors },
              { text: '<3', style: 'headerTable', fillColor: colors },
              { text: '<15', style: 'headerTable', fillColor: colors },
              { text: '>97', style: 'headerTable', fillColor: colors },
              { text: '<13', style: 'headerTable', fillColor: colors },
              { text: '<8', style: 'headerTable', fillColor: colors },
              { text: '', style: 'headerTable', fillColor: colors },
            ],
            ...contentKernelScrew, //ต้นฉบับคือของแถว2
            [
              //5
              {
                text: 'Sample',
                style: 'headerTable',
                fillColor: colors,
              },
              { text: 'Moisture', style: 'headerTable', fillColor: colors },
              { text: 'Oil/Nos.', style: 'headerTable', fillColor: colors },
              { text: 'Dry/Basis', style: 'headerTable', fillColor: colors },
              { text: 'Oil/Dry', style: 'headerTable', fillColor: colors },
              { text: 'Oil/Basis', style: 'headerTable', fillColor: colors },
              {
                text: 'Remark',
                style: 'headerTable',
                fillColor: colors,
                rowSpan: 2,
                margin: [-3, 10, -3, -10],
              },
            ],
            [
              //6
              { text: 'STD', style: 'headerTable', fillColor: colors },
              { text: '<95', style: 'headerTable', fillColor: colors },
              { text: '<18', style: 'headerTable', fillColor: colors },
              { text: '<5', style: 'headerTable', fillColor: colors },
              { text: '<14', style: 'headerTable', fillColor: colors },
              { text: '<10', style: 'headerTable', fillColor: colors },
              { text: '', style: 'headerTable', fillColor: colors },
            ],
            ...contentDataDecanter,
            [
              //7
              {
                text: 'Sample',
                style: 'headerTable',
                fillColor: colors,
              },
              { text: 'Moisture', style: 'headerTable', fillColor: colors },
              { text: 'Oil/Nos.', style: 'headerTable', fillColor: colors },
              { text: 'Dry/Basis', style: 'headerTable', fillColor: colors },
              { text: 'Oil/Dry', style: 'headerTable', fillColor: colors },
              { text: 'Oil/Basis', style: 'headerTable', fillColor: colors },
              {
                text: 'Remark',
                style: 'headerTable',
                fillColor: colors,
                rowSpan: 2,
                margin: [-3, 10, -3, -10],
              },
            ],
            [
              //8
              { text: 'STD', style: 'headerTable', fillColor: colors },
              { text: '<75', style: 'headerTable', fillColor: colors },
              { text: '<13', style: 'headerTable', fillColor: colors },
              { text: '>25', style: 'headerTable', fillColor: colors },
              { text: '<9', style: 'headerTable', fillColor: colors },
              { text: '<3.5', style: 'headerTable', fillColor: colors },
              { text: '', style: 'headerTable', fillColor: colors },
            ],
            ...contentDataSCD, //แก้เป็เหลือสองแถว
            [
              //9
              {
                text: 'Sample',
                style: 'headerTable',
                fillColor: colors,
              },
              { text: 'Moisture', style: 'headerTable', fillColor: colors },
              { text: 'Oil/Nos.', style: 'headerTable', fillColor: colors },
              { text: 'Dry/Basis', style: 'headerTable', fillColor: colors },
              { text: 'Oil/Dry', style: 'headerTable', fillColor: colors },
              { text: 'Oil/Basis', style: 'headerTable', fillColor: colors },
              {
                text: 'Remark',
                style: 'headerTable',
                fillColor: colors,
                rowSpan: 2,
                margin: [-3, 10, -3, -10],
              },
            ],
            [
              //10
              { text: 'STD', style: 'headerTable', fillColor: colors },
              { text: '<95', style: 'headerTable', fillColor: colors },
              { text: '<18', style: 'headerTable', fillColor: colors },
              { text: '>5', style: 'headerTable', fillColor: colors },
              { text: '<14', style: 'headerTable', fillColor: colors },
              { text: '<1', style: 'headerTable', fillColor: colors },
              { text: '', style: 'headerTable', fillColor: colors },
            ],
            ...contentDataFeed,
            [
              //11
              {
                text: 'Sample',
                style: 'headerTable',
                fillColor: colors,
              },
              { text: 'Moisture', style: 'headerTable', fillColor: colors },
              { text: 'Oil/Nos.', style: 'headerTable', fillColor: colors },
              { text: 'Dry/Basis', style: 'headerTable', fillColor: colors },
              { text: 'Oil/Dry', style: 'headerTable', fillColor: colors },
              { text: 'Oil/Basis', style: 'headerTable', fillColor: colors },
              {
                text: 'Remark',
                style: 'headerTable',
                fillColor: colors,
                rowSpan: 2,
                margin: [-3, 10, -3, -10],
              },
            ],
            [
              //12
              { text: 'STD', style: 'headerTable', fillColor: colors },
              { text: '<95', style: 'headerTable', fillColor: colors },
              { text: '<18', style: 'headerTable', fillColor: colors },
              { text: '<5', style: 'headerTable', fillColor: colors },
              { text: '<14', style: 'headerTable', fillColor: colors },
              { text: '<1', style: 'headerTable', fillColor: colors },
              { text: '', style: 'headerTable', fillColor: colors },
            ],
            ...contentDataCooling,
          ],
        },
      },

      {
        margin: [0, 50],
        table: {
          widths: ['33.33%', '33.33%', '33.33%'],
          heights: [20, 20, 20],
          body: [
            [
              {
                text: 'ลงชื่อ...................................',
                style: 'tableContent',
                border: [false, false, false, false],
              },
              {
                text: 'ลงชื่อ...................................',
                style: 'tableContent',
                border: [false, false, false, false],
              },
              {
                text: 'ลงชื่อ...................................',
                style: 'tableContent',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '(พนักงานควบคุมคุณภาพ)',
                style: 'tableContent',
                border: [false, false, false, false],
              },
              {
                text: '(ผู้ตรวจสอบ)',
                style: 'tableContent',
                border: [false, false, false, false],
              },
              {
                text: '(หัวหน้างานผลิต)',
                style: 'tableContent',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    // footer: function (currentPage, pageCount) {
    //   return []
    // },

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: {
        font: 'FontAwesome',
        bold: true,
        alignment: 'center',
        fontSize: 10,
      },
      header: {
        bold: true,
        fontSize: 15,
        margin: [0, 0, 0, 0],
      },
      header2: {
        fontSize: 16,
        bold: true,
      },
      headerTable: {
        margin: [-3, 0, -2, 0],
        fontSize: 15,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 14,
        margin: [0, 0, 0, 0],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_QC_007

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { printPDF } from './CountingSummaryReport'
import {
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Button,
  Box,
  Link as LinkUI,
} from '@chakra-ui/react'
import _ from 'lodash'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// import { printPDF } from "./CountingSummaryReport";
export default function CountingSummaryLists({
  countingSummary,
  systemInfo,
  stationId,
}) {
  const { t } = useTranslation()
  const [allowedDashboard, setAllowedDashboard] = useState()
  const stations = useSelector((state) => state.station)
  const me = useSelector((state) => state.me)
  console.log('COUNTING SUMMARY : ', countingSummary)

  useEffect(() => {
    if (me && me._id) {
      if (_.size(stations.arr)) {
        if (_.includes(me.duty, 'ALLAREA')) {
          let allowedStationStack = stations.arr
          setAllowedDashboard(allowedStationStack)
        } else {
          let allowedStation = _.filter(
            stations.arr,
            (station) => station._id === me.duty
          )
          setAllowedDashboard(allowedStation)
        }
      }
    }
    return () => {}
  }, [stations, me])

  const genCountingSummaryLists = () =>
    _.map(
      _.filter(countingSummary, (member) => member.station?._id === stationId),
      (_countingSummary, index) => (
        <Tr key={_countingSummary._id}>
          <Td>{index + 1}</Td>
          <Td>{moment(_countingSummary.date).format('dddd')} {moment(_countingSummary.date).format('DD/MM/YYYY')}</Td>
          <Td>{_countingSummary.station?.name}</Td>
          <Td>
            {parseFloat(_countingSummary.summary).toFixed(0) + ' '}{' '}
            {t('counter.unit')}
          </Td>
          <Td>
            <Link
              to={
                '/counting-summary/' +
                _countingSummary.station.name +
                '/' +
                _countingSummary._id
              }
            >
              <Button colorScheme="linkedin" variant="solid">
                รายละเอียด
              </Button>
            </Link>
            <Link
              to={
                '/counting-summary/' +
                _countingSummary.station.name +
                '/' +
                _countingSummary._id
              }
            ></Link>{' '}
            <Button
              colorScheme="yellow"
              variant="solid"
              onClick={() => {
                printPDF(_countingSummary, systemInfo)
              }}
            >
              พิมพ์
            </Button>
          </Td>
        </Tr>
      )
    )

  return (
    <div>
      <Table size="sm" variant="striped" className="whitespace-no-wrap">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t('dateTime.date')}</Th>
            <Th>{t('setting.station')}</Th>
            <Th>{t('counter.counterTotal')}</Th>
          </Tr>
        </Thead>
        <Tbody>{genCountingSummaryLists()}</Tbody>
      </Table>
    </div>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/th'
import dayjs from 'dayjs'
dayjs.locale('th')
dayjs.extend(buddhistEra)
dayjs.extend(LocalizedFormat)

const HeaderReportType2 = (form, date) => ({
  margin: [10, 10],
  style: 'tableExample',
  table: {
    widths: ['100%'],
    body: [
      [
        {
          text: `${form?.name || ''}`,
          alignment: 'center',
          fontSize: '16',
          bold: true,
          margin: [0, 5],
          border: [false, false, false, false],
        },
      ],
      [
        {
          text: `วันที่ ${dayjs(date).format('DD เดือน MMMM พ.ศ. BBBB ')}`,
          alignment: 'center',
          fontSize: '14',
          border: [false, false, false, false],
        },
      ],
    ],
  },
})

HeaderReportType2.propTypes = {
  form: PropTypes.object,
}

export default HeaderReportType2

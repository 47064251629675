/* eslint-disable import/named */
import {
  SUMMARIZE_ALL,
  SUMMARIZE_GET,
  SUMMARIZE_PUT,
  SUMMARIZE_DEL,
  SUMMARIZE_POST,
  SUMMARIZE_ERROR,
} from '../../actions/types'

const initialState = {
  log: null,
  isLoading: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case SUMMARIZE_ALL:
      return {
        arr: [...action.payload],
        isLoading: true,
      }
    case SUMMARIZE_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case SUMMARIZE_POST:
      return action.payload
    case SUMMARIZE_PUT:
      return action.payload
    case SUMMARIZE_DEL:
      return { isLoading: true }
    default:
      return state
  }
}

import React, { useEffect, useState } from 'react'
import BackButton from 'components/Button/BackButton'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

// components
import { Button } from '@chakra-ui/react'
import SpinnerLoading from '../../components/Loading/SpinnerLoading'

export default function MainSystem() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const stations = useSelector((state) => state.station)

  useEffect(() => {
    dispatch(actions.stationAll()).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [])

  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              {t('setting.systemManagement')}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              รายการจัดการระบบ
            </h2>
          </div>
        </div>
        <Button>
          <BackButton />
        </Button>
      </div>
      <div className="grid sm:grid-cols-1 lg:grid-cols-3">
        <div className="my-4  px-4">
          <Link to={'/system/info'}>
            <div className="grid grid-cols-4 bg-gray-300 shadow rounded py-6 px-4 transition-transform transform hover:scale-105">
              <p></p>
              <p className="col-span-3 text-blue-500 mb-0 font-bold  text-xl">
                จัดการข้อมูลระบบ
              </p>
              <i className="fa fa-solid fa-info fa-2x text-gray-800 text-center "></i>
              <p className="col-span-3 text-gray-600 mb-0 text-left ">
                สำหรับแก้ไขเกี่ยวกับระบบ เช่น ชื่อระบบการแสดงผลโมดูล เป็นต้น
              </p>
            </div>
          </Link>
        </div>
        <div className="my-4  px-4">
          <Link to={'/system/connections'}>
            <div className="grid grid-cols-4 bg-gray-300 shadow rounded py-6 px-4 transition-transform transform hover:scale-105">
              <p></p>
              <p className="col-span-3 text-blue-500 mb-0 font-bold  text-xl">
                จัดการการเชื่อมต่อข้อมูล
              </p>
              <i className="fa fa-solid fa-link fa-2x text-gray-800 text-center "></i>
              <p className="col-span-3 text-gray-600 mb-0 text-left ">
                สำหรับแก้ไขการเชื่อมต่อข้อมูล เช่น server สำหรับเชื่อมต่อ
                เป็นต้น
              </p>
            </div>
          </Link>
        </div>
        <div className="my-4  px-4">
          <Link to={'/system/notify'}>
            <div className="grid grid-cols-4 bg-gray-300 shadow rounded py-6 px-4 transition-transform transform hover:scale-105">
              <p></p>
              <p className="col-span-3 text-blue-500 mb-0 font-bold  text-xl">
                จัดการการแจ้งเตือน
              </p>
              <i className="fa fa-solid fa-bell fa-2x text-gray-800  text-center "></i>
              <p className="col-span-3 text-gray-600  mb-0 text-left ">
                สำหรับแก้ไขเกี่ยวกับการแจ้งเตือน เช่น token สำหรับแจ้งเตือน
                เป็นต้น
              </p>
            </div>
          </Link>
        </div>
        <div className="my-4  px-4">
          <Link to={'/system/stations'}>
            <div className="grid grid-cols-4 bg-gray-300 shadow rounded py-6 px-4 transition-transform transform hover:scale-105">
              <p></p>
              <p className="col-span-3 text-blue-500 mb-0 font-bold  text-xl">
                จัดการสถานี
              </p>
              <i className="fa fa-solid fa-warehouse fa-2x text-gray-800 text-center "></i>
              <p className="col-span-3 text-gray-600 mb-0 text-left ">
                สำหรับแก้ไขข้อมูลต่างๆ เกี่ยวกับสถานี เช่น เซนเซอร์ เกจ กราฟ
                เป็นต้น
              </p>
            </div>
          </Link>
        </div>
        <div className="my-4  px-4">
          <Link to={'/system/summarize'}>
            <div className="grid grid-cols-4 bg-gray-300 shadow rounded py-6 px-4 transition-transform transform hover:scale-105">
              <p></p>
              <p className="col-span-3 text-blue-500 mb-0 font-bold  text-xl">
                จัดการสรุปและการทำนาย
              </p>
              <i className="fa fa-solid fa-chart-bar fa-2x text-gray-800 text-center "></i>
              <p className="col-span-3 text-gray-600  mb-0 text-left ">
                สำหรับแก้ไขเกี่ยวกับรูปแบบสรุปและ การทำนาย เช่น กำหนดรูปแบบ
                เป็นต้น
              </p>
            </div>
          </Link>
        </div>
        <div className="my-4  px-4">
          <Link to={'/system/template'}>
            <div className="grid grid-cols-4 bg-gray-300 shadow rounded py-6 px-4 transition-transform transform hover:scale-105">
              <p></p>
              <p className="col-span-3 text-blue-500 mb-0 font-bold  text-xl">
                จัดการรูปแบบสถานี
              </p>
              <i className="fa fa-solid fa-file fa-2x text-gray-800 text-center "></i>
              <p className="col-span-3 text-gray-600 mb-0 text-left ">
                สำหรับแก้ไขเกี่ยวกับรูปแบบสถานี เช่น อัพโหลดรูปแบบของสถานี
                เป็นต้น
              </p>
            </div>
          </Link>
        </div>
        <div className="my-4  px-4">
          <Link to={'/system/users'}>
            <div className="grid grid-cols-4 bg-gray-300 shadow rounded py-6 px-4 transition-transform transform hover:scale-105">
              <p></p>
              <p className="col-span-3 text-blue-500 mb-0 font-bold  text-xl">
                จัดการผู้ใช้
              </p>
              <i className="fa fa-solid fa-user-tag fa-2x text-gray-800 text-center "></i>
              <p className="col-span-3 text-gray-600  mb-0 text-left ">
                สำหรับแก้ไขเกี่ยวกับผู้ใช้ เช่น การเพิ่มผู้ใช้ บทบาท เป็นต้น
              </p>
            </div>
          </Link>
        </div>
        <div className="my-4  px-4">
          <Link to={'/statistics'}>
            <div className="grid grid-cols-4 bg-gray-300 shadow rounded py-6 px-4 transition-transform transform hover:scale-105">
              <p></p>
              <p className="col-span-3 text-blue-500 mb-0 font-bold  text-xl">
                ประวัติการใช้งาน
              </p>
              <i className="fa fa-solid fa-paste fa-2x text-gray-800  text-center "></i>
              <p className="col-span-3 text-gray-600  mb-0 text-left ">
                สำหรับเก็บข้อมูลการใช้งานของผู้ใช้ เช่น เก็บการลงชื่อเข้าใช้
                เป็นต้น
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}

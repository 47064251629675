export const PipeType = {
  Pipe: {
    value: 'PipeType',
    describe: 'Pipe',
  },

  PipeSrc: [
    {
      name: '45° Angle 5',
      value: '45Angle5.svg',
    },
    {
      name: '45° Angle 6',
      value: '45Angle6.svg',
    },
    {
      name: '45° Angle 7',
      value: '45Angle7.svg',
    },
    {
      name: '45° Angle 8',
      value: '45Angle8.svg',
    },
    {
      name: '45° Short',
      value: '45Short.svg',
    },
    {
      name: '90° curve 1',
      value: '90Curve1.svg',
    },
    {
      name: '90° curve 2',
      value: '90Curve2.svg',
    },
    {
      name: '90° curve 3',
      value: '90Curve3.svg',
    },
    {
      name: '90° curve 4',
      value: '90Curve4.svg',
    },
    {
      name: '135° Short',
      value: '135Short.svg',
    },
    {
      name: 'Intersection',
      value: 'Intersection.svg',
    },
    {
      name: 'Long Horizontal Pipe',
      value: 'LongHorizontalpipe.svg',
    },
    {
      name: 'Long Vertical Pipe',
      value: 'LongVerticalpipe.svg',
    },
    {
      name: 'Short Horizontal Pipe',
      value: 'ShortHorizontalpipe.svg',
    },
    {
      name: 'Short Vertical Pipe',
      value: 'ShortVerticalpipe.svg',
    },
    {
      name: 'Tee 1',
      value: 'Tee1.svg',
    },
    {
      name: 'Tee2',
      value: 'Tee2.svg',
    },
    {
      name: 'Tee3',
      value: 'Tee3.svg',
    },
    {
      name: 'Tee4',
      value: 'Tee4.svg',
    },
    {
      name: 'Tee5',
      value: 'Tee5.svg',
    },
    {
      name: 'Tee6',
      value: 'Tee6.svg',
    },
  ],
}

import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import currencyFormatter from 'currency-formatter'
import _ from 'lodash'
import moment from 'moment'

pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf',
  },
}

const counterName = (history) => {
  return _.map(history.sensors, (sensor) => {
    return {
      text: sensor.name,
      alignment: 'center',
      border: [true, true, true, true, true],
      fontSize: 12,
    }
  })
}
const processRate = (history) => {
  return _.map(history.sensors, (sensor) => {
    return {
      text: sensor.rate.toFixed(2),
      alignment: 'center',
      border: [true, true, true, true, true],
      fontSize: 12,
    }
  })
}
const counter = (history) => {
  return _.map(history.sensors, (sensor) => {
    return {
      text: sensor.totalCount,
      alignment: 'center',
      border: [true, true, true, true, true],
      fontSize: 12,
    }
  })
}

const historyList = (countingSummary) => {
  return _.map(countingSummary.history, (history, index) => [
    {
      text: `${moment(history.date).format('HH:mm')} - ${moment(history.date)
        .endOf('hour')
        .format('HH:mm')} น.`,
      border: [true, false, true, true],
      rowSpan: 1,
      fontSize: '12',
    },
    counterName(history),
    processRate(history),
    counter(history),
    {
      text: history.summary,
      border: [true, false, true, true],
      rowSpan: 1,
      alignment: 'center',
      fontSize: '16',
    },
  ])
}

export const printPDF = (countingSummary, systemInfo) => {
  const history = historyList(countingSummary)
  var docDefinition = {
    header: {
      columns: [
        {
          text: `${moment().format('ll')}`,
          alignment: 'right',
          margin: [10, 10, 10, 10],
          fontSize: '12',
        },
      ],
    },
    footer: function (currentPage, pageCount) {
      // you can apply any logic and return any valid pdfmake element

      return [
        {
          text: currentPage.toString() + ' of ' + pageCount,
          alignment: 'right',
          margin: [10, 10, 10, 10],
          fontSize: '12',
        },
      ]
    },

    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [40, 40, 40, 40],
    defaultStyle: {
      font: 'THSarabunNew',
      fontSize: '16',
    },
    info: {
      title: `สรุปผลการผลิตประจำวันที่ ${moment(countingSummary.date).format(
        'DD MMMM'
      )}/${parseInt(moment(countingSummary.date).format('YYYY'))}`,
    },
    content: [
      {
        alignment: 'justify',
        columns: [
          {
            image: 'company_logo',
            width: 60,
            margin: [0, 0, 0, 0],
          },
          [
            {
              text: systemInfo.systemOwner,
              fontSize: '18',
              bold: true,
              margin: [20, 0, 0, 0],
            },

            {
              text: `${systemInfo.name}`,
              fontSize: '14',
              margin: [20, 0, 0, 0],
            },
            {
              text: `${systemInfo.description}`,
              fontSize: '12',
              margin: [20, 0, 0, 0],
            },
          ],
        ],
      },

      {
        //หัวข้อใหญ่
        alignment: 'center',
        margin: [0, 20, 0, 20],
        text: `สรุปผลการผลิตข้อมูลประจำวันที่ ${moment(
          countingSummary.date
        ).format('DD MMMM')} ${parseInt(
          moment(countingSummary.date).format('YYYY')
        )}`,
        fontSize: '23',
        bold: true,
      },
      {
        margin: [0, 0, 0, 10],
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#ebebeb' : '#f5f5f5'
          },
        },
        table: {
          widths: ['100%'],
          heights: [20, 10],
          body: [
            [
              {
                text: `จำนวนการผลิตรวมทั้งหมดประจำวัน : ${currencyFormatter.format(
                  countingSummary.summary.toFixed(0),
                  { code: '' }
                )} หน่วย`,
                fontSize: '18',
                bold: true,
                fillColor: '#c2dec2',
              },
            ],
          ],
        },
      },
      // {
      //     text: `ข้อมูลเพิ่ม`,
      //     fontSize: '16',
      //     bold: true,
      // },
      // {
      //     margin: [0, 0, 0, 10],
      //     layout: {
      //         fillColor: function (rowIndex, node, columnIndex) {
      //             return rowIndex % 2 === 0 ? '#ebebeb' : '#f5f5f5'
      //         },
      //     },
      //     table: {
      //         widths: ['100%'],
      //         heights: [20, 10],
      //         body: [
      //             [
      //                 {
      //                     text: ` : ${moment(
      //                         countingSummary.date_max_on_peak
      //                     ).format('LLLL')} น.`,
      //                     fontSize: '14',
      //                 },
      //             ],
      //         ],
      //     },
      // },
      {
        text: `สรุปผลจำนวนการผลิตรายชั่วโมง`,
        fontSize: '16',
        bold: true,
      },
      {
        style: 'tableExample',
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex === 0 ? '#c2dec2' : null
          },
        },
        table: {
          widths: ['13%', '15%', '25%', '20%', '27%'],
          heights: [10, 10, 10, 10, 30, 10, 25],
          headerRows: 1,
          body: [
            [
              {
                text: 'เวลา (ชั่วโมง)',
                colSpan: 1,
                fontSize: 14,
                bold: true,
                alignment: 'center',
              },
              {
                text: 'ไลน์การผลิต',
                colSpan: 1,
                fontSize: 14,
                bold: true,
                alignment: 'center',
              },
              {
                text: 'อัตราการผลิตสูงสุด (หน่วย/นาที)',
                colSpan: 1,
                fontSize: 14,
                bold: true,
                alignment: 'center',
              },
              {
                text: 'จำนวนการผลิต (หน่วย)',
                colSpan: 1,
                fontSize: 14,
                bold: true,
                alignment: 'center',
              },
              {
                text: 'จำนวนการผลิตรายชั่วโมงทั้งหมด (หน่วย)',
                colSpan: 1,
                fontSize: 14,
                bold: true,
                alignment: 'center',
              },
            ],
            ...history,
          ],
        },
      },
    ],
    images: {
      company_logo: systemInfo.logo,
    },
  }
  pdfMake.createPdf(docDefinition).open()
}

import React, { useState, useEffect } from 'react'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import * as actions from '../../redux/actions/'
import _ from 'lodash'
import moment from 'moment'
import { printPDF } from './components/CountingSummaryReport'
import { Button } from '@chakra-ui/button'
import CountingSummaryDetailLists from './components/CountingSummaryDetailList'
import { useTranslation } from 'react-i18next'
// import { printPDF } from "./components/CountingSummaryReport";
export default function CountingSummaryDetail() {
  const { t } = useTranslation()
  const { id } = useParams()
  const dispatch = useDispatch()
  const countingSummary = useSelector((state) => state.countingSummary)
  const systemInfo = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(actions.countingSummaryGet(id)).then(() => {
      dispatch(actions.systemInfoGet()).then(() => {
        setIsLoading(true)
      })
    })
    return () => {}
  }, [])

  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded ">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              {t('counter.counterInfo')}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              {t('counter.counterOfDate')}{' '}
              {moment(countingSummary.date).format('DD MMMM YYYY')}
            </h2>
          </div>
        </div>
        <div className="flex justify-between my-2">
          <Link to={'/counting-summary'}>
            <Button variant="outline" size="sm">
              {t('counter.back')}
            </Button>
          </Link>
          <Button
            leftIcon={<i className="fas fa-print"></i>}
            colorScheme="yellow"
            size="sm"
            onClick={() => {
              printPDF(countingSummary, systemInfo)
            }}
          >
            {t('electricBill.print')}
          </Button>
        </div>
        <div className="flex flex-wrap p-4 w-full bg-gray-200 rounded-sm">
          <div className="flex flex-wrap w-full md:w-1/2">
            <div className="box-border border-2 border-gray-400 rounded-md h-64 w-64 my-2 mx-4 px-2 py-4">
              <span className="font-bold text-2xl">
                {t('setting.nameStation')}
                {' : '}
              </span>
              <span className="font text-2xl">
                {'' + countingSummary.station?.name}
              </span>
                <div className="my-2 py-4 text-2xl">
                <span className='font-bold text-md'>
                  {' '}
                  {t('counter.counterTotal')}
                  {' : '}
                </span>
                <span className='flex flex-col my-0 font-bold text-3xl text-blue-500'>
                  {'' + countingSummary.summary?.toFixed(0) + ' '}{' '}
                  {t('counter.unit')}
                </span>
              </div>
            </div>
            
            <div className="box-border border-2 border-gray-400 rounded-md overflow-y-auto h-64 w-auto mx-4 my-2 px-2 py-4">
              <span className='font-bold text-2xl'>จำนวนการผลิตแต่ละเครื่อง</span>
              {_.map(countingSummary?.totalEachCounter, (eachCounter) => (
                <div className='flex flex-row text-md mx-2 my-2'>
                  <div className='font-bold'>{eachCounter.name}  {' : '}</div>
                  <div className='font-bold text-blue-500 mx-2'>{eachCounter.count} {'หน่วย'}</div>
                </div>
                ))}
            </div>
          </div>
          <div className="w-full md:w-1/2 overflow-x-auto ">
            {/* <div className="mt-8 mb-2 text-sm font-semibold">
              ข้อมูลเพิ่มเติม
            </div> */}
          </div>
          <div className="w-full px-2 my-2">
            <hr />
          </div>
        </div>

        <div className="my-2 mx-2 text-lg font-bold">
          {t('counter.counterOfHours')}
        </div>
        <div className="p-4  w-full bg-gray-200 rounded-sm border-black border-2">
          {_.size(countingSummary.history) ? (
            <div className="overflow-x-auto">
              <CountingSummaryDetailLists
                history={countingSummary.history}
                worktime={systemInfo.worktime}
              />
            </div>
          ) : (
            <div className="flex justify-center p-4">
              <div>{t('dataAndStat.empty')}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}

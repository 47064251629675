export const WarningStatus = {
  ERROR: 'ERROR',
  DANGER_HIGH: 'DANGER_HIGH',
  WARNING_HIGH: 'WARNING_HIGH',
  WARNING_LOW: 'WARNING_LOW',
  DANGER_LOW: 'DANGER_LOW',
  NORMAL: 'NORMAL',
}

export const WarningColorClass = {
  ERROR: 'text-red-500',
  DANGER_HIGH: 'text-red-500',
  WARNING_HIGH: 'text-yellow-500',
  WARNING_LOW: 'text-yellow-500',
  DANGER_LOW: 'text-red-500',
  NORMAL: 'text-blue-500',
}

export default WarningStatus

import _ from 'lodash'
import GenerateMathematicSymbol from '../../../util/GenerateMathematicSymbol'
import SubVariableDataFinding from './SubVariableDataFinding'
import MathematicResolver from '../../../util/LogicResolve'
import * as actions from '../../../redux/actions'
import { useDispatch } from 'react-redux'

// Main Rendering the value of Variable in Realtime

export default function VariableMainRendering(stationData, variableId) {
  // console.log('ee', stationData)
  /// Find the variable that want to display value
  const selectedVariable = _.find(
    stationData?.variables,
    (variable) => variable?._id === variableId
  )
  // console.log('VARIABLE ID : ', variableId)
  // console.log('หาข้อมูลจากตัวแปรไอดี ', variableId, 'ได้ค่า', selectedVariable)

  // คำนวณตัวเลขที่ต้องการนำไปแสดงที่ Dashboard
  if (selectedVariable) {
    // console.log('Selected Sensor Data Rendering')
    // console.log(
    //   'Variable is : ' + selectedVariable.name + ' Value is : ',
    //   selectedVariable.numericStatus
    // )
    return selectedVariable?.numericStatus

    // dispatch(actions.stationGet_PYTHON(stationData)).then(() => {
    //     dispatch(actions.stationAll())
    //   })
  } else {
    return false
  }
}

import axios from "axios";
import {
  ELECTRIC_CONSUMPTION_ALL,
  ELECTRIC_CONSUMPTION_GET,
  ELECTRIC_CONSUMPTION_PUT,
  ELECTRIC_CONSUMPTION_DEL,
} from "../types";

export const electricConsumptionAll = () => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + "/electricconsumption").then((res) => {
      console.log("Request Server to Get All electricConsumption");
      dispatch({ type: ELECTRIC_CONSUMPTION_ALL, payload: res.data });
    });
  };
};

export const electricConsumptionGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/electricconsumption/" + payload)
      .then((res) => {
        console.log("Request Server to Get an electricConsumption");
        if (res.data) {
          dispatch({ type: ELECTRIC_CONSUMPTION_GET, payload: res.data });
        } else {
          dispatch({ type: ELECTRIC_CONSUMPTION_GET, payload: null });
        }
      });
  };
};

export const electricConsumptionPut = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + "/electricconsumption/" + id, payload)
      .then((res) => {
        console.log("Request Server to put an electricConsumption");
        if (res.data) {
          dispatch({ type: ELECTRIC_CONSUMPTION_PUT, payload: res.data });
        } else {
          dispatch({ type: ELECTRIC_CONSUMPTION_PUT, payload: null });
        }
      });
  };
};

export const electricConsumptionPost = (payload) => {
  return async (dispatch) => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/electricconsumption/", payload)
      .then((res) => {
        console.log("Request to Create electricConsumption");
        dispatch(electricConsumptionAll());
      });
  };
};
export const electricConsumptionDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/electricconsumption/" + payload)
      .then((res) => {
        console.log("Request Server to Delete an electricConsumption");
        dispatch({ type: ELECTRIC_CONSUMPTION_DEL, payload: null });
      });
  };
};

export const DiagramType = {
  Null: {
    value: 'Null',
    describe: 'Select Type of Diagram Display',
  },
  Image: {
    value: 'Image',
    describe: 'รูปภาพ',
  },
  Text: {
    value: 'Text',
    describe: 'ข้อความเดี่ยว',
  },
  String: {
    value: 'String',
    describe: 'ชุดข้อความ',
  },
  MeterColors: {
    value: 'MeterColors',
    describe: 'หน้าปัดมิเตอร์แบบสี',
  },
  MeterClassic: {
    value: 'MeterClassic',
    describe: 'หน้าปัดมิเตอร์แบบคลาสสิค',
  },
}

export const FormType = {
  singleInput: 'SINGLE_INPUT',
  multipleInput: 'MULTIPLE_INPUT',
  null: 'NULL_INPUT',
  checkbox: 'CHECKBOX',
  selector: 'SELECTOR',
  calculate: 'CALCULATE',
}
export const InputType = {
  date: 'DATE',
  time: 'TIME',
  number: 'NUMBER',
  text: 'TEXT',
  username: 'USERNAME',
}

export default { FormType, InputType }

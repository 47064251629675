/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import {
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Button,
  Modal,
  CheckboxGroup,
  ButtonModal,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  ModalContent,
  useDisclosure,
  Stack,
} from '@chakra-ui/react'
import { Chip } from '@mui/material'
import * as UserRoles from 'config/UserRoles'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export default function EditUser({
  user,
  index,
  selectedUser,
  handleDelelte,
  handleUpdate,
}) {
  const { t } = useTranslation()
  const { register, handleSubmit, reset, errors, setValue, control } = useForm()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const station = useSelector((state) => state.station)
  const [selectedStation, setSelectedStation] = useState([])
  const [rerender, setRerender] = useState(false)

  const setSelect = (index, user) => {
    console.log('index = ', index)
    // ดูว่าใน Select Station มี Station ID ตัวนี้มั้ย

    setSelectedStation(user)
    onOpen()
    setRerender(!rerender)
  }

  const renderStationSelect = (userDuty) => {
    return _.map(station?.arr, (_station, index) => {
      if (_station?._id === userDuty?.[index]) {
        return index >= 5 ? (
          <Chip className="mx-2" size="small" label={_station?.name} />
        ) : (
          <Chip className="mx-2" size="small" label={'...'} />
        )
      }
    })
  }

  const handleCloseModal = () => {
    setSelectedStation([])
    onClose()
  }

  const handleSuccess = () => {
    handleUpdate(selectedStation?._id, { duty: selectedStation?.duty })
    handleCloseModal()
  }

  const showDuty = () => {
    return _.map(
      station?.arr,
      (_station, index) => {
        if (_station?._id === selectedStation?.duty?.[index]) {
          return <Chip className="mx-2" size="small" label={_station?.name} />
        }
      }

      // <div
      //   className={`${
      //     _station?._id === selectedStation?.duty?.[index]
      //       ? 'bg-blue-400'
      //       : 'bg-gray-200'
      //   } p-2 px-4 my-2 rounded-md cursor-pointer`}
      //   onClick={() => {
      //     if (selectedStation.duty[index] === _station?._id) {
      //       // ลบ station ตัวนี้ออกจาก Array
      //       selectedStation.duty[index] = null
      //     } else {
      //       // เพิ่มลงใน Array
      //       selectedStation.duty[index] = _station?._id
      //     }
      //     setSelectedStation(selectedStation)
      //     setRerender(!rerender)
      //   }}
      // >
      //   {_station?.name}
      // </div>
    )
  }

  const genStationList = () => {
    return _.map(station.arr, (eachStation, index) => (
      <option key={index} value={eachStation._id}>
        {eachStation.name}{' '}
      </option>
    ))
  }

  const genPositionOption = () => (
    <>
      <option value={UserRoles.ROLES.USER}>{UserRoles.ROLES.USER}</option>
      <option value={UserRoles.ROLES.GENERAL}>{UserRoles.ROLES.GENERAL}</option>
      <option value={UserRoles.ROLES.AMDIN}>{UserRoles.ROLES.ADMIN}</option>
      <option value={UserRoles.ROLES.SUPER_USER}>
        {UserRoles.ROLES.SUPER_USER}
      </option>
    </>
  )

  return (
    <div>
      <div className="relative w-full mb-3">
        <label
          className="block uppercase text-gray-700 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Email/Username
        </label>
        <input
          name="username"
          defaultValue={selectedUser?.email || selectedUser?.username}
          //required
          {...register('username')}
          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="example@email.com"
        />
      </div>
      <div className="relative w-full  mb-3">
        <label
          className="block uppercase text-gray-700 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          {t('connection.password')}
        </label>
        <input
          name="password"
          type="password"
          //defaultValue={selectedUser?.password}
          //required
          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="Password"
          {...register('password')}
        />
      </div>
      <div className="relative w-full  mb-3">
        <label
          className="block uppercase text-gray-700 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          {t('connection.confirmPassword')}
        </label>
        <input
          name="confirm_password"
          type="password"
          //required
          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="Confirm Password"
          {...register('confirm_password')}
        />
      </div>
      <div className="relative w-full mb-3">
        <label
          className="block uppercase text-gray-700 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          {t('utility.name')}
        </label>
        <input
          name="name"
          type="text"
          defaultValue={selectedUser?.name}
          //required
          {...register('name')}
          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="ชื่อสกุล"
        />
      </div>
      <div className="relative w-full mb-3">
        <label
          className="block uppercase text-gray-700 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          {t('utility.tel')}
        </label>
        <input
          name="tel"
          type="text"
          defaultValue={selectedUser?.tel}
          {...register('tel')}
          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="เบอร์โทรศัพท์"
        />
      </div>
      <div className="relative w-full  mb-3">
        <label
          className="block uppercase text-gray-700 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          {t('system.address')}
        </label>
        <textarea
          name="address"
          defaultValue={selectedUser?.address}
          {...register('address')}
          rows="3"
          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="ที่อยู่"
        />
      </div>
      <div className="relative w-full  mb-3">
        <label
          className="block uppercase text-gray-700 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          {t('utility.role')}
        </label>
        <select
          onChange={(e) => {
            const data = { role: e.target.value }
            const confirm = window.confirm(
              'ยืนยันเปลี่ยนบทบาทบัญชีผู้ใช้จาก' +
                selectedUser?.role +
                'เป็น' +
                e.target.value
            )
            if (confirm) handleUpdate(selectedUser?.id, data)
          }}
          defaultValue={selectedUser?.role}
          className="rounded text-xl shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
        >
          {genPositionOption()}
        </select>
        <div className="flex justify-items-center">
          <Button
            variant="solid"
            size="sm"
            colorScheme="yellow"
            onClick={() => setSelect(index, user)}
          >
            {' '}
            แก้ไขสถานีสถานีที่ต้องการ{' '}
          </Button>

          <Modal isOpen={isOpen} onClose={handleCloseModal} size={'md'}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <div className="w-full grid justify-items-center">
                  เลือกสถานีที่ต้องการดูแล
                </div>
              </ModalHeader>
              <ModalBody>
                <CheckboxGroup colorScheme="blue">
                  <Stack spacing={2} direction={'column'}>
                    {renderStationSelect(user._id)}
                  </Stack>
                </CheckboxGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="red"
                  mr={3}
                  size="sm"
                  variant="solid"
                  onClick={handleCloseModal}
                >
                  {t('utility.cancel')}
                </Button>
                <Button
                  colorScheme="green"
                  size="sm"
                  onClick={() => handleSuccess()}
                  type="button"
                >
                  {t('utility.save')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      </div>
    </div>
  )
}

import {
  STATION_ALL,
  STATION_GET,
  STATION_PUT,
  STATION_DEL,
  STATION_POST,
} from "../../actions/types";

const initialState = {
  station: null,
  isLoading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case STATION_ALL:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case STATION_GET:
      return {
        ...action.payload,
        isLoading: true,
      };
    case STATION_POST:
      return action.payload;
    case STATION_PUT:
      return action.payload;
    case STATION_DEL:
      return { isLoading: true };
    default:
      return state;
  }
}

/* eslint-disable object-shorthand */
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Chart from 'react-apexcharts'
import moment from 'moment'
import 'moment/locale/th'
import { Button, Stack } from '@chakra-ui/react'
import FetchingGraphData from '../Functions/FetchingGraphData'
import { useDispatch } from 'react-redux'
import * as actions from '../../../redux/actions'
import { useTranslation } from 'react-i18next'

export default function LineGraph({
  data,
  stationData,
  chartData,
  sensorRef,
  valueFormat,
  tickAmount,
  graphMax,
  graphMin,
  setValueFormat,
  setGraphMax,
  setGraphMin,
  setTickAmount,
  enableTimeSelect = true,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const sensorData = _.find(
    stationData.sensors,
    (sensor) => sensor.refString === chartData.chartSensorRef
  )
  const stationId = stationData._id
  const [valueStack, setValueStack] = useState(
    FetchingGraphData(data?.arr, stationId, sensorData._id)
  )

  const [chartType, setChartType] = useState('line')

  const [isAllowAutoTrigger, setIsAllowAutoTrigger] = useState(true)

  // Automate Render
  useEffect(() => {
    if (data && data.arr) {
      let tempValueStack = FetchingGraphData(data.arr, sensorData._id)
      setValueStack(tempValueStack)
    }
    return () => {}
  }, [data])

  const lastHour = () => {
    dispatch(actions.logByHour(stationId))
  }

  const lastDay = () => {
    dispatch(actions.logByDay(stationId))
  }

  const lastWeek = () => {
    dispatch(actions.logByWeek(stationId))
  }

  const lastMonth = () => {
    dispatch(actions.logByMonth(stationId))
  }

  const lastYear = () => {
    dispatch(actions.logByYear(stationId))
  }

  //Default Options
  const options = {
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (value) => {
          return moment(value).format(valueFormat)
        },
      },
      tickAmount: tickAmount,
      min: graphMin,
      max: graphMax,
    },
    yaxis: {
      labels: {
        formatter:
          chartData?.labelOnSituation?.enable === true
            ? (value) => {
                if (value >= 1) return chartData?.labelOnSituation?.labelOnHigh
                else return chartData?.labelOnSituation?.labelOnLow
              }
            : (value) => {
                return parseFloat(value).toFixed(2)
              },
      },
      min: (min) => min - 0.03,
      max: (max) => max + 0.03,
      tickAmount: chartData?.labelOnSituation?.enable === true ? 1 : 5,
      title: {
        text:
          chartData?.valueLabel +
          (chartData?.labelOnSituation?.enable === true ? '' : ' (') +
          chartData?.valueUnit +
          (chartData?.labelOnSituation?.enable === true ? '' : ') '),
      },
    },
    tooltip: {
      x: {
        show: true,
        formatter: (value) => moment(value).format('D MMM - HH:mm'),
      },
      y: {
        formatter: (value) => {
          return parseFloat(value).toFixed(2) + ' ' + chartData.valueUnit
        },
        title: {
          formatter: (seriesName) => chartData.valueLabel,
        },
        style: {
          fontWeight: '400',
        },
      },
    },
    stroke: {
      curve: chartData?.chartCurve,
    },
    dataLabels: {
      formatter: (value, opts) => {
        return parseFloat(value).toFixed(2)
      },
      enabled: isAllowAutoTrigger && false,
      background: {
        enabled: true,
        foreColor: '#fff',
      },
      textAnchor: 'small',
    },
    chart: {
      toolbar: {
        tools: {
          zoomin: false,
          zoomout: false,
        },
        export: {
          csv: {
            headerCategory: 'Date',
            headerValue: chartData?.valueLabel,
            dateFormatter: (timestamp) =>
              moment(timestamp).format('DD/MM/YYYY HH:mm'),
          },
        },
      },
    },
  }

  //Rendered Series
  const series = [
    {
      name: sensorData?.name,
      data: _.reverse(valueStack),
    },
  ]

  // FIltering

  return (
    <div>
      <h4 className="font-bold text-xl font-sans">{chartData?.chartLabel}</h4>

      <Chart
        type={chartType}
        series={series}
        options={options}
        width="100%"
        height="400px"
      />

      {enableTimeSelect && (
        <Stack direction={['column', 'row']} spacing={['2', '4']}>
          <Button
            size="sm"
            onClick={() => {
              lastHour()
              setGraphMin(undefined)
              setGraphMax(undefined)
              // setGraphMin(moment().subtract('hours').toDate().getTime())
              // setGraphMax(moment().ad('hours').toDate().getTime())
              setTickAmount(10)
              setValueFormat('HH:mm')
              setChartType('line')
            }}
          >
            {t('graph.hourAgo')}
          </Button>
          <Button
            size="sm"
            onClick={() => {
              setGraphMin(moment().subtract(1, 'days').toDate().getTime())
              setGraphMax(moment().add(2, 'hours').toDate().getTime())
              lastDay()
              setTickAmount(12)
              setValueFormat('HH:mm')
              setChartType('line')
            }}
          >
            {t('graph.dayAgo')}
          </Button>
          <Button
            size="sm"
            onClick={() => {
              setGraphMin(moment().subtract(1, 'weeks').toDate().getTime())
              setGraphMax(moment().add(1, 'days').toDate().getTime())
              lastWeek()
              setTickAmount(7)
              setValueFormat('DD MMM YYYY')
              setChartType('line')
            }}
          >
            {t('graph.weekAgo')}
          </Button>
          <Button
            size="sm"
            onClick={() => {
              lastMonth()
              setGraphMin(moment().subtract(1, 'months').toDate().getTime())
              setGraphMax(moment().add(2, 'days').toDate().getTime())
              setTickAmount(6)
              setValueFormat('DD MMM YYYY')
              setChartType('line')
            }}
          >
            {t('graph.monthAgo')}
          </Button>
        </Stack>
      )}

      <br />
    </div>
  )
}

/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import {
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Button,
  Stack,
  Modal,
  CheckboxGroup,
  ButtonModal,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react'
import { Chip } from '@mui/material'
import * as UserRoles from 'config/UserRoles'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import EditUser from './EditUser'

export default function UserLists({ user, handleDelelte, handleUpdate }) {
  const { t } = useTranslation()
  const { register, handleSubmit, reset, errors, setValue, control } = useForm()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const station = useSelector((state) => state.station)
  const [selectedUser, setSelectedUser] = useState([])
  const [rerender, setRerender] = useState(false)

  const setSelect = (index, user) => {
    // console.log('index = ', index)
    // ดูว่าใน Select Station มี Station ID ตัวนี้มั้ย

    setSelectedUser(user)
    onOpen()
    setRerender(!rerender)
  }

  const handleCloseModal = () => {
    setSelectedUser([])
    onClose()
  }

  const handleSuccess = () => {
    handleUpdate(selectedUser?._id, { duty: selectedUser?.duty })
    handleCloseModal()
  }

  const renderStationSelect = (userDuty) => {
    return _.map(station?.arr, (_station, index) => {
      if (_station?._id === userDuty?.[index]) {
        return index >= 5 ? (
          <Chip className="mx-2" size="small" label={_station?.name} />
        ) : (
          <Chip className="mx-2" size="small" label={'...'} />
        )
      }
    })
  }
  //console.log('selectStation', selectedStation)

  const showDuty = () => {
    return _.map(
      station?.arr,
      (_station, index) => {
        if (_station?._id === selectedUser?.duty?.[index]) {
          return <Chip className="mx-2" size="small" label={_station?.name} />
        }
      }

      // <div
      //   className={`${
      //     _station?._id === selectedStation?.duty?.[index]
      //       ? 'bg-blue-400'
      //       : 'bg-gray-200'
      //   } p-2 px-4 my-2 rounded-md cursor-pointer`}
      //   onClick={() => {
      //     if (selectedStation.duty[index] === _station?._id) {
      //       // ลบ station ตัวนี้ออกจาก Array
      //       selectedStation.duty[index] = null
      //     } else {
      //       // เพิ่มลงใน Array
      //       selectedStation.duty[index] = _station?._id
      //     }
      //     setSelectedStation(selectedStation)
      //     setRerender(!rerender)
      //   }}
      // >
      //   {_station?.name}
      // </div>
    )
  }

  const genUserLists = () =>
    _.map(user, (_user, index) => (
      <Tr key={_user?._id}>
        <Td>{index + 1}</Td>
        <Td>
          {_user?.name}
          {_user?.username && (
            <Chip className="mx-2" size="small" label={_user?.username} />
          )}
        </Td>
        <Td>{renderStationSelect(_user?.duty)}</Td>
        <Td></Td>
        <Td>{_user?.role}</Td>
        {/* <Td>
          <select
            onChange={(e) => {
              const data = { role: e.target.value }
              const confirm = window.confirm(
                'ยืนยันเปลี่ยนบทบาทบัญชีผู้ใช้จาก' +
                  _user.role +
                  'เป็น' +
                  e.target.value
              )
              if (confirm) handleUpdate(_user.id, data)
            }}
            defaultValue={_user.role}
            className="rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          >
            {genUserRolesOptions()}
          </select>
        </Td> */}
        {/* <Td>
          <div className="flex justify-items-center">
            <Button
              variant="solid"
              size="sm"
              colorScheme="yellow"
              onClick={() => setSelect(index, _user)}
            >
              {' '}
              แก้ไขสถานีสถานีที่ต้องการ{' '}
            </Button>

            <Modal isOpen={isOpen} onClose={handleCloseModal} size={'md'}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  <div className="w-full grid justify-items-center">
                    เลือกสถานีที่ต้องการดูแล
                  </div>
                </ModalHeader>
                <ModalBody>
                  <CheckboxGroup colorScheme="blue">
                    <Stack spacing={2} direction={'column'}>
                      {renderStationSelect(_user._id)}
                    </Stack>
                  </CheckboxGroup>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="red"
                    mr={3}
                    size="sm"
                    variant="solid"
                    onClick={handleCloseModal}
                  >
                    {t('utility.cancel')}
                  </Button>
                  <Button
                    colorScheme="green"
                    size="sm"
                    onClick={() => handleSuccess()}
                    type="button"
                  >
                    {t('utility.save')}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </div>
        </Td> */}
        <Td className="space-x-2 center">
          <Button
            colorScheme="yellow"
            size="sm"
            onClick={() => setSelect(index, _user)}
          >
            {t('utility.edit')}
          </Button>

          <Button
            colorScheme="red"
            size="sm"
            onClick={() => {
              if (_user.role === UserRoles.ROLES.SUPER_USER) {
                alert('ไม่สามารถลบบัญชี SUPERUSER')
              } else {
                const confirm = window.confirm('ยืนยันการลบบัญชีผู้ใช้')
                if (confirm) handleDelelte(_user.id)
              }
            }}
          >
            {t('utility.delete')}
          </Button>
          <Modal isOpen={isOpen} onClose={handleCloseModal} size={'md'}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <div className="w-full grid justify-items-center">
                  แก้ไขข้อมูลบัญชีผู้ใช้
                </div>
              </ModalHeader>
              <ModalBody>
                <EditUser
                  user={user}
                  index={index}
                  selectedUser={selectedUser}
                  handleUpdate={handleUpdate}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="red"
                  mr={3}
                  size="sm"
                  variant="solid"
                  onClick={handleCloseModal}
                >
                  {t('utility.cancel')}
                </Button>
                <Button
                  colorScheme="green"
                  size="sm"
                  onClick={() => handleSuccess()}
                  type="button"
                >
                  {t('utility.save')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Td>
      </Tr>
    ))

  const genStationList = () => {
    return _.map(station.arr, (eachStation, index) => (
      <option key={index} value={eachStation._id}>
        {eachStation.name}{' '}
      </option>
    ))
  }

  // const genUserRolesOptions = () => (
  //   <>
  //     <option value={UserRoles.ROLES.USER}>{UserRoles.ROLES.USER}</option>
  //     <option value={UserRoles.ROLES.GENERAL}>{UserRoles.ROLES.GENERAL}</option>
  //     <option value={UserRoles.ROLES.ADMIN}>{UserRoles.ROLES.ADMIN}</option>
  //     <option value={UserRoles.ROLES.SUPER_USER}>
  //       {UserRoles.ROLES.SUPER_USER}
  //     </option>
  //   </>
  // )
  return (
    <div>
      <Table size="md" variant="striped" className="whitespace-no-wrap">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th> {t('utility.name')}</Th>
            <Th>แผนก</Th>
            <Th></Th>
            <Th> {t('utility.role')}</Th>

            <Th> {t('utility.action')}</Th>
          </Tr>
        </Thead>
        <Tbody>{genUserLists()}</Tbody>
      </Table>
    </div>
  )
}

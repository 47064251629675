import React from 'react'
import _ from 'lodash'

import Config from '../../../../config'
import VariableMainRendering from '../../../Variables/Function/VariableMainRendering'

export default function DisplayImageAttribute({
  stationData,
  attributeData,
  diagramData,
  display,
}) {
  let displaySize = diagramData?.imageSize / 5
  let type = diagramData?.diagramTypeImage
  //console.log('attribule', attributeData.dataSource, attributeData)
  if (attributeData.dataSource === Config.TypeOfSource.GaugeSource.sensor) {
    const sensorSourceData = _.find(
      stationData?.sensors,
      (sensor) => sensor.refString === attributeData.sensorRef
    )
    //console.log('attribule', attributeData)
    if (sensorSourceData) {
      if (attributeData.isActiveHigh === true) {
        return (
          <div>
            {sensorSourceData.booleanStatus === true ? (
              <div className="flex justify-center">
                <img
                  src={`/images/${type}/On` + diagramData.diagramImage}
                  alt={diagramData.diagramName}
                  className={`w-${displaySize} h-20 self-end `}
                />
              </div>
            ) : (
              <div className="flex justify-center">
                <img
                  src={`/images/${type}/Off` + diagramData.diagramImage}
                  alt={diagramData.diagramName}
                  className={`w-${displaySize} h-20 self-end `}
                />
              </div>
            )}
          </div>
        )
      } else {
        return (
          <div>
            {sensorSourceData.booleanStatus === false ? (
              <img
                src={`/images/${type}/Off` + diagramData.diagramImage}
                alt={diagramData.diagramName}
                className={`w-${displaySize} h-20 self-end `}
              />
            ) : (
              <img
                src={`/images/${type}/On` + diagramData.diagramImage}
                alt={diagramData.diagramName}
                className={`w-${displaySize} h-20 self-end `}
              />
            )}
          </div>
        )
      }
    } else {
      return <div></div>
    }
  } else if (
    attributeData?.dataSource === Config?.TypeOfSource?.GaugeSource?.variable
  ) {
    const variableData = VariableMainRendering(
      stationData,
      attributeData?.variableId
    )
    if (attributeData?.isActiveHigh === true) {
      return (
        <div>
          {variableData === true ? (
            <img
              src={`/images/${type}/On` + diagramData.diagramImage}
              alt={diagramData.diagramName}
              className={`w-${displaySize} h-20 self-end `}
            />
          ) : (
            <img
              src={`/images/${type}/Off` + diagramData.diagramImage}
              alt={diagramData.diagramName}
              className={`w-${displaySize} h-20 self-end `}
            />
          )}
        </div>
      )
    } else {
      return (
        <div>
          {variableData === false ? (
            <img
              src={`/images/${type}/Off` + diagramData.diagramImage}
              alt={diagramData.diagramName}
              className={`w-${displaySize} h-20 self-end `}
            />
          ) : (
            <img
              src={`/images/${type}/On` + diagramData.diagramImage}
              alt={diagramData.diagramName}
              className={`w-${displaySize} h-20 self-end `}
            />
          )}
        </div>
      )
    }
  } else {
    return display === true ? (
      <div className="border ">
        {' '}
        <img
          src={`/images/${type}/` + diagramData.diagramImage}
          alt={diagramData.diagramName}
          style={{
            scale: `${displaySize}`,
          }}
          //className={`w-${displaySize} h-20 self-end `}
          className="w-full h-full"
        />
      </div>
    ) : (
      <div className="border ">
        {' '}
        <img
          src={`/images/${type}/` + diagramData.diagramImage}
          alt={diagramData.diagramName}
          className={`w-${displaySize} h-20 self-end `}
        />
      </div>
    )
  }
}

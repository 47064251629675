import React from 'react'
import PropTypes from 'prop-types'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/th'
import dayjs from 'dayjs'

dayjs.locale('th')
dayjs.extend(buddhistEra)
dayjs.extend(LocalizedFormat)

const HeaderReportType1 = (form, date) => ({
  margin: [30, 20, 25, 0],
  style: 'tableExample',
  table: {
    widths: ['23%', '48%', '14.5%', '14.1%'],
    body: [
      [
        {
          image: 'logo_imge',
          rowSpan: 2,
          fit: [30, 30],
          alignment: 'center',
          margin: [0, 2, 0, 0],
          border: [true, true, true, false],
        },
        {
          text: `${form?.name || ''}`,
          alignment: 'center',
          bold: true,
          fontSize: 16,
          margin: [0, 0, 0, -3],
          border: [true, true, true, false],
        },
        {
          text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
          style: 'header',
          alignment: 'center',
          colSpan: 2,
          border: [true, true, true, true],
        },
        { text: '', border: [false, true, true, true] },
      ],
      [
        { text: '', border: [true, false, true, false] },
        {
          text: '',
          border: [true, false, true, false],
        },
        {
          text: `แก้ไขครั้งที่: 0${form?.editVersion || ''}`,
          style: 'header',
          margin: [0, 2, 0, 0],
          alignment: 'center',
          border: [true, true, true, true],
        },
        {
          text: 'หน้าที่ : 1/1',
          style: 'header',
          margin: [0, 2, 0, 0],
          alignment: 'center',
          border: [true, true, true, true],
        },
      ],
      [
        {
          text: 'THAI TALLOW AND OIL CO.,LTD.',
          alignment: 'center',
          bold: true,
          font: 'Impac',
          fontSize: 12,
          border: [true, false, true, true],
        },
        {
          text: `วันที่ ${dayjs(date).format('DD เดือน MMMM พ.ศ. BBBB')}`,
          alignment: 'center',
          style: 'header',
          border: [true, false, true, true],
        },
        {
          text: `วันที่บังคับใช้ : ${
            dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
          }`,
          style: 'header',
          colSpan: 2,
          margin: [0, 2, 0, 0],
          alignment: 'center',
          border: [true, true, true, true],
        },
        {},
      ],
    ],
  },
})

HeaderReportType1.propTypes = {
  form: PropTypes.object,
}

export default HeaderReportType1

import React from 'react'
import PropTypes from 'prop-types'

const FooterReportSigleComment = () => ({
  alignment: 'between',
  margin: [10, 0, 10, 0],
  table: {
    widths: [`50%`, `50%`],
    body: [
      [
        {
          text: 'หมายเหตุ ....................................................................................................................................................................................................\n...................................................................................................................................................................................................................',
          alignment: 'left',
          border: [true, true, true, false],
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'ผู้รายงานกะ A \n1.ลงชื่อ................................ 2.ลงชื่อ...............................',
          alignment: 'center',
          border: [true, true, true, false],
        },
        {
          text: `ผู้รายงานกะ B \n1.ลงชื่อ................................ 2.ลงชื่อ............................... `,
          style: 'header',
          alignment: 'center',
          border: [true, true, true, false],
        },
      ],
      [
        {
          text: '    (.......................................)    (.......................................)  ',
          alignment: 'center',
          border: [true, false, true, false],
        },
        {
          text: '    (.......................................)    (.......................................)  ',
          alignment: 'center',
          border: [true, false, true, false],
        },
      ],
      [
        {
          text: 'หัวหน้างานกะ A ลงชื่อ.......................................',
          alignment: 'center',
          border: [true, false, true, false],
        },
        {
          text: 'หัวหน้างานกะ B ลงชื่อ.......................................',
          style: 'header',
          alignment: 'center',
          border: [true, false, true, false],
        },
      ],
      [
        {
          text: '    (.........................................................)   ',
          alignment: 'center',
          border: [true, false, true, true],
        },
        {
          text: '    (.........................................................)    ',
          alignment: 'center',
          border: [true, false, true, true],
        },
      ],
    ],
  },
  styles: {
    header: {
      fontSize: 8,
      bold: true,
      alignment: 'right',
    },
    header1: {
      fontSize: 12,
      bold: true,
    },
  },
})

FooterReportSigleComment.propTypes = {}

export default FooterReportSigleComment

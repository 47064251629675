import React from 'react'
import PropTypes from 'prop-types'

function FooterReportWastWater() {
  return {
    margin: [20, 50, 20, 0],
    style: 'tableExample',
    table: {
      widths: ['33.33%', '33.33%', '33.33%'],
      body: [
        [
          {
            text: 'ปริมาณผลปาล์มสดเข้ากระบวนการผลิตประจำเดือน = ................. ตัน ',
            alignment: 'left',
            fontSize: '14',
            border: [false, false, false, false],
          },
          {
            text: 'ปริมาณน้ำเสียจาก 50% FFB = ......................... ลบ.ม',
            alignment: 'center',
            fontSize: '14',
            border: [false, false, false, false],
          },
          {
            text: '',
            alignment: 'right',

            border: [false, false, false, false],
          },
        ],
        [
          {
            text: ' ',
            alignment: 'center',
            border: [false, false, false, false],
          },
          {
            text: ' ',
            alignment: 'center',
            border: [false, false, false, false],
          },
          {
            text: ' ',
            alignment: 'center',
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: ' ',
            alignment: 'center',
            border: [false, false, false, false],
          },
          {
            text: ' ',
            alignment: 'center',
            border: [false, false, false, false],
          },
          {
            text: ' ',
            alignment: 'center',
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: 'ผู้บันทึก.....................................................................',
            alignment: 'left',
            border: [false, false, false, false],
          },
          {
            text: ' ',
            alignment: 'center',
            border: [false, false, false, false],
          },
          {
            text: '( หัวหน้าแผนก ) ............................................................',
            alignment: 'right',
            border: [false, false, false, false],
          },
        ],
      ],
    },
  }
}

FooterReportWastWater.propTypes = {}

export default FooterReportWastWater

import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { text } from '@fortawesome/fontawesome-svg-core'
import checkImage from './checkImage.json'
import checkCloseImage from './checkCloseImage.json'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderHourMeter = (dataHourStart, dataHourEnd) => {
  if (dataHourStart?.length === 0 && dataHourEnd?.length === 0) {
    const content = [
      [
        {
          text: 'Hour Meter เริ่มใช้งาน : ',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter หลังใช้งาน : ',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter เริ่มใช้งาน : ',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter หลังใช้งาน : ',
          style: 'headerTable',
          alignment: 'left',
        },
      ],
    ]
    return content
  } else if (dataHourEnd.length === 0) {
    const content = [
      [
        {
          text: `Hour Meter เริ่มใช้งาน : ${
            dataHourStart?.[0]?.PURIFIERNo1 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(
                  dataHourStart?.[0]?.PURIFIERNo1
                )
          }`,
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter หลังใช้งาน : ',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: `Hour Meter เริ่มใช้งาน : ${
            dataHourStart?.[0]?.PURIFIERNo2 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(
                  dataHourStart?.[0]?.PURIFIERNo2
                )
          }`,
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter หลังใช้งาน : ',
          style: 'headerTable',
          alignment: 'left',
        },
      ],
    ]
    return content
  } else if (dataHourStart?.length === 0) {
    const content = [
      [
        {
          text: 'Hour Meter เริ่มใช้งาน :  ',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: `Hour Meter หลังใช้งาน : ${
            dataHourEnd?.[0]?.PURIFIERNo1 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(dataHourEnd?.[0]?.PURIFIERNo1)
          }`,
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter เริ่มใช้งาน : ',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: `Hour Meter หลังใช้งาน : ${
            dataHourEnd?.[0]?.PURIFIERNo2 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(dataHourEnd?.[0]?.PURIFIERNo2)
          }`,
          style: 'headerTable',
          alignment: 'left',
        },
      ],
    ]
    return content
  } else {
    const content = [
      [
        {
          text: `Hour Meter เริ่มใช้งาน : ${
            dataHourStart?.[0]?.PURIFIERNo1 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(
                  dataHourStart?.[0]?.PURIFIERNo1
                )
          }`,
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: `Hour Meter หลังใช้งาน : ${
            dataHourEnd?.[0]?.PURIFIERNo1 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(dataHourEnd?.[0]?.PURIFIERNo1)
          }`,
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: `Hour Meter เริ่มใช้งาน : ${
            dataHourStart?.[0]?.PURIFIERNo2 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(
                  dataHourStart?.[0]?.PURIFIERNo2
                )
          }`,
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: `Hour Meter หลังใช้งาน : ${
            dataHourEnd?.[0]?.PURIFIERNo2 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(dataHourEnd?.[0]?.PURIFIERNo2)
          }`,
          style: 'headerTable',
          alignment: 'left',
        },
      ],
    ]
    return content
  }
  return null
}

const renderMapTime = (data, timeMap) => {
  const content = []
  _.map(timeMap, (time, index) => {
    let indexTime = false
    if (data?.length === 0) {
      return content.push([
        { text: time, style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    } else {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (time === eachData?.TimeStart) {
          indexTime = true
          content.push([
            { text: eachData?.TimeStart || '', style: 'tableContent' },
            {
              image:
                eachData?.PURIFIERNo1.เวลาเปิด === true
                  ? checkImage?.image
                  : checkCloseImage?.image,
              fit: [9, 9],
              alignment: 'center',
            },
            {
              image:
                eachData?.PURIFIERNo1.เวลาปิด === true
                  ? checkImage?.image
                  : checkCloseImage?.image,
              fit: [9, 9],
              alignment: 'center',
            },
            {
              image:
                eachData?.PURIFIERNo1.เวลาล้างเครื่อง === true
                  ? checkImage?.image
                  : checkCloseImage?.image,
              fit: [9, 9],
              alignment: 'center',
            },
            {
              text: eachData?.PURIFIERNo1.อัตราการไหล || '-',
              style: 'tableContent',
            },
            { text: eachData?.PURIFIERNo1.Temp || '-', style: 'tableContent' },
            {
              image:
                eachData?.PURIFIERNo2.เวลาเปิด === true
                  ? checkImage?.image
                  : checkCloseImage?.image,
              fit: [9, 9],
              alignment: 'center',
            },
            {
              image:
                eachData?.PURIFIERNo2.เวลาปิด === true
                  ? checkImage?.image
                  : checkCloseImage?.image,
              fit: [9, 9],
              alignment: 'center',
            },
            {
              image:
                eachData?.PURIFIERNo2.เวลาล้างเครื่อง === true
                  ? checkImage?.image
                  : checkCloseImage?.image,
              fit: [9, 9],
              alignment: 'center',
            },
            {
              text: eachData?.PURIFIERNo2.อัตราการไหล || '-',
              style: 'tableContent',
            },
            { text: eachData?.PURIFIERNo2.Temp || '-', style: 'tableContent' },
            { text: eachData?.OilTank || '-', style: 'tableContent' },
            { text: eachData?.Vacuum || '-', style: 'tableContent' },
            { text: eachData?.comment || '', style: 'tableContent' },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: time, style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    }
  })
  return content
}

const FM_PD_005 = async (form, formInput, subForm, system, date) => {
  const colors = '#dddddd'
  const timeMap = [
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
  ]
  //find data for map in table
  const dataPurifier = _.filter(formInput?.arr, {
    subForm_name: 'PURIFIER No.1-2 & Oil Tank & Vacuum',
  })
  const dataHourStart = _.filter(formInput?.arr, {
    HourMeter: 'เริ่มใช้งาน',
  })
  const dataHourEnd = _.filter(formInput?.arr, {
    HourMeter: 'หลังใช้งาน',
  })
  const contentHourMeter = renderHourMeter(dataHourStart, dataHourEnd)
  const TableBody = renderMapTime(dataPurifier, timeMap)
  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [30, 69, 30, 0],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      {
        margin: [30, 20, 30, 0],
        table: {
          widths: ['23%', '48%', '20%', '9%'],
          body: [
            [
              {
                image: 'logo_imge',
                fit: [20, 20],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: `${form?.name || ''} - (${form?.docNo || ''})`,
                alignment: 'center',
                bold: true,
                fontSize: 16,
                margin: [0, 0, 0, -3],
                border: [true, true, true, false],
              },
              {
                text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
                style: 'header',
                alignment: 'center',
                colSpan: 2,
                border: [true, true, true, true],
              },
              { text: '', border: [false, true, true, true] },
            ],
            [
              {
                text: `THAI TALLOW AND OIL CO.,LTD.`,
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: 12,
                border: [true, false, true, true],
              },
              {
                text: `วันที่ ${dayjs(date).format(
                  'DD เดือน MMMM พ.ศ. BBBB '
                )}`,
                alignment: 'center',
                style: 'header',
                border: [true, false, true, true],
              },
              {
                text: `วันที่บังคับใช้ : ${
                  dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                }`,
                style: 'header',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: `แก้ไขครั้งที่: 0${form?.editVersion || '-'}`,
                style: 'header',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
          ],
        },
      },
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        table: {
          widths: ['23%', '24%', '23%', '30%'],
          body: [
            [
              {
                text: 'PURIFIER No.1',
                colSpan: 2,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {
                text: 'PURIFIER No.2',
                colSpan: 2,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
            ],
            ...contentHourMeter,
          ],
        },
      },
      {
        margin: [0, 0, 0, 0],
        table: {
          widths: [
            '8%',
            '4.5%',
            '4.5%',
            '5%',
            '8%',
            '6%',
            '4.5%',
            '4.7%',
            '6.4%',
            '9%',
            '8%',
            '8%',
            '11.7%',
            '11.7%',
          ],
          body: [
            [
              //Row 1
              {
                stack: [
                  {
                    text: 'ค่าบันทึก',
                    bold: true,
                    style: 'headerTable',
                    alignment: 'left',
                  },
                  {
                    canvas: [
                      {
                        type: 'line',
                        x1: 56,
                        y1: -12,
                        x2: -4,
                        y2: 32,
                        lineWidth: 1,
                        lineColor: '#000000',
                      },
                    ],
                  },
                  {
                    text: 'เวลาที่บันทึก',
                    alignment: 'right',
                    margin: [0, -16, 0, 0],
                    bold: true,
                  },
                ],
                rowSpan: 3,
              },
              {
                text: 'PURIFIER No.1',
                colSpan: 5,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
              {},
              {},
              {
                text: 'PURIFIER No.2',
                colSpan: 5,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
              {},
              {},
              { text: '', border: [true, true, true, false] },
              { text: '', border: [true, true, true, false] },

              { text: '', border: [true, true, true, false] },
            ],
            [
              //Row 2

              {},

              {
                text: 'เวลาเปิด',
                rowSpan: 2,
                margin: [0, 4],
                style: 'headerTable',
              },
              {
                text: 'เวลาปิด',
                rowSpan: 2,
                style: 'headerTable',
                margin: [0, 4],
              },
              {
                text: 'เวลา',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'อัตราการไหล',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'Temp',
                style: 'headerTable',
                border: [true, false, false, false],
              },
              {
                text: 'เวลาเปิด',
                rowSpan: 2,
                margin: [0, 4],
                style: 'headerTable',
              },
              {
                text: 'เวลาปิด',
                margin: [0, 4],
                style: 'headerTable',
                rowSpan: 2,
              },
              {
                text: 'เวลา',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'อัตราการไหล',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'Temp',
                border: [true, false, false, false],
                style: 'headerTable',
              },
              {
                text: 'Oil Tank',
                border: [true, false, true, false],
                style: 'headerTable',
                margin: [0, -10, 0, -2],
              },
              {
                text: 'Vacuum',
                border: [true, false, true, false],
                style: 'headerTable',
                margin: [0, -10, 0, -2],
              },
              {
                text: 'หมายเหตุ',
                style: 'headerTable',
                border: [true, false, true, false],
              },
            ],
            [
              //Row 3
              {
                text: 'เวลาที่บันทึก',
                style: 'headerTable',
                alignment: 'right',
                border: [true, false, true, true],
              },

              {},
              {},
              {
                text: 'ล้างเครื่อง',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(ตัน/ชม)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(75-90 ํC)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {},
              {},
              {
                text: 'ล้างเครื่อง',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(ตัน/ชม)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(75-90 ํC)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(88-95 C ํ)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(0.7-0.9bar)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              { text: '', border: [true, false, true, true] },
            ],
            ...TableBody,
          ],
        },
      },
      {
        alignment: 'between',
        table: {
          widths: [`53.75%`, `46.25%`],
          body: [
            [
              {
                text: 'ผู้รายงานกะ A 1.ลงชื่อ................................................. 2.ลงชื่อ................................................',
                alignment: 'left',
                style: 'headerTable',
                margin: [0, 0, 0, -2],
                border: [true, true, true, false],
              },
              {
                text: `ผู้รายงานกะ B 1.ลงชื่อ...................................................... 2.ลงชื่อ......................................................`,
                style: 'headerTable',
                margin: [0, 0, 0, -2],
                alignment: 'left',
                border: [false, true, true, false],
              },
            ],
            [
              {
                text: '    (.......................................)     (.......................................)  ',
                alignment: 'center',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: '    (.......................................)    (.......................................)  ',
                alignment: 'center',
                style: 'headerTable',
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'หัวหน้างานกะ A ลงชื่อ................................................................',
                alignment: 'left',
                style: 'headerTable',
                margin: [0, 0, 0, 0],
                border: [true, false, true, false],
              },
              {
                text: 'หัวหน้างานกะ B ลงชื่อ................................................................',
                style: 'headerTable',
                alignment: 'left',
                margin: [0, 0, 0, 0],
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: '(.........................................................)   ',
                alignment: 'center',
                style: 'headerTable',
                margin: [-100, 0, 0, 0],
                border: [true, false, true, true],
              },
              {
                text: '(.........................................................)    ',
                alignment: 'center',
                style: 'headerTable',
                margin: [-100, 0, 0, 0],
                border: [false, false, true, true],
              },
            ],
          ],
        },
        styles: {
          header: {
            fontSize: 8,
            bold: true,
            alignment: 'right',
          },
          header1: {
            fontSize: 12,
            bold: true,
          },
        },
      },
    ],

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: {
        font: 'FontAwesome',
        bold: true,
        alignment: 'center',
        fontSize: 10,
      },
      header: {
        bold: true,
        fontSize: 14,
        margin: [0, -2, 0, -3],
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        margin: [-3, -3, -3, -2],
        fontSize: 13,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 13,
        margin: [0, -4, 0, -2],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_PD_005

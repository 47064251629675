import React, { useState } from 'react'
import _ from 'lodash'
import {
  Switch,
  FormControl,
  Input,
  Select,
  Button,
  Tr,
  Td,
} from '@chakra-ui/react'

export default function OperationSensorAddingComponent({
  stationData,
  rendered,
  setRendered,
  operationList,
  setOperationList,
}) {
  const [selectedOperation, setSelectedOperation] = useState('')
  const [leftSensorRef, setLeftSensorRef] = useState('')
  const [rightSensorRef, setRightSensorRef] = useState('')

  const sensorRefList = () =>
    _.map(stationData.sensors, (sensor) => (
      <>
        <option value={sensor.refString}> {sensor.refString} </option>
      </>
    ))

  const gaugeOperation = () => (
    <>
      <option value="AND">AND</option>
      <option value="OR">OR</option>
      <option value="NOR">NOR</option>
      <option value="NAND">NAND</option>
      <option value="NOP">สิ้นสุดสมการ</option>
    </>
  )

  const handlingAddOperation = () => {
    const tempOperationComponent = {
      leftSensorRef: _.size(operationList) === 0 ? leftSensorRef : null,
      rightSensorRef: rightSensorRef,
      operation: selectedOperation,
    }
    let tempOperationList = operationList
    tempOperationList.push(tempOperationComponent)
    setOperationList(tempOperationList)
    console.log(operationList)
    setRendered(!rendered)
  }

  return (
    <Tr>
      <Td></Td>
      <Td>
        {_.size(operationList) === 0 ? (
          <>
            <Select
              className="w-1/3"
              size="sm"
              onChange={(event) => {
                setLeftSensorRef(event.target.value)
              }}
            >
              <option value="">เลือกเซ็นเซอร์</option>
              {sensorRefList()}
            </Select>
          </>
        ) : (
          <>
            <label>ผลจากส่วนที่ {_.size(operationList)} </label>
          </>
        )}
      </Td>
      <Td>
        <Select
          className="w-1/3"
          size="sm"
          onChange={(event) => setSelectedOperation(event.target.value)}
        >
          <option value="">เลือกตัวดำเนินการ</option>
          {gaugeOperation()}
        </Select>
      </Td>
      <Td>
        <Select
          className="w-1/3"
          size="sm"
          onChange={(event) => {
            setRightSensorRef(event.target.value)
          }}
        >
          <option value="">เลือกเซ็นเซอร์</option>
          {sensorRefList()}
        </Select>
      </Td>
      <Td>
        <Button
          colorScheme="blue"
          size="sm"
          className="self-center"
          type="button"
          onClick={() => handlingAddOperation()}
        >
          เพิ่ม
        </Button>
      </Td>
    </Tr>
  )
}

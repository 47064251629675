import React from 'react'
import _ from 'lodash'
import { Checkbox, FormControl, Input, Select, Badge } from '@chakra-ui/react'

/**
 * @deprecated since 2021-04-14
 */
export default function EditNumberDisplay({
    gaugeData,
    register,
    stationData,
}) {
    const genRefStringList = () => {
        return _.map(stationData.sensors, (sensor) => (
            <option value={sensor.refString}>{sensor.refString}</option>
        ))
    }
    return (
        <>
            <label>
                <Badge colorScheme="yellow" size="sm">
                    ระบบเก่า
                </Badge>
                ลองใช้เกจ ตัวเลข ตัวใหม่ที่รองรับทั้งค่าจากเซ็นเซอร์และตัวแปร
                กดเลือกเกจเป็น แสดงค่าตัวเลขอีกครั้ง เพื่อเริ่มใช้ฟีเจอร์นี้
            </label>
            <FormControl>
                <label className="text-sm font-bold">
                    {' '}
                    เซ็นเซอร์ที่ต้องการเชื่อมต่อ{' '}
                </label>
                <Select
                    defaultValue={gaugeData.sensorRefString}
                    ref={register}
                    size="sm"
                    name="sensorRefString"
                >
                    {genRefStringList()}
                </Select>
            </FormControl>

            <FormControl>
                <label className="text-sm font-bold">หน่วย</label>
                <Input
                    type="text"
                    name="valueUnit"
                    size="sm"
                    defaultValue={gaugeData.valueUnit}
                    ref={register}
                />
            </FormControl>
        </>
    )
}

/* eslint-disable no-plusplus */
import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { faL } from '@fortawesome/free-solid-svg-icons'
import { color } from '@chakra-ui/react'
import { log } from 'mathjs'
import { formInputPost } from 'redux/actions'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}
const rowTotal = (data) => {
  return (
    parseFloat(data.GasEngines1.Peak === '' ? 0 : data.GasEngines1.Peak) +
    parseFloat(data.GasEngines1.Holiday === '' ? 0 : data.GasEngines1.Holiday) +
    parseFloat(data.GasEngines2.Holiday === '' ? 0 : data.GasEngines2.Holiday) +
    parseFloat(data.GasEngines2.Peak === '' ? 0 : data.GasEngines2.Peak) +
    parseFloat(
      data.GasEngines1['Off Peak'] === '' ? 0 : data.GasEngines1['Off Peak']
    ) +
    parseFloat(
      data.GasEngines2['Off Peak'] === '' ? 0 : data.GasEngines2['Off Peak']
    )
  )
}

const TotalManufacture = (data) => {
  return (
    parseFloat(data.GasEngines1.Peak === '' ? 0 : data.GasEngines1.Peak) +
    parseFloat(data.GasEngines1.Holiday === '' ? 0 : data.GasEngines1.Holiday) +
    parseFloat(data.GasEngines2.Holiday === '' ? 0 : data.GasEngines2.Holiday) +
    parseFloat(data.GasEngines2.Peak === '' ? 0 : data.GasEngines2.Peak) +
    parseFloat(
      data.GasEngines1['Off Peak'] === '' ? 0 : data.GasEngines1['Off Peak']
    ) +
    parseFloat(
      data.GasEngines2['Off Peak'] === '' ? 0 : data.GasEngines2['Off Peak']
    ) +
    parseFloat(data.UseControl === '' ? 0 : data.UseControl)
  )
}

const TotalPEA = (data) => {
  return (
    parseFloat(data.ElectricPEA.Holiday === '' ? 0 : data.ElectricPEA.Holiday) +
    parseFloat(
      data.ElectricPEA['Off Peak'] === '' ? 0 : data.ElectricPEA['Off Peak']
    ) +
    parseFloat(data.ElectricPEA.Peak === '' ? 0 : data.ElectricPEA.Peak)
  )
}

const renderTableBody = (data) => {
  const DataSet = []
  const timeMap = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ]

  //console.log('data ', data)
  const colorsLightBlue = '#9FC5E8'
  const colorsLightPurple = '#DFD3FF'
  if (_.isEmpty(data)) {
    for (let i = 0; i < 31; i++) {
      DataSet.push([
        { text: i + 1, style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent', fillColor: colorsLightPurple },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent', fillColor: colorsLightPurple },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent', fillColor: colorsLightBlue },
      ])
    }
    return DataSet
  } else {
    _.map(timeMap, (_day, i) => {
      const testArrayy = _.map(data, (row, j) => {
        if (parseFloat(dayjs(row?.date).format('D')) === _day) {
          DataSet.push([
            {
              text: _day,
              style: 'tableContent',
            },
            { text: row?.TimeRun.No1 || '-', style: 'tableContent' },
            { text: row?.TimeRun.No2 || '-', style: 'tableContent' },
            { text: row?.GasEngines1.Peak || '-', style: 'tableContent' },
            {
              text: row?.GasEngines1['Off Peak'] || '-',
              style: 'tableContent',
            },
            { text: row?.GasEngines1.Holiday || '-', style: 'tableContent' },
            { text: row?.GasEngines2.Peak || '-', style: 'tableContent' },
            {
              text: row?.GasEngines2['Off Peak'] || '-',
              style: 'tableContent',
            },
            { text: row?.GasEngines2.Holiday || '-', style: 'tableContent' },
            {
              text: rowTotal(row) || '0',
              style: 'tableContent',
              fillColor: colorsLightPurple,
            },
            { text: row?.UseControl || '-', style: 'tableContent' },
            {
              text: TotalManufacture(row) || '0',
              style: 'tableContent',
              fillColor: colorsLightPurple,
            },
            { text: row?.ElectricPEA.Peak || '-', style: 'tableContent' },
            {
              text: row?.ElectricPEA['Off Peak'] || '-',
              style: 'tableContent',
            },
            { text: row?.ElectricPEA.Holiday || '-', style: 'tableContent' },
            {
              text: TotalPEA(row) || '0',
              style: 'tableContent',
              fillColor: colorsLightBlue,
            },
          ])

          return { pu: 1 }
        } else {
          return { pu: 2 }
        }
      })

      //console.log('testArrayy', testArrayy)

      const sumTestArrayy = _.sumBy(
        testArrayy,
        (_testArrayy) => _testArrayy?.pu
      )

      //console.log('sumTestArrayy', sumTestArrayy)

      if (sumTestArrayy === data.length * 2) {
        DataSet.push([
          { text: _day, style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: ' ', style: 'tableContent' },
          { text: ' ', style: 'tableContent' },
          { text: ' ', style: 'tableContent' },
          { text: ' ', style: 'tableContent' },
          { text: ' ', style: 'tableContent' },
          { text: ' ', style: 'tableContent' },
          { text: ' ', style: 'tableContent' },
          { text: ' ', style: 'tableContent', fillColor: colorsLightPurple },
          { text: ' ', style: 'tableContent' },
          { text: ' ', style: 'tableContent', fillColor: colorsLightPurple },
          { text: ' ', style: 'tableContent' },
          { text: ' ', style: 'tableContent' },
          { text: ' ', style: 'tableContent' },
          { text: ' ', style: 'tableContent', fillColor: colorsLightBlue },
        ])
      }
    })
  }

  return DataSet
}

const FM_BG_003 = async (form, formInput, subForm, system, date) => {
  const colorsGreen = '#64EF28'
  const colorsYellow = '#FFD249'
  const colorsBlue = '#3D85C6'
  const colorsPurple = '#9D80E7'
  const colorsLightBlue = '#9FC5E8'
  const colorsPink = '#f0c2c2'

  const TableBody = renderTableBody(formInput.arr)

  const colNo1Sum = _.sumBy(formInput.arr, (e) =>
    parseFloat(e.TimeRun.No1 === '' ? 0 : e.TimeRun.No1)
  )
  const colNo2Sum = _.sumBy(formInput.arr, (e) =>
    parseFloat(e.TimeRun.No2 === '' ? 0 : e.TimeRun.No2)
  )
  //  console.log('colNo2', colNo2)
  const colNo1Peak = _.sumBy(formInput.arr, (e) =>
    parseFloat(e.GasEngines1.Peak === '' ? 0 : e.GasEngines1.Peak)
  )
  const colNo1OffPeak = _.sumBy(formInput.arr, (e) =>
    parseFloat(e.GasEngines1['Off Peak'] === '' ? 0 : e.GasEngines1['Off Peak'])
  )
  const colNo1Holiday = _.sumBy(formInput.arr, (e) =>
    parseFloat(e.GasEngines1.Holiday === '' ? 0 : e.GasEngines1.Holiday)
  )
  const colNo2Peak = _.sumBy(formInput.arr, (e) =>
    parseFloat(e.GasEngines2.Peak === '' ? 0 : e.GasEngines2.Peak)
  )
  const colNo2OffPeak = _.sumBy(formInput.arr, (e) =>
    parseFloat(e.GasEngines2['Off Peak'] === '' ? 0 : e.GasEngines2['Off Peak'])
  )
  const colNo2Holiday = _.sumBy(formInput.arr, (e) =>
    parseFloat(e.GasEngines2.Holiday === '' ? 0 : e.GasEngines2.Holiday)
  )
  const colUseControl = _.sumBy(formInput.arr, (e) =>
    parseFloat(e.UseControl === '' ? 0 : e.UseControl)
  )
  const colPEAPeak = _.sumBy(formInput.arr, (e) =>
    parseFloat(e.ElectricPEA.Peak === '' ? 0 : e.ElectricPEA.Peak)
  )
  const colPEAOffPeak = _.sumBy(formInput.arr, (e) =>
    parseFloat(e.ElectricPEA['Off Peak'] === '' ? 0 : e.ElectricPEA['Off Peak'])
  )
  const colPEAHoliday = _.sumBy(formInput.arr, (e) =>
    parseFloat(e.ElectricPEA.Holiday === '' ? 0 : e.ElectricPEA.Holiday)
  )

  const colTotal = (dataArray) => {
    let total = 0
    for (let i = 0; i < dataArray.length; i++) {
      total += rowTotal(dataArray[i])
    }
    return total
  }

  const colTotalManufacture = (dataArray) => {
    let total = 0
    for (let i = 0; i < dataArray.length; i++) {
      total += TotalManufacture(dataArray[i])
    }
    return total
  }

  const colTotalPEA = (dataArray) => {
    let total = 0
    for (let i = 0; i < dataArray.length; i++) {
      total += TotalPEA(dataArray[i])
    }
    return total
  }

  // console.log('colNo1', colNo1)
  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [10, 69, 10, 10],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      {
        margin: [10, 8, 10, 0],
        style: 'tableExample',
        table: {
          widths: ['20%', '40%', '40%'],
          body: [
            [
              {
                image: 'logo_imge',
                rowSpan: 2,
                fit: [27, 27],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: `${form?.name || ''} - (FM-BG-003)`,
                alignment: 'center',
                bold: true,
                rowSpan: 2,
                font: 'AngsanaNew',
                fontSize: '18',
                margin: [0, 5],
                border: [true, true, true, false],
              },
              {
                text: `Number issue: ${form?.docNo || '-'}  `,
                style: 'headerTable',
                alignment: 'left',
                margin: [15, 2, 0, 0],
              },
            ],
            [
              { text: '', alignment: 'center' },
              { text: '', alignment: 'center' },
              {
                text: `Date issue: ${
                  dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                }`,
                style: 'headerTable',
                alignment: 'left',
                margin: [15, 2, 0, -2],
              },
            ],
            [
              {
                text: 'THAITALLOW AND OIL CO.,LTD.',
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: '8',
                border: [true, false, true, true],
              },
              {
                text:
                  `Month ${dayjs(date).format('MMMM')} ` +
                  `Year ${dayjs(date).format('YYYY')} `,
                alignment: 'center',
                bold: true,
                fontSize: '14',
                border: [true, false, true, true],
              },

              {
                text: 'Modify No: 00',
                style: 'headerTable',
                alignment: 'left',
                margin: [15, 2, 0, -2],
              },
            ],
          ],
        },
      },
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        table: {
          widths: [
            '5%',
            '5%',
            '5%',
            '6%',
            '7%',
            '7%',
            '6%',
            '7%',
            '7%',
            '6%',
            '6%',
            '6%',
            '6%',
            '7%',
            '7%',
            '7%',
          ],
          body: [
            [
              // Row 1
              {
                text: 'Electric produce',
                style: 'headerTable',
                colSpan: 12,
                fillColor: colorsYellow,
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {
                text: 'Electric Import from PEA',
                style: 'headerTable',
                colSpan: 4,
                rowSpan: 2,
                margin: [0, 4, 0, -4],
                fillColor: colorsLightBlue,
              },
              {},
              {},
              {},
            ],
            [
              // Row 2
              {
                text: '',
                fillColor: colorsYellow,
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'Time of run',
                style: 'headerTable',
                colSpan: 2,
                fillColor: colorsYellow,
              },
              {},
              {
                text: 'Gas Engine',
                style: 'headerTable',
                colSpan: 6,
                fillColor: colorsYellow,
              },
              {},
              {},
              {},
              {},
              {},
              {
                text: 'Total',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 4, 0, -4],
                fillColor: colorsGreen,
              },
              {
                text: 'Use control',
                style: 'headerTable',
                rowSpan: 2,
                fillColor: colorsBlue,
              },
              {
                text: 'ผลิต Total',
                style: 'headerTable',
                rowSpan: 2,
                fillColor: colorsGreen,
              },
              {},
              {},
              {},
              {},
            ],
            [
              // Row 3
              {
                text: 'Date',
                style: 'headerTable',
                border: [true, false, true, false],
                fillColor: colorsYellow,
              },
              {
                text: 'No.1',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 4, 0, -4],
                fillColor: colorsGreen,
              },
              {
                text: 'No.2',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 4, 0, -4],
                fillColor: colorsGreen,
              },
              {
                text: 'No.1',
                style: 'headerTable',
                colSpan: 3,
                fillColor: colorsYellow,
              },
              {},
              {},
              {
                text: 'No.2',
                style: 'headerTable',
                colSpan: 3,
                fillColor: colorsYellow,
              },
              {},
              {},
              {},
              {},
              {},
              { text: 'Peak', style: 'headerTable', fillColor: colorsPurple },
              {
                text: 'Off Peak',
                style: 'headerTable',
                margin: [-3, -2],
                fillColor: colorsPurple,
              },
              {
                text: 'Holiday',
                style: 'headerTable',
                fillColor: colorsPurple,
              },
              { text: 'Total', style: 'headerTable', fillColor: colorsPurple },
            ],
            [
              // Row 4
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
                fillColor: colorsYellow,
              },
              {},
              {},
              { text: 'Peak', style: 'headerTable', fillColor: colorsGreen },
              {
                text: 'Off Peak',
                style: 'headerTable',
                margin: [-3, -2],
                fillColor: colorsGreen,
              },
              { text: 'Holiday', style: 'headerTable', fillColor: colorsGreen },
              { text: 'Peak', style: 'headerTable', fillColor: colorsGreen },
              {
                text: 'Off Peak',
                style: 'headerTable',
                margin: [-3, -2],
                fillColor: colorsGreen,
              },
              { text: 'Holiday', style: 'headerTable', fillColor: colorsGreen },
              { text: 'Kwh', style: 'headerTable', fillColor: colorsGreen },
              { text: 'GE', style: 'headerTable', fillColor: colorsBlue },
              { text: 'Kwh', style: 'headerTable', fillColor: colorsGreen },
              { text: 'Kwh', style: 'headerTable', fillColor: colorsPurple },
              { text: 'Kwh', style: 'headerTable', fillColor: colorsPurple },
              { text: 'Kwh', style: 'headerTable', fillColor: colorsPurple },
              { text: 'Kwh', style: 'headerTable', fillColor: colorsPurple },
            ],
            ...TableBody,
            [
              // Row Toatl
              {
                text: 'Total',
                style: 'headerTable',
                border: [true, false, true, true],
                fillColor: colorsPink,
              },
              {
                text: colNo1Sum || '0',
                style: 'tableContent',
                fillColor: colorsPink,
              },
              {
                text: colNo2Sum || '0',
                style: 'tableContent',
                fillColor: colorsPink,
              },
              {
                text: colNo1Peak || '0',
                style: 'tableContent',
                fillColor: colorsPink,
              },
              {
                text: colNo1OffPeak || '0',
                style: 'tableContent',
                fillColor: colorsPink,
              },
              {
                text: colNo1Holiday || '0',
                style: 'tableContent',
                fillColor: colorsPink,
              },
              {
                text: colNo2Peak || '0',
                style: 'tableContent',
                fillColor: colorsPink,
              },
              {
                text: colNo2OffPeak || '0',
                style: 'tableContent',
                fillColor: colorsPink,
              },
              {
                text: colNo2Holiday || '0',
                style: 'tableContent',
                fillColor: colorsPink,
              },
              {
                text: colTotal(formInput.arr) || '0',
                style: 'tableContent',
                fillColor: colorsPink,
              },
              {
                text: colUseControl || '0',
                style: 'tableContent',
                fillColor: colorsPink,
              },
              {
                text: colTotalManufacture(formInput.arr) || '0',
                style: 'tableContent',
                fillColor: colorsPink,
              },
              {
                text: colPEAPeak || '0',
                style: 'tableContent',
                fillColor: colorsPink,
              },
              {
                text: colPEAOffPeak || '0',
                style: 'tableContent',
                fillColor: colorsPink,
              },
              {
                text: colPEAHoliday || '0',
                style: 'tableContent',
                fillColor: colorsPink,
              },
              {
                text: colTotalPEA(formInput.arr) || '0',
                style: 'tableContent',
                fillColor: colorsPink,
              },
            ],
          ],
        },
      },
      {
        alignment: 'between',
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                margin: [0, 20, 0, 0],
                text: 'ผู้ออกรายงาน..............................................',
                alignment: 'center',
                fontSize: 14,
                border: [false, false, false, false],
              },
              {
                text: '',

                alignment: 'left',
                fontSize: 14,
                border: [false, false, false, false],
              },
            ],
            [
              {
                margin: [0, -10, 0, 0],
                text: '\n(นาย ธีระยุทธ กลิ่นจันทร์)',
                fontSize: 14,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: '',

                alignment: 'center',
                fontSize: 14,

                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                alignment: 'left',

                border: [false, false, false, false],
              },
              {
                margin: [0, -48, 0, 0],
                text: 'ผู้ตรวจสอบรายงาน..............................................',

                alignment: 'center',
                fontSize: 14,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                margin: [0, -40, 0, 0],
                text: '\n(นาย โกเมส บุญรักษ์) ',
                fontSize: 14,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
          ],
        },
        styles: {
          header: {
            fontSize: 8,
            bold: true,
            alignment: 'right',
          },
          header1: {
            fontSize: 12,
            bold: true,
          },
        },
      },
    ],

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: { font: 'FontAwesome' },
      header: {
        fontSize: 14,
        bold: true,
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        fontSize: 12,
        margin: [0, -2],
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 14,
        margin: [0, -2],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_BG_003

import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Switch,
  Input,
  FormControl,
  FormLabel,
  NumberInput,
} from '@chakra-ui/react'

export default function FormofControl({ sensor, form, enableSwitchForm }) {
  const { t } = useTranslation()
  const [time, setTime] = useState(Date.now())
  const checkForm = () => {
    const newSensor = sensor?.controlForm?.value?.[0]?.split('/') || null
    return _.find(form?.arr, (ff) => {
      if (newSensor != null && ff?.name === newSensor[0]) {
        //console.log('FF', ff?.name)
        return ff?._id
      }
    })
  }

  const { register, handleSubmit, setValue } = useForm({})
  // รีเฟรชทุกๆ 10 วินาที
  useEffect(() => {
    // TODO: EEdit it
    const interval = setInterval(() => setTime(Date.now()), 10000)
    return () => {
      clearInterval(interval)
    }
  }, [])
  useEffect(() => {
    const newSensor = checkForm()
    if (newSensor != null) {
      setValue('value', newSensor?._id)
    }

    return () => {}
  }, [time])

  const submitControlForm = (data) => {
    //console.log('Submit Number : ', data)
    enableSwitchForm(sensor.controlRefString, data)
  }

  return (
    <div className="w-full">
      <div className="lg:flex border-2 rounded-md">
        <div className="bg-gray-200 md:w-1/3">
          <div className="items-center text-center px-4 py-6">
            {sensor.name}
          </div>
        </div>
        <div className="grid grid-cols-4">
          <FormControl className="grid col-span-2">
            <label className="text-sm font-sans mx-2">ฟอร์มที่ต้องการ</label>

            <select
              name="value"
              defaultValue={checkForm()?._id}
              {...register('value')}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
            >
              {_.map(form?.arr, (fm, index) => (
                <option value={fm?._id}>{fm?.name}</option>
              ))}
            </select>
          </FormControl>

          <div className=" text-center px-4 m-2">
            <FormControl display="flex">
              <Switch
                size="md"
                colorScheme="blue"
                name="enable"
                {...register('enable')}
                defaultChecked={sensor?.controlForm?.enable}
              />
            </FormControl>
          </div>
          <div className="mb-2">
            <Button
              size="sm"
              colorScheme="green"
              type="submit"
              onClick={handleSubmit(submitControlForm)}
            >
              {t('utility.save')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import dayjs from 'dayjs'
import _ from 'lodash'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderHourMeter = (data) => {
  //const DataSet = renderCheckHourMeter(data)

  if (data?.length === 0) {
    const content = [
      [
        {
          text: 'Hour Meter เริ่มใช้งาน',
          style: 'headerTable',
        },
        { text: '', colSpan: 2, alignment: 'center' },
        {},
        { text: '', colSpan: 3, alignment: 'center' },
        {},
        {},
        { text: '', colSpan: 2, alignment: 'center' },
        {},
        { text: '', colSpan: 3, alignment: 'center' },
        {},
        {},
        { text: '', colSpan: 2, alignment: 'center' },
        {},
        { text: '', colSpan: 3, alignment: 'center' },
        {},
        {},
      ],
      [
        {
          text: 'Hour Meter หลังใช้งาน',
          style: 'headerTable',
        },
        { text: '', colSpan: 2, alignment: 'center' },
        {},
        { text: '', colSpan: 3, alignment: 'center' },
        {},
        {},
        { text: '', colSpan: 2, alignment: 'center' },
        {},
        { text: '', colSpan: 3, alignment: 'center' },
        {},
        {},
        { text: '', colSpan: 2, alignment: 'center' },
        {},
        { text: '', colSpan: 3, alignment: 'center' },
        {},
        {},
      ],
    ]

    return content
  } else if (data?.length === 2) {
    const content = _.map(data, (row) => [
      {
        text:
          row?.HourMeter === 'เริ่มใช้งาน'
            ? 'Hour Meter เริ่มใช้งาน'
            : 'Hour Meter หลังใช้งาน',
        style: 'headerTable',
        alignment: 'center',
      },
      {
        text:
          row?.subForm_name === 'Hour Meter 1-3'
            ? row?.DigesterNo1 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.DigesterNo1)
            : row?.DigesterNo4 === ''
            ? ''
            : Intl.NumberFormat('en-US').format(row?.DigesterNo4),
        style: 'headerTable',
        colSpan: 2,
        alignment: 'center',
      },
      {},
      {
        text:
          row?.subForm_name === 'Hour Meter 1-3'
            ? row?.ScrewPressNo1 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.ScrewPressNo1)
            : row?.ScrewPressNo4 === ''
            ? ''
            : Intl.NumberFormat('en-US').format(row?.ScrewPressNo4),
        style: 'headerTable',
        colSpan: 3,
        alignment: 'center',
      },
      {},
      {},
      {
        text:
          row?.subForm_name === 'Hour Meter 1-3'
            ? row?.DigesterNo2 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.DigesterNo2)
            : row?.DigesterNo5 === ''
            ? ''
            : Intl.NumberFormat('en-US').format(row?.DigesterNo5),
        style: 'headerTable',
        colSpan: 2,
        alignment: 'center',
      },
      {},
      {
        text:
          row?.subForm_name === 'Hour Meter 1-3'
            ? row?.ScrewPressNo2 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.ScrewPressNo2)
            : row?.ScrewPressNo5 === ''
            ? ''
            : Intl.NumberFormat('en-US').format(row?.ScrewPressNo5),
        style: 'headerTable',
        colSpan: 3,
        alignment: 'center',
      },
      {},
      {},
      {
        text:
          row?.subForm_name === 'Hour Meter 1-3'
            ? row?.DigesterNo3 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.DigesterNo3)
            : row?.DigesterNo6 === ''
            ? ''
            : Intl.NumberFormat('en-US').format(row?.DigesterNo6),
        style: 'headerTable',
        colSpan: 2,
        alignment: 'center',
      },
      {},
      {
        text:
          row?.subForm_name === 'Hour Meter 1-3'
            ? row?.ScrewPressNo3 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.ScrewPressNo3)
            : row?.ScrewPressNo6 === ''
            ? ''
            : Intl.NumberFormat('en-US').format(row?.ScrewPressNo6),
        style: 'headerTable',
        colSpan: 3,
        alignment: 'center',
      },
      {},
      {},
    ])
    return content
  } else if (data?.[0]?.HourMeter === 'เริ่มใช้งาน') {
    const content = _.map(data, (row) => [
      {
        text: 'Hour Meter เริ่มใช้งาน',
        style: 'headerTable',
        alignment: 'center',
      },
      {
        text:
          row?.subForm_name === 'Hour Meter 1-3'
            ? row?.DigesterNo1 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.DigesterNo1)
            : row?.DigesterNo4 === ''
            ? ''
            : Intl.NumberFormat('en-US').format(row?.DigesterNo4),
        style: 'headerTable',
        colSpan: 2,
        alignment: 'center',
      },
      {},
      {
        text:
          row?.subForm_name === 'Hour Meter 1-3'
            ? row?.ScrewPressNo1 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.ScrewPressNo1)
            : row?.ScrewPressNo4 === ''
            ? ''
            : Intl.NumberFormat('en-US').format(row?.ScrewPressNo4),
        style: 'headerTable',
        colSpan: 3,
        alignment: 'center',
      },
      {},
      {},
      {
        text:
          row?.subForm_name === 'Hour Meter 1-3'
            ? row?.DigesterNo2 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.DigesterNo2)
            : row?.DigesterNo5 === ''
            ? ''
            : Intl.NumberFormat('en-US').format(row?.DigesterNo5),
        style: 'headerTable',
        colSpan: 2,
        alignment: 'center',
      },
      {},
      {
        text:
          row?.subForm_name === 'Hour Meter 1-3'
            ? row?.ScrewPressNo2 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.ScrewPressNo2)
            : row?.ScrewPressNo5 === ''
            ? ''
            : Intl.NumberFormat('en-US').format(row?.ScrewPressNo5),
        style: 'headerTable',
        colSpan: 3,
        alignment: 'center',
      },
      {},
      {},
      {
        text:
          row?.subForm_name === 'Hour Meter 1-3'
            ? row?.DigesterNo3 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.DigesterNo3)
            : row?.DigesterNo6 === ''
            ? ''
            : Intl.NumberFormat('en-US').format(row?.DigesterNo6),
        style: 'headerTable',
        colSpan: 2,
        alignment: 'center',
      },
      {},
      {
        text:
          row?.subForm_name === 'Hour Meter 1-3'
            ? row?.ScrewPressNo3 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.ScrewPressNo3)
            : row?.ScrewPressNo6 === ''
            ? ''
            : Intl.NumberFormat('en-US').format(row?.ScrewPressNo6),
        style: 'headerTable',
        colSpan: 3,
        alignment: 'center',
      },
      {},
      {},
    ])
    content.push([
      {
        text: 'Hour Meter หลังใช้งาน',
        style: 'headerTable',
      },
      { text: '', colSpan: 2, alignment: 'center' },
      {},
      { text: '', colSpan: 3, alignment: 'center' },
      {},
      {},
      { text: '', colSpan: 2, alignment: 'center' },
      {},
      { text: '', colSpan: 3, alignment: 'center' },
      {},
      {},
      { text: '', colSpan: 2, alignment: 'center' },
      {},
      { text: '', colSpan: 3, alignment: 'center' },
      {},
      {},
    ])
    return content
  } else if (data?.[0]?.HourMeter === 'หลังใช้งาน') {
    const content = [
      [
        {
          text: 'Hour Meter เริ่มใช้งาน',
          style: 'headerTable',
        },
        { text: '', colSpan: 2, alignment: 'center' },
        {},
        { text: '', colSpan: 3, alignment: 'center' },
        {},
        {},
        { text: '', colSpan: 2, alignment: 'center' },
        {},
        { text: '', colSpan: 3, alignment: 'center' },
        {},
        {},
        { text: '', colSpan: 2, alignment: 'center' },
        {},
        { text: '', colSpan: 3, alignment: 'center' },
        {},
        {},
      ],
    ]

    _.map(data, (row) =>
      content.push([
        {
          text: 'Hour Meter หลังใช้งาน',
          style: 'headerTable',
          alignment: 'center',
        },
        {
          text:
            row?.subForm_name === 'Hour Meter 1-3'
              ? row?.DigesterNo1 === ''
                ? ''
                : Intl.NumberFormat('en-US').format(row?.DigesterNo1)
              : row?.DigesterNo4 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.DigesterNo4),
          style: 'headerTable',
          colSpan: 2,
          alignment: 'center',
        },
        {},
        {
          text:
            row?.subForm_name === 'Hour Meter 1-3'
              ? row?.ScrewPressNo1 === ''
                ? ''
                : Intl.NumberFormat('en-US').format(row?.ScrewPressNo1)
              : row?.ScrewPressNo4 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.ScrewPressNo4),
          style: 'headerTable',
          colSpan: 3,
          alignment: 'center',
        },
        {},
        {},
        {
          text:
            row?.subForm_name === 'Hour Meter 1-3'
              ? row?.DigesterNo2 === ''
                ? ''
                : Intl.NumberFormat('en-US').format(row?.DigesterNo2)
              : row?.DigesterNo5 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.DigesterNo5),
          style: 'headerTable',
          colSpan: 2,
          alignment: 'center',
        },
        {},
        {
          text:
            row?.subForm_name === 'Hour Meter 1-3'
              ? row?.ScrewPressNo2 === ''
                ? ''
                : Intl.NumberFormat('en-US').format(row?.ScrewPressNo2)
              : row?.ScrewPressNo5 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.ScrewPressNo5),
          style: 'headerTable',
          colSpan: 3,
          alignment: 'center',
        },
        {},
        {},
        {
          text:
            row?.subForm_name === 'Hour Meter 1-3'
              ? row?.DigesterNo3 === ''
                ? ''
                : Intl.NumberFormat('en-US').format(row?.DigesterNo3)
              : row?.DigesterNo6 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.DigesterNo6),
          style: 'headerTable',
          colSpan: 2,
          alignment: 'center',
        },
        {},
        {
          text:
            row?.subForm_name === 'Hour Meter 1-3'
              ? row?.ScrewPressNo3 === ''
                ? ''
                : Intl.NumberFormat('en-US').format(row?.ScrewPressNo3)
              : row?.ScrewPressNo6 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(row?.ScrewPressNo6),
          style: 'headerTable',
          colSpan: 3,
          alignment: 'center',
        },
        {},
        {},
      ])
    )

    return content
  }
  return null
}

const renderMapTime = (data, timeMap) => {
  const content = []
  _.map(timeMap, (time, index) => {
    let indexTime = false
    if (data?.length === 0) {
      return content.push([
        { text: time, style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    } else {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (time === eachData?.TimeStart) {
          indexTime = true
          content.push(
            eachData?.subForm_name === 'Digester & Screw Press 1-3'
              ? [
                  // Digester & Screw Press 1-3
                  { text: eachData?.TimeStart || '', style: 'tableContent' },
                  {
                    text: eachData?.DigesterNo1.กระแส || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.DigesterNo1.อุณหภูมิ || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo1.เวลาเดินเครื่องเปิด || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo1.เวลาเดินเครื่องปิด || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo1.กระแส || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.DigesterNo2.กระแส || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.DigesterNo2.อุณหภูมิ || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo2.เวลาเดินเครื่องเปิด || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo2.เวลาเดินเครื่องปิด || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo2.กระแส || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.DigesterNo3.กระแส || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.DigesterNo3.อุณหภูมิ || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo3.เวลาเดินเครื่องเปิด || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo3.เวลาเดินเครื่องปิด || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo3.กระแส || '-',
                    style: 'tableContent',
                  },
                ]
              : [
                  //Digester & Screw Press 4-6
                  { text: eachData?.TimeStart || '', style: 'tableContent' },
                  {
                    text: eachData?.DigesterNo4.กระแส || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.DigesterNo4.อุณหภูมิ || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo4.เวลาเดินเครื่องเปิด || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo4.เวลาเดินเครื่องปิด || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo4.กระแส || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.DigesterNo5.กระแส || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.DigesterNo5.อุณหภูมิ || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo5.เวลาเดินเครื่องเปิด || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo5.เวลาเดินเครื่องปิด || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo5.กระแส || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.DigesterNo6.กระแส || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.DigesterNo6.อุณหภูมิ || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo6.เวลาเดินเครื่องเปิด || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo6.เวลาเดินเครื่องปิด || '-',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.ScrewPressNo6.กระแส || '-',
                    style: 'tableContent',
                  },
                ]
          )
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: time, style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    }
  })
  return content
}

const FM_PD_002 = async (form, formInput, subForm, system, date) => {
  const colors = '#dddddd'
  const timeMap = [
    '08:00',
    '10:00',
    '12:00',
    '14:00',
    '16:00',
    '18:00',
    '20:00',
    '22:00',
    '00:00',
    '02:00',
    '04:00',
    '06:00',
    '07:00',
  ]
  //find data for map in table

  const dataHourMeter1 = _.filter(formInput?.arr, {
    subForm_name: 'Hour Meter 1-3',
  })
  const dataHourMeter4 = _.filter(formInput?.arr, {
    subForm_name: 'Hour Meter 4-6',
  })
  const data1_3 = _.filter(formInput?.arr, {
    subForm_name: 'Digester & Screw Press 1-3',
  })
  const data4_6 = _.filter(formInput?.arr, {
    subForm_name: 'Digester & Screw Press 4-6',
  })
  const FooterData = _.filter(formInput?.arr, {
    subForm_name: 'ชั่วโมงการทำงาน',
  })
  const contentHourMeter1 = renderHourMeter(dataHourMeter1)
  const contentHourMeter4 = renderHourMeter(dataHourMeter4)
  const TableBody1_3 = renderMapTime(data1_3, timeMap)
  const TableBody4_6 = renderMapTime(data4_6, timeMap)
  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [10, 60, 10, 0],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      {
        margin: [10, 10, 10, 0],
        table: {
          widths: ['22%', '56%', '13%', '9%'],
          body: [
            [
              {
                image: 'logo_imge',
                fit: [20, 20],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: `${form?.name || ''} - (FM-PD-002)`,
                alignment: 'center',
                bold: true,
                fontSize: 14,
                margin: [0, 0, 0, -3],
                border: [true, true, true, false],
              },
              {
                text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
                style: 'header',
                alignment: 'left',
                colSpan: 2,
                border: [true, true, true, true],
              },
              { text: '', border: [false, true, true, true] },
            ],
            [
              {
                text: 'THAI TALLOW AND OIL CO.,LTD.',
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: 12,
                border: [true, false, true, true],
              },
              {
                text: `วันที่ ${dayjs(date).format(
                  'DD เดือน MMMM พ.ศ. BBBB '
                )}`,
                alignment: 'center',
                style: 'header',
                border: [true, false, true, true],
              },
              {
                text: `วันที่บังคับใช้ : ${
                  dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                }`,
                style: 'header',
                alignment: 'left',
                border: [true, true, true, true],
              },
              {
                text: `แก้ไขครั้งที่: 0${form?.editVersion || '-'}`,
                style: 'header',
                alignment: 'left',
                border: [true, true, true, true],
              },
            ],
          ],
        },
      },
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        margin: [0, 0, 0, 0],
        table: {
          widths: [
            '11%',
            '6%',
            '6%',
            '6%',
            '6%',
            '6%',
            '6%',
            '6%',
            '6%',
            '6%',
            '6%',
            '6%',
            '5.75%',
            '5.75%',
            '5.75%',
            '5.75%',
          ],
          body: [
            [
              //Row 1
              {
                stack: [
                  {
                    text: 'เวลาทำงาน',
                    bold: true,
                    style: 'headerTable',
                    alignment: 'left',
                  },
                  {
                    canvas: [
                      {
                        type: 'line',
                        x1: 79, //เพิ่มเพื่อให้ด้านขวาออกไป
                        y1: -11, //เพิ่มเพื่อให้ด้านขวาลง
                        x2: -4,
                        y2: 32, //เพิ่มเพื่อให้ด้านซ้ายลง
                        lineWidth: 1,
                        lineColor: '#000000',
                      },
                    ],
                  },
                  {
                    text: 'ค่าควบคุม',
                    alignment: 'right',
                    margin: [0, -16, 0, 0],
                    bold: true,
                  },
                ],
                rowSpan: 3,
              },
              {
                text: 'Digester No 1',
                colSpan: 2,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {
                text: 'SCREW PRESS NO.1',
                colSpan: 3,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
              {
                text: 'Digester No 2',
                colSpan: 2,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {
                text: 'SCREW PRESS NO.2',
                colSpan: 3,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
              {
                text: 'Digester No 3',
                colSpan: 2,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {
                text: 'SCREW PRESS NO.3',
                colSpan: 3,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
            ],
            [
              //Row 2
              {
                text: '',
                style: 'headerTable',
                border: [true, false, false, false],
              },
              {
                text: 'กระแส',
                style: 'headerTable',
              },
              {
                text: 'อุณหภูมิ',
                style: 'headerTable',
              },
              {
                text: 'เวลาเดินเครื่อง',
                colSpan: 2,
                style: 'headerTable',
              },
              {},
              {
                text: 'กระแส',
                style: 'headerTable',
              },
              {
                text: 'กระแส',
                style: 'headerTable',
              },
              {
                text: 'อุณหภูมิ',
                style: 'headerTable',
              },
              {
                text: 'เวลาเดินเครื่อง',
                colSpan: 2,
                style: 'headerTable',
              },
              {},
              {
                text: 'กระแส',
                style: 'headerTable',
              },
              {
                text: 'กระแส',
                style: 'headerTable',
              },
              {
                text: 'อุณหภูมิ',
                style: 'headerTable',
              },
              {
                text: 'เวลาเดินเครื่อง',
                colSpan: 2,
                style: 'headerTable',
              },
              {},
              {
                text: 'กระแส',
                style: 'headerTable',
              },
            ],
            [
              //Row 3
              {
                text: 'ค่าควบคุม',
                style: 'headerTable',
                alignment: 'right',
                border: [true, false, true, true],
              },
              { text: '40-50 A', style: 'headerTable' },
              { text: '70-90C', style: 'headerTable' },
              {
                text: 'เวลาเปิด',
                style: 'headerTable',
              },
              {
                text: 'เวลาปิด',
                style: 'headerTable',
              },
              {
                text: '34-47 A',
                style: 'headerTable',
              },
              { text: '40-50 A', style: 'headerTable' },
              { text: '70-90C', style: 'headerTable' },
              {
                text: 'เวลาเปิด',
                style: 'headerTable',
              },
              {
                text: 'เวลาปิด',
                style: 'headerTable',
              },
              {
                text: '34-47 A',
                style: 'headerTable',
              },
              { text: '40-50 A', style: 'headerTable' },
              { text: '70-90C', style: 'headerTable' },
              {
                text: 'เวลาเปิด',
                style: 'headerTable',
              },
              {
                text: 'เวลาปิด',
                style: 'headerTable',
              },
              {
                text: '34-47 A',
                style: 'headerTable',
              },
            ],
            ...TableBody1_3,
            ...contentHourMeter1,
            [
              //Row 1
              {
                text: 'เวลาทำงาน',
                style: 'headerTable',
                alignment: 'center',
                border: [true, true, true, false],
              },
              {
                text: 'Digester No 4',
                colSpan: 2,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {
                text: 'SCREW PRESS NO.4',
                colSpan: 3,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
              {
                text: 'Digester No 5',
                colSpan: 2,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {
                text: 'SCREW PRESS NO.5',
                colSpan: 3,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
              {
                text: 'Digester No 6',
                colSpan: 2,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {
                text: 'SCREW PRESS NO.6',
                colSpan: 3,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
            ],
            ...TableBody4_6,
            ...contentHourMeter4,
          ],
        },
      },
      console.log('foot', FooterData),
      {
        alignment: 'between',
        table: {
          widths: ['17%', '38%', '45%'],
          body: [
            [
              {
                text:
                  FooterData?.length === 0
                    ? 'ชั่วโมงการทำงาน....................ชั่วโมง'
                    : 'ชั่วโมงการทำงาน  ' +
                      FooterData[0].TimerStart +
                      '  ชั่วโมง',
                alignment: 'left',
                style: 'tableContent',
                rowSpan: 4,
                margin: [0, 2, 0, -2],
                border: [true, true, false, true],
              },
              {
                text: 'ผู้รายงานกะ A 1.ลงชื่อ................................................. 2.ลงชื่อ................................................',
                alignment: 'left',
                style: 'tableContent',
                margin: [0, 2, 0, -2],
                border: [false, true, false, false],
              },
              {
                text: 'ผู้รายงานกะ B 1.ลงชื่อ...................................................... 2.ลงชื่อ......................................................',
                style: 'tableContent',
                margin: [0, 2, 0, -2],
                alignment: 'left',
                border: [false, true, true, false],
              },
            ],
            [
              {},
              {
                text: '    (.......................................)     (.......................................)  ',
                alignment: 'center',
                style: 'tableContent',
                border: [false, false, false, false],
              },
              {
                text: '    (.......................................)    (.......................................)  ',
                alignment: 'center',
                style: 'tableContent',
                border: [false, false, true, false],
              },
            ],
            [
              {},
              {
                text: 'หัวหน้างานกะ A ลงชื่อ................................................................',
                alignment: 'left',
                style: 'tableContent',
                border: [false, false, false, false],
              },
              {
                text: 'หัวหน้างานกะ B ลงชื่อ................................................................',
                style: 'tableContent',
                alignment: 'left',
                border: [false, false, true, false],
              },
            ],
            [
              {},
              {
                text: '(.........................................................)   ',
                alignment: 'center',
                style: 'tableContent',
                margin: [-20, -4, 0, 0],
                border: [false, false, false, true],
              },
              {
                text: '(.........................................................)    ',
                alignment: 'center',
                style: 'tableContent',
                margin: [-100, -4, 0, 0],
                border: [false, false, true, true],
              },
            ],
          ],
        },
        styles: {
          header: {
            fontSize: 8,
            bold: true,
            alignment: 'right',
          },
          header1: {
            fontSize: 12,
            bold: true,
          },
        },
      },
    ],

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: { font: 'FontAwesome' },
      header: {
        bold: true,
        fontSize: 14,
        margin: [0, 0, 0, -3],
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        margin: [-3, -2, -3, -2],
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 12,
        margin: [0, -4, 0, -2],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_PD_002

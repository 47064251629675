import React from 'react'
import { FormControl, Input, Select, Badge } from '@chakra-ui/react'
import _ from 'lodash'
/**
 * @deprecated since 2021-04-14
 */
export default function EditingBooleanDisplay({
    gaugeData,
    register,
    stationData,
}) {
    const genRefStringList = () => {
        return _.map(stationData.sensors, (sensor) => (
            <option value={sensor.refString}>{sensor.refString}</option>
        ))
    }
    const colorList = () => (
        <>
            <option value="red">แดง</option>
            <option value="yellow">เหลือง</option>
            <option value="green">เขียว</option>
            <option value="blue">น้ำเงิน</option>
            <option value="purple">ม่วง</option>
            <option value="pink">ชมพู</option>
            <option value="gray">เทา</option>
        </>
    )
    return (
        <>
            <label>
                <Badge colorScheme="yellow" size="sm">
                    ระบบเก่า
                </Badge>
                ลองใช้เกจ Boolean ตัวใหม่ที่รองรับทั้งค่าจากเซ็นเซอร์และตัวแปร
                กดเลือกเกจเป็น แสดงค่าบูลีนอีกครั้ง เพื่อเริ่มใช้ฟีเจอร์นี้
            </label>
            <FormControl>
                <label className="text-sm font-bold">
                    {' '}
                    เซ็นเซอร์ที่ต้องการเชื่อมต่อ{' '}
                </label>
                <Select
                    defaultValue={gaugeData.sensorRefString}
                    ref={register}
                    size="sm"
                    name="sensorRefString"
                >
                    {genRefStringList()}
                </Select>
            </FormControl>
            <FormControl>
                <label className="text-sm font-bold">
                    {' '}
                    คำที่จะแสดงเมื่อเป็นค่า High{' '}
                </label>
                <Input
                    type="text"
                    name="valueLabel.high"
                    size="sm"
                    defaultValue={gaugeData.valueLabel?.high}
                    ref={register}
                />
            </FormControl>
            <FormControl>
                <label className="text-sm font-bold">
                    {' '}
                    ค่าที่จะแสดงเมื่อเป็นค่า Low{' '}
                </label>
                <Input
                    type="text"
                    name="valueLabel.low"
                    size="sm"
                    defaultValue={gaugeData.valueLabel?.low}
                    ref={register}
                />
            </FormControl>
            <FormControl>
                <label className="text-sm font-bold">
                    {' '}
                    สีเมื่อแสดงค่าเป็น High{' '}
                </label>
                <Select
                    type="text"
                    name="valueColor.high"
                    size="sm"
                    defaultValue={gaugeData.valueColor?.high}
                    ref={register}
                >
                    {colorList()}
                </Select>
            </FormControl>
            <FormControl>
                <label className="text-sm font-bold">
                    {' '}
                    สีเมื่อแสดงค่าเป็น Low{' '}
                </label>
                <Select
                    type="text"
                    name="lowLevelColor"
                    size="sm"
                    defaultValue={gaugeData.valueColor?.low}
                    ref={register}
                >
                    {colorList()}
                </Select>
            </FormControl>
        </>
    )
}

import React from 'react'
import { Checkbox, FormControl, Input, Select, Badge } from '@chakra-ui/react'
import _ from 'lodash'

/**
 * @deprecated since 2021-04-14
 */
export default function EditMeterDataDisplay({
    gaugeData,
    register,
    stationData,
}) {
    const genRefStringList = () => {
        return _.map(stationData.sensors, (sensor) => (
            <option value={sensor.refString}>{sensor.refString}</option>
        ))
    }
    return (
        <>
            <label>
                <Badge colorScheme="yellow" size="sm">
                    ระบบเก่า
                </Badge>
                ลองใช้เกจ มิเตอร์ ตัวใหม่ที่รองรับทั้งค่าจากเซ็นเซอร์และตัวแปร
                กดเลือกเกจเป็น แสดงค่ามิเตอร์อีกครั้ง เพื่อเริ่มใช้ฟีเจอร์นี้
            </label>{' '}
            <FormControl>
                <label className="text-sm font-bold">
                    {' '}
                    เซ็นเซอร์ที่ต้องการเชื่อมต่อ{' '}
                </label>
                <Select
                    defaultValue={gaugeData.sensorRefString}
                    ref={register}
                    size="sm"
                    name="sensorRefString"
                >
                    {genRefStringList()}
                </Select>
            </FormControl>
            <FormControl>
                <label className="text-sm font-bold">หน่วย</label>
                <Input
                    type="text"
                    name="valueUnit"
                    size="sm"
                    defaultValue={gaugeData.valueUnit}
                    ref={register}
                />
            </FormControl>
            <FormControl>
                <label className="text-sm font-bold">
                    ค่าสูงที่สุดที่ในเกจ
                </label>
                <Input
                    type="number"
                    name="valueResolution.high"
                    size="sm"
                    defaultValue={gaugeData.valueResolution?.high}
                    ref={register}
                />
            </FormControl>
            <FormControl>
                <label className="text-sm font-bold"> ค่าต่ำที่สุดในเกจ</label>
                <Input
                    type="number"
                    name="valueResolution.low"
                    size="sm"
                    defaultValue={gaugeData.valueResolution?.low}
                    ref={register}
                />
            </FormControl>
            <FormControl>
                <label className="text-sm font-bold">
                    ชื่อของค่าที่แสดงในกราฟ เช่น ความดัน
                </label>
                <Input
                    type="text"
                    name="valueNote"
                    size="sm"
                    defaultValue={gaugeData.valueNote}
                    ref={register}
                />
            </FormControl>
        </>
    )
}

/* eslint-disable quotes */
import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { formInputPost } from 'redux/actions'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderMapTime = (data, timeMap) => {
  const content = []
  console.log(data.length)
  _.map(timeMap, (time, index) => {
    let indexTime = false
    if (data?.length === 0) {
      return content.push([
        { text: time, style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    } else {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (time === eachData?.TimeSave) {
          indexTime = true
          content.push([
            { text: eachData?.TimeSave, style: 'tableContent' },
            {
              text: eachData?.SILO1.อุณหภูมิ,
              style: 'tableContent',
            },
            {
              text: eachData?.SILO1.กระแส,
              style: 'tableContent',
            },
            {
              text: eachData?.SILO1.หมายเหตุ,
              style: 'tableContent',
            },
            {
              text: eachData?.SILO2.อุณหภูมิ,
              style: 'tableContent',
            },
            {
              text: eachData?.SILO2.กระแส,
              style: 'tableContent',
            },
            {
              text: eachData?.SILO2.หมายเหตุ,
              style: 'tableContent',
            },
            {
              text: eachData?.SILO3.อุณหภูมิ,
              style: 'tableContent',
            },
            {
              text: eachData?.SILO3.กระแส,
              style: 'tableContent',
            },
            {
              text: eachData?.SILO3.หมายเหตุ,
              style: 'tableContent',
            },
            {
              text: eachData?.SILO4.อุณหภูมิ,
              style: 'tableContent',
            },
            {
              text: eachData?.SILO4.กระแส,
              style: 'tableContent',
            },
            {
              text: eachData?.SILO4.หมายเหตุ,
              style: 'tableContent',
            },
            {
              text: eachData?.SILO5.อุณหภูมิ,
              style: 'tableContent',
            },
            {
              text: eachData?.SILO5.กระแส,
              style: 'tableContent',
            },
            {
              text: eachData?.SILO5.หมายเหตุ,
              style: 'tableContent',
            },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: time, style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    }
  })
  return content
}

const FM_PD_007 = async (form, formInput, subForm, system, date) => {
  //formInputไม่หมือนกับ03
  const colors = '#dddddd'
  console.log(formInput)
  const timeMap = [
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
  ]
  //find data for map in table

  const TableBody = renderMapTime(formInput.arr, timeMap) //timeMapประกาศเพิ่มมา

  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [30, 79, 30, 0],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      {
        margin: [30, 30, 30, 0],
        table: {
          widths: ['20.25%', '43.25%', '18.75%', '17.75%'],
          body: [
            [
              {
                image: 'logo_imge',
                fit: [20, 20],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: `${form?.name || ''} - (${form?.docNo || ''})`,
                alignment: 'center',
                bold: true,
                fontSize: 18,
                margin: [0, 0, 0, -3],
                border: [true, true, true, false],
              },
              {
                text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
                style: 'header',
                alignment: 'center',
                colSpan: 2,
                margin: [0, 2, 0, -3],
                border: [true, true, true, true],
              },
              { text: '', border: [false, true, true, true] },
            ],
            [
              {
                text: `THAI TALLOW AND OIL CO.,LTD.`,
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: 12,
                border: [true, false, true, true],
              },
              {
                text: `วันที่ ${dayjs(date).format(
                  'DD เดือน MMMM พ.ศ. BBBB '
                )}`,
                alignment: 'center',
                style: 'header',
                border: [true, false, true, true],
              },
              {
                text: `บังคับใช้ : 8 ธันวาคม 2565`,
                style: 'header',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: `แก้ไขครั้งที่: 02`,
                style: 'header',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
          ],
        },
      },
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        margin: [0, 0, 0, 0],
        table: {
          widths: [
            '9%',
            '6%',
            '6%',
            '6%',
            '6%',
            '6%',
            '6%',
            '6%',
            '6%',
            '6%',
            '6%',
            '6%',
            '7%',
            '6%',
            '6%',
            '6%',
          ],
          body: [
            [
              //Row 1 ไม่แก้
              {
                stack: [
                  {
                    text: 'ค่าควบคุม',
                    bold: true,
                    style: 'headerTable',
                    alignment: 'left',
                  },
                  {
                    canvas: [
                      {
                        type: 'line',
                        x1: 62,
                        y1: -12,
                        x2: -4,
                        y2: 33,
                        lineWidth: 1,
                        lineColor: '#000000',
                      },
                    ],
                  },
                  {
                    text: 'เวลาที่บันทึก',
                    alignment: 'right',
                    style: 'headerTable',
                    margin: [0, -16, 0, 0],
                    bold: true,
                  },
                ],
                rowSpan: 3,
              },
              {
                text: 'SILO 1',
                colSpan: 3,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
              {
                text: 'SILO 2',
                colSpan: 3,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
              {
                text: 'SILO 3',
                colSpan: 3,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
              {
                text: 'SILO 4',
                colSpan: 3,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
              {
                text: 'SILO 5',
                colSpan: 3,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
            ],
            [
              //Row 2 ไม่แก้
              {
                text: '',
                style: 'headerTable',
                border: [true, false, false, false],
              },
              {
                text: 'อุณหภูมิ',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'หมายเหตุ',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 4],
                border: [true, true, true, false],
              },
              {
                text: 'อุณหภูมิ',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'หมายเหตุ',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 4],
                border: [true, true, true, false],
              },
              {
                text: 'อุณหภูมิ',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'หมายเหตุ',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 4],
                border: [true, true, true, false],
              },
              {
                text: 'อุณหภูมิ',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'หมายเหตุ',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 4],
                border: [true, true, true, false],
              },
              {
                text: 'อุณหภูมิ',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'หมายเหตุ',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 4],
                border: [true, true, true, false],
              },
            ],
            [
              //Row 3
              {
                text: 'เวลาที่บันทึก',
                style: 'headerTable',
                alignment: 'right',
                border: [true, false, true, true],
              },
              {
                text: '70-90 C',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '20-30 A',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              { text: '', border: [true, false, true, true] },
              {
                text: '70-90 C',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '20-30 A',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              { text: '', border: [true, false, true, true] },
              {
                text: '70-90 C',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '20-30 A',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              { text: '', border: [true, false, true, true] },
              {
                text: '70-90 C',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '20-30 A',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              { text: '', border: [true, false, true, true] },
              {
                text: '70-90 C',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '20-30 A',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              { text: '', border: [true, false, true, true] },
            ],
            ...TableBody,
          ],
        },
      },
      {
        alignment: 'between',
        table: {
          widths: [`50.8%`, `49.2%`],
          body: [
            [
              {
                text: 'ผู้รายงานกะ A 1.ลงชื่อ......................................................      2.ลงชื่อ...............................................................',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: `ผู้รายงานกะ B 1.ลงชื่อ..........................................................      2.ลงชื่อ................................................................`,
                bold: true,
                alignment: 'center',
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: '    (.................................................)                    (.................................................)  ',
                alignment: 'center',
                bold: true,
                border: [true, false, true, false],
              },
              {
                text: '    (.................................................)                    (.................................................)  ',
                alignment: 'center',
                bold: true,
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: 'หัวหน้างานกะ A ลงชื่อ................................................................',
                alignment: 'center',
                bold: true,
                border: [true, false, true, false],
              },
              {
                text: 'หัวหน้างานกะ B ลงชื่อ................................................................',
                bold: true,
                alignment: 'center',
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: '(.........................................................)   ',
                alignment: 'center',
                bold: true,
                border: [true, false, true, true],
              },
              {
                text: '(.........................................................)    ',
                alignment: 'center',
                bold: true,
                border: [true, false, true, true],
              },
            ],
          ],
        },
        styles: {
          header: {
            fontSize: 8,
            bold: true,
            alignment: 'right',
          },
          header1: {
            fontSize: 12,
            bold: true,
          },
        },
      },
    ],

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: { font: 'FontAwesome' },
      header: {
        bold: true,
        fontSize: 14,
        margin: [0, 0, 0, -3],
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        margin: [-3, -3, -3, -2],
        fontSize: 13,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 13,
        margin: [0, -3, 0, -2],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_PD_007

export const PumpType = {
  Pump: {
    value: 'PumpType',
    describe: 'Pump',
    image: 'Coolpump.svg',
  },

  PumpSrc: [
    {
      name: 'Metering Pump',
      value: 'Meteringpump.svg',
    },
    {
      name: 'Cool Pump',
      value: 'Coolpump.svg',
    },
    {
      name: 'Chemical Pump',
      value: 'Chemicalpump.svg',
    },
    {
      name: 'Horizontal Pump',
      value: 'Horizontalpump.svg',
    },
  ],
}

import { GaugeType } from './GaugeType'
import { ChartType } from './ChartType'
import { ChartCurve } from './ChartCurve'
import { SensorGaugeType } from './SensorType' // aka SensorSubType
import { NumberSensorType } from './NumberSensorType'
import { DiagramType } from './DiagramType'
import { WarningStatus, WarningColorClass } from './WarningStatus'

export {
  GaugeType,
  ChartType,
  ChartCurve,
  SensorGaugeType,
  NumberSensorType,
  DiagramType,
  WarningStatus,
  WarningColorClass,
}
export default {
  GaugeType,
  ChartType,
  ChartCurve,
  SensorGaugeType,
  NumberSensorType,
  DiagramType,
  WarningStatus,
  WarningColorClass,
}

import {
  SUB_FORM_ALL,
  SUB_FORM_GET,
  SUB_FORM_PUT,
  SUB_FORM_DEL,
  SUB_FORM_POST,
  SUB_FORM_LOADING,
  SUB_FORM_ERROR,
} from '../../actions/types'

const initialState = {
  subForm: null,
  isLoading: true,
  isCompleted: true,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case SUB_FORM_ALL:
      return {
        arr: [...action.payload],
        isLoading: false,
        isCompleted: true,
      }
    case SUB_FORM_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      }
    case SUB_FORM_POST:
      return { isLoading: false, isCompleted: true }
    case SUB_FORM_PUT:
      return { isLoading: false, isCompleted: true }
    case SUB_FORM_DEL:
      return { isLoading: false, isCompleted: true }
    case SUB_FORM_LOADING:
      return { isLoading: true, isCompleted: false }
    case SUB_FORM_ERROR:
      return { isLoading: false, isCompleted: false }
    default:
      return state
  }
}

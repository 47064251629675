import React from 'react'
import { Table, Thead, Tr, Th, Td, Tbody } from '@chakra-ui/react'
import _ from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export default function CountingSummaryDetailLists({ history, worktime }) {
    const { t } = useTranslation()
    const genEstimateBillLists = () =>
        _.map(history, (_history, index) => {
            const currentDate = moment(_history.date).startOf('days') // ข้อมูลของวันที่ทำการเก็บค่าสะสมเพราะถ้าไม่ทำแบบนี้จะได้เป็นของวันนี้เท่านั้น
            const startWorkTimeHours = moment(worktime.start, 'HH:mm').hour() // สำหรับดึงค่าชั่วโมง
            const startWorkTimeMinutes = moment(
                worktime.start,
                'HH:mm'
            ).minutes() //สำหรับดึงค่านาที
            const startWorkTime = moment(currentDate)
                .add(startWorkTimeHours, 'hours')
                .add(startWorkTimeMinutes, 'minutes') // ค่าสำหรับนำไปใช้เปรียบเทียบ

            const endWorkTimeHours = moment(worktime.end, 'HH:mm').hour() // สำหรับดึงค่าชั่วโมง
            const endWorkTimeMinutes = moment(worktime.start, 'HH:mm').minutes() //สำหรับดึงค่านาที
            const endWorkTime = moment(currentDate)
                .add(endWorkTimeHours, 'hours')
                .add(endWorkTimeHours, 'minutes') // ค่าสำหรับนำไปใช้เปรียบเทียบ
            const currentTime = moment(_history.date)

            // console.log('currentDate', currentDate)
            // console.log('startWorkTime', startWorkTime)
            // console.log('endWorkTime', endWorkTime)
            // console.log('currentTime', currentTime)
            // console.log('--------------------------------------------')
            if (
                currentTime.isBetween(
                    startWorkTime,
                    endWorkTime,
                    undefined,
                    '[]'
                )
            )
                return (
                    <Tr key={_history._id}>
                        {/* <Td>{index + 1}</Td> */}
                        <Td>
                            {`${moment(_history.date).format('HH:mm')}-${moment(
                                _history.date
                            )
                                .endOf('hour')
                                .format('HH:mm')} น.`}
                        </Td>
                        {_.map(_history.sensors, (sensor) => {
                            return (
                                <Td key={sensor._id}>
                                    <div className="text-center">
                                        {`${sensor.rate.toFixed(0)}`}
                                    </div>
                                </Td>
                            )
                        })}
                    </Tr>
                )
        })
    return (
        <div>
            <Table size="sm" variant="striped" className="whitespace-no-wrap">
                <Thead>
                    <Tr>
                        {/* <Th>#</Th> */}
                        <Th>
                            {t('dateTime.time')} ( {t('dateTime.hours')})
                        </Th>
                        {_.map(history[0].sensors, (sensor) => {
                            return (
                                <Th key={sensor._id}>
                                    <div className="text-center">
                                        {sensor.name}{' '}
                                        <span className="text-xs">
                                            {' '}
                                            ( {t('counter.unit')}/
                                            {t('dateTime.minute')} )
                                        </span>
                                    </div>
                                </Th>
                            )
                        })}
                    </Tr>
                </Thead>
                <Tbody>{genEstimateBillLists()}</Tbody>
            </Table>
        </div>
    )
}

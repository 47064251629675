/* eslint-disable quotes */
import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import { Button } from '@chakra-ui/react'
//import Moveable from 'react-moveable'
import Draggable from 'react-draggable'
import GridLines from 'react-gridlines'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, useFieldArray, useWatch, Controller } from 'react-hook-form'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'
import DiagramRenderingComponent from '../../../../components/Diagram/Function/DiagramRenderingComponent'

import { useParams, useHistory, Link } from 'react-router-dom'
import * as actions from '../../../../redux/actions'
import Config from '../../../../config'

// Create For Test New scada.
export default function EditDiagramOrder() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const targetRef = useRef(null)
  const params = useParams()
  const allStations = useSelector((state) => state.station)
  const [currentStation, setCurrentStation] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.stationAll()).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [params])

  useEffect(() => {
    if (allStations) {
      const tempcurrentStatus = _.find(
        allStations.arr,
        (station) => station._id === params.id
      )
      setCurrentStation(tempcurrentStatus)
      console.log('Curent Station', currentStation)
    }
    return () => {}
  }, [params, allStations])

  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {},
  })

  const { fields } = useFieldArray({
    control,
    name: 'diagram',
  })

  useEffect(() => {
    if (currentStation) {
      console.log('Order diagram by order level')
      const orderedDiagram = _.map(currentStation.diagrams, (member) => {
        return member
      })
      setValue('diagram', orderedDiagram)
    }
  }, [currentStation])

  const trackPos = (data) => {
    try {
      const index = data?.index

      const selectedDiagram = watch(`diagram`)
      selectedDiagram[index].axisX = data.x
      selectedDiagram[index].axisY = data.y
      setValue('diagram', selectedDiagram)
    } catch (error) {
      console.error('Cannot set diagram ', error)
    }
  }

  const saveOrdered = async (data) => {
    let confirm = window.confirm('ยืนยันการจัดเรียง')
    if (confirm) {
      const payload = { diagrams: [...fields] }
      await dispatch(actions.stationPut(params?.id, payload))
      await dispatch(actions.stationAll())
      console.log('New Rendered ', payload)
    }
  }
  const genDiagramList = (provided, snapshot) => {
    return fields.map((items, index) => (
      <div>
        <div key={items.id} className="target w-56" ref={targetRef}>
          {/* <Draggable
          key={items._id}
          defaultPosition={{ x: items?.axisX, y: items?.axisY }}
          onDrag={(e, data) => trackPos({ ...data, items, index })}
          handle="#handle"
        >
          <div id="handle" className="cursor-move">
            <DiagramRenderingComponent
              attributeData={items?.attribute[0]}
              stationData={currentStation}
              diagramData={items}
              display={false}
            />
          </div>
        </Draggable>{' '} */}

          <DiagramRenderingComponent
            attributeData={items?.attribute[0]}
            stationData={currentStation}
            diagramData={items}
            display={true}
          />

          {/* <h1>
          x:{watch(`diagram[${index}].axisX`)}, y:
          {watch(`diagram[${index}].axisY`)}
        </h1> */}
        </div>
        {/* <Moveable
          key={items._id}
          target={targetRef}
          draggable={true}
          throttleDrag={1}
          edgeDraggable={false}
          startDragRotate={0}
          throttleDragRotate={0}
          onDrag={(e, data) => trackPos({ ...data, items, index })}
        /> */}
      </div>
    ))
  }

  if (currentStation && isLoading) {
    return (
      <div>
        <div className=" flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
          <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1">
                <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                  {t('setting.systemManagement')}
                </h6>
                <h2 className="text-gray-800 text-2xl font-bold font-sans">
                  {t('arrangement.manageFlowchartArrangement')}{' '}
                  {currentStation.name}
                </h2>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(saveOrdered)}>
            <div className="flex justify-between p-4">
              <div className="flex justify-items-start">
                {' '}
                <Button
                  colorScheme="green"
                  variant="solid"
                  size="sm"
                  type="submit"
                >
                  {t('utility.saveArrangement')}
                </Button>
              </div>
            </div>
            <div className="bg-gray-500  p-6 m-6 overflow-auto">
              <GridLines
                className="grid-area"
                lineColor="black"
                cellWidth={120}
                strokeWidth={5}
                cellWidth2={20}
                lineColor2="gray"
              >
                <div className="h-4/6 w-1280 ">{genDiagramList()}</div>
              </GridLines>
            </div>
          </form>
        </div>
      </div>
    )
  } else {
    return <SpinnerLoading />
  }
}

/**
 *
 * @param {*} lowerOrUpper Use word lower or upper
 * @param {*} chartData
 * @param {*} sensorData
 * @returns
 */

export default function findChartAnnotation(
  lowerOrUpper,
  chartData,
  sensorData
) {
  if (lowerOrUpper === 'lower') {
    if (chartData?.lineOfAlert?.linkSensorThreshold) {
      return sensorData?.lowerThresholdValue
    } else {
      return chartData?.lineOfAlert?.lower
    }
  } else if (chartData?.lineOfAlert?.linkSensorThreshold) {
    return sensorData?.upperThresholdValue
  } else {
    return chartData?.lineOfAlert?.upper
  }
}

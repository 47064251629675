import axios from 'axios'
import {
    COUNTING_SUMMARY_ALL,
    COUNTING_SUMMARY_GET,
    COUNTING_SUMMARY_PUT,
    COUNTING_SUMMARY_DEL,
} from '../types'

export const countingSummaryAll = (query) => {
    const { size = 30, page = 1 } = query
    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL +
                    `/counting-summary/?size=${size}&page=${page}`
            )
            .then((res) => {
                console.log('Request Server to Get All countingSummary')
                dispatch({ type: COUNTING_SUMMARY_ALL, payload: res.data })
            })
    }
}

export const countingSummaryGet = (payload) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/counting-summary/' + payload)
            .then((res) => {
                console.log('Request Server to Get an countingSummary')
                if (res.data) {
                    dispatch({ type: COUNTING_SUMMARY_GET, payload: res.data })
                } else {
                    dispatch({ type: COUNTING_SUMMARY_GET, payload: null })
                }
            })
    }
}

export const countingSummaryPut = (id, payload) => {
    return async (dispatch) => {
        await axios
            .put(
                process.env.REACT_APP_API_URL + '/counting-summary/' + id,
                payload
            )
            .then((res) => {
                console.log('Request Server to put an countingSummary')
                dispatch({ type: COUNTING_SUMMARY_PUT, payload: null })
                dispatch(countingSummaryGet(id))
            })
    }
}

export const countingSummaryPost = (payload) => {
    return async (dispatch) => {
        await axios
            .post(process.env.REACT_APP_API_URL + '/counting-summary/', payload)
            .then((res) => {
                console.log('Request to Create countingSummary')
                dispatch(countingSummaryAll())
            })
    }
}
export const countingSummaryDelete = (payload) => {
    return async (dispatch) => {
        await axios
            .delete(
                process.env.REACT_APP_API_URL + '/counting-summary/' + payload
            )
            .then((res) => {
                console.log('Request Server to Delete an countingSummary')
                dispatch({ type: COUNTING_SUMMARY_DEL, payload: null })
            })
    }
}

import React, { useState } from 'react'
import { Chart as ChartClassic } from 'react-google-charts'
import { Box } from '@chakra-ui/react'
import { min } from 'lodash'
import Config from '../../../../config'

export default function DisplayMeterClassicAttribute({
  staticData,
  stationData,
  unit,
  highResolution,
  lowResolution,
  valueLabel,
  attributeData,
  diagramData,
}) {
  let displaySize = diagramData?.imageSize
  // const series = [(staticData * 100) / (highResolution - lowResolution)]
  //console.log('static', staticData, attributeData)

  const sensorSourceData = _.find(
    stationData?.sensors,
    (sensor) => sensor.refString === attributeData.sensorRef
  )

  const rangeResolution = {
    lowHalf: lowResolution + (highResolution - lowResolution) * 0.25,
    half: lowResolution + (highResolution - lowResolution) * 0.5,
    hightHalf: lowResolution + (highResolution - lowResolution) * 0.75,
  }
  const options = {
    plotOptions: {
      greenColor: '#0084ff',
      greenFrom: lowResolution,
      greenTo: lowResolution + (highResolution - lowResolution) * 0.2,
      redFrom: lowResolution + (highResolution - lowResolution) * 0.9,
      redTo: highResolution,
      yellowFrom: lowResolution + (highResolution - lowResolution) * 0.75,
      yellowTo: lowResolution + (highResolution - lowResolution) * 0.9,
      max: highResolution,
      min: lowResolution,
      majorTicks: [
        lowResolution,
        rangeResolution.lowHalf,
        rangeResolution.half,
        rangeResolution.hightHalf,
        highResolution,
      ],
      minorTicks: (rangeResolution.half - rangeResolution.lowHalf) / 5,
    },
    dataLabels: {
      name: {
        show: true,
      },
      value: {
        show: true,
        formatter: (val) =>
          parseFloat(sensorSourceData?.numericStatus).toFixed(2) + ' ' + unit,
        offsetY: 5,
      },
    },
  }

  if (sensorSourceData) {
    return (
      <Box maxW="sm" className="w-full ">
        {' '}
        <ChartClassic
          width={150 + displaySize}
          height={170 + displaySize}
          chartType="Gauge"
          className="mx-10 font-semibold text-xl"
          loader={<div>Loading Gauge</div>}
          data={[
            ['Label', 'Value'],
            ['', sensorSourceData?.numericStatus],
          ]}
          options={options.plotOptions}
        />
      </Box>
    )
  }
}

export const VariableTypeOfSource = {
  sensor: 'เซ็นเซอร์',
  constant: 'ค่าคงที่',
  multiSensor: 'เซ็นเซอร์ 2 ตัว',
  sensorAndConstant: 'เซ็นเซอร์และค่าคงที่',
}

export const GaugeSource = {
  sensor: 'Sensor',
  variable: 'Variable',
  null: 'null',
}

export default { VariableTypeOfSource, GaugeSource }

import React from 'react'
import PropTypes from 'prop-types'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/th'
import dayjs from 'dayjs'
dayjs.locale('th')
dayjs.extend(buddhistEra)
dayjs.extend(LocalizedFormat)

const HeaderReportSteam = (form, date) => ({
  margin: [10, 10],
  style: 'tableExample',
  table: {
    widths: ['23%', '54%', '23%'],
    body: [
      [
        {
          image: 'logo_imge',
          //text: 'logo',
          rowSpan: 2,
          fit: [35, 35],
          alignment: 'center',
          margin: [0, 2, 0, 0],
          border: [true, true, true, false],
        },
        {
          text: `${form?.name || ''}`,
          alignment: 'center',
          bold: true,
          rowSpan: 2,
          //font: 'Angsana New',
          fontSize: '18',
          margin: [0, 5],
          border: [true, true, true, false],
        },
        { text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `, fontSize: '14' },
      ],
      [
        { text: '', alignment: 'center' },
        { text: ``, alignment: 'center' },
        {
          text: `วันที่บังคับใช้ ${
            dayjs(form?.enforceDate).format('DD/MM/YYYY') || '-'
          }`,
          fontSize: '14',
        },
      ],
      [
        {
          text: 'THAI TALLOW AND OIL CO.,LTD.',
          alignment: 'center',
          bold: true,
          fontSize: '12',
          border: [true, false, true, true],
        },
        {
          text: `วันที่ ${dayjs(date).format('DD เดือน MMMM พ.ศ. BBBB ')}`,
          alignment: 'center',
          fontSize: '14',
          border: [true, false, true, true],
        },
        { text: `แก้ไขครั้งที่: 0${form?.editVersion || '-'}`, fontSize: '14' },
      ],
    ],
  },
})

HeaderReportSteam.propTypes = {
  form: PropTypes.object,
}

export default HeaderReportSteam

/* eslint-disable import/no-named-as-default-member */
import React from 'react'
import _ from 'lodash'
import GeneralGaugeSelector from '../../../components/Gagues/Functions/GeneralGaugeSelector'
import SensorDataRendering from '../../../components/Sensors/Functions/SensorDataRendering'
import VariableDataRendering from '../../../components/Variables/Function/VariableMainRendering'
import Config from '../../../config'
import { log10 } from 'mathjs'

export default function DashboardFavoriteComponent({
  currentStation,
  currentStation2,
  handleEditUserFavorite,
  user,
}) {
  let orderedGauge = _.orderBy(currentStation.gauges, 'orderLevel', 'asc')

  const handleFavorite = () => {
    return (
      <div className="grid grid-cols-1 gap-x-4 gap-y-1 lg:grid-cols-4 md:gid-cols-3 mr-6 mb-4">
        {_.map(currentStation.gauges, (e, index) => {
          if (e.gaugeSource === Config.TypeOfSource.GaugeSource.sensor) {
            const check = _.find(
              user?.favorite?.gauge,
              (gauges) => gauges === e?._id
            )
            if (check) {
              return (
                <GeneralGaugeSelector
                  gaugeData={e}
                  key={index}
                  stationData={currentStation}
                  staticValue={SensorDataRendering(currentStation, e)}
                  handleEditUserFavourite={handleEditUserFavorite}
                  user={user}
                />
              )
            }
          } else {
            console.log('ee', e)
            const check = _.find(
              user?.favorite?.gauge,
              (gauges) => gauges === e?._id
            )
            console.log('check', check)
            if (check) {
              return (
                <GeneralGaugeSelector
                  gaugeData={e}
                  key={index}
                  stationData={currentStation}
                  staticValue={VariableDataRendering(
                    currentStation,
                    e?.variableId
                  )}
                  handleEditUserFavourite={handleEditUserFavorite}
                  user={user}
                />
              )
            }
            // return (
            //   <GeneralGaugeSelector
            //     gaugeData={e}
            //     key={index}
            //     stationData={currentStation}
            //     staticValue={VariableDataRendering(
            //       currentStation,
            //       currentStation?.variableId
            //     )}
            //     handleEditUserFavourite={handleEditUserFavorite}
            //     user={user}
            //   />
            // )
          }
        })}
      </div>
    )
  }

  return handleFavorite()
}

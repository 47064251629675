/* eslint-disable import/named */
import api from '../../../util/api'
import {
  SUMMARIZE_ALL,
  SUMMARIZE_GET,
  SUMMARIZE_PUT,
  SUMMARIZE_DEL,
  SUMMARIZE_POST,
  SUMMARIZE_ERROR,
} from '../types'

export const summarizeAll = () => {
  return async (dispatch) => {
    await api
      .get(process.env.REACT_APP_API_URL + '/summarize')
      .then((res) => {
        console.log('Request Server to Get All summarize')
        dispatch({ type: SUMMARIZE_ALL, payload: res.data })
      })
      .catch((error) => {
        console.error(error)
        dispatch({ type: SUMMARIZE_ERROR })
        throw new Error(error)
      })
  }
}

export const summarizeGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/summarize/${id}`
    )
    if (status === 200) {
      dispatch({ type: SUMMARIZE_GET, payload: data })
    }
  } catch (error) {
    console.error(error)
    dispatch({ type: SUMMARIZE_ERROR })
    throw new Error(error)
  }
}

export const summarizePut = (id, payload) => async (dispatch) => {
  try {
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/summarize/${id}`,
      payload
    )
    dispatch({ type: SUMMARIZE_PUT, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: SUMMARIZE_ERROR })
    throw new Error(error)
  }
}

export const summarizePost = (payload) => async (dispatch) => {
  try {
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/summarize/`,
      payload
    )
    dispatch({ type: SUMMARIZE_POST, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: SUMMARIZE_ERROR })
    throw new Error(error)
  }
}

export const summarizeDelete = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/summarize/${id}`
    )
    dispatch({ type: SUMMARIZE_DEL, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: SUMMARIZE_ERROR })
    throw new Error(error)
  }
}

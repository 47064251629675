import React from 'react'
import PropTypes from 'prop-types'

function FooterReportQC() {
  return {
    margin: [20, 100, 20, 20],
    style: 'tableExample',
    table: {
      widths: ['50%', '50%'],
      body: [
        [
          {
            text: 'ลงชื่อ ........................................................... พนักงานควบคุมคุณภาพ',
            alignment: 'left',
            border: [false, false, false, false],
          },
          {
            text: ' ',
            alignment: 'center',
            border: [false, false, false, false],
          },
        ],
      ],
    },
  }
}

FooterReportQC.propTypes = {}

export default FooterReportQC

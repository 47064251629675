import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// components
import { Box, Button, Link as LinkUI } from '@chakra-ui/react'

import SpinnerLoading from 'components/Loading/SpinnerLoading'
import _ from 'lodash'
import { stationAll } from '../../redux/actions'

export default function Diagrams() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [allowedDashboard, setAllowedDashboard] = useState()
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me)
  const stations = useSelector((state) => state.station)
  useEffect(() => {
    dispatch(stationAll()).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [])

  // ตรวจสอบว่า User ที่เข้ามามี Permission ที่เข้าสถานีไหนได้บ้าง
  // ตอนนี้ยังเก็บเป็น Text อยู่ เก็บเฉพาะใน User ไม่ได้เก็บในสถานี
  // ถ้าอนุญาติหมดจะเป็น ALLAREA
  // TODO: ถ้าเป็น Array จะดีมาก
  useEffect(() => {
    if (me && me._id) {
      if (_.size(stations.arr)) {
        if (_.includes(me.duty, 'ALLAREA')) {
          let allowedStationStack = stations.arr
          setAllowedDashboard(allowedStationStack)
        } else {
          let allowedStation = _.filter(
            stations.arr,
            (station, index) => station._id === me.duty[index]
          )
          setAllowedDashboard(allowedStation)
        }
      }
    }
    return () => {}
  }, [stations, me])

  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              {t('dataAndStat.dataAndStat')}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans ">
              {t('dataAndStat.dataAndStat')}
            </h2>
          </div>
        </div>
      </div>
      {/* <div className="flex justify-between p-4"></div> */}

      <div className="p-4 w-full">
        {_.size(stations.arr) ? (
          <>
            {' '}
            {_.map(allowedDashboard, (station) => (
              <Box
                padding="4"
                borderRadius="lg"
                borderWidth="1px"
                className="mb-3"
              >
                <Link to={'/statistics/' + station._id}>
                  <LinkUI>
                    {' '}
                    <h3 className="font-bold text-xl font-sans">
                      <i className="fas fa-search text-sm"> </i> {station.name}{' '}
                    </h3>
                  </LinkUI>
                </Link>
                <h5 className="text-base text-gray-700 font-sans">
                  {' '}
                  {station.description} | {station.location}{' '}
                </h5>
              </Box>
            ))}
          </>
        ) : (
          <div>{t('utility.empty')}</div>
        )}
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}

import EditBooleanWater from './BackwardCompatible/EditBooleanWaterDisplay'
import EditMultiStepWaterDisplay from './MultiStep/EditMultistepWaterDisplay'
import EditStaticDisplay from './StaticData'
import EditMultiStepNumberDisplay from './MultiStep/EditMultiStepNumberDisplay'
import EditMultiStepBooleanDisplay from './MultiStep/EditMultiStepBooleanDisplay'
import EditStringDisplay from './BackwardCompatible/EditStringDisplay'
import EditMeterData from './BackwardCompatible/EditMeterDataDisplay'
import EditNumberDisplay from './BackwardCompatible/EditNumberDisplay'
import EditBooleanDisplay from './BackwardCompatible/EditingBooleanDisplay'

const BackwardCompatible = {
    EditMeterData,
    EditNumberDisplay,
    EditBooleanDisplay,
    EditStringDisplay,
    EditBooleanWater,
}

export default {
    EditMultiStepWaterDisplay,
    EditMultiStepBooleanDisplay,
    EditMultiStepNumberDisplay,
    BackwardCompatible,
    EditStaticDisplay,
}

export const SensorGaugeType = {
    OnOffGauge: {
        value: 'OnOffGauge',
        describe: 'เซ็นเซอร์ที่มีการควบคุม',
    },
    TriggerGague: {
        value: 'TriggerGague',
        describe: 'เซ็นเซอร์ที่ควบคุมโดยใช้ Trigger',
    },
    MajorControl: {
        value: 'MajorControl',
        describe: 'เซ็นเซอร์อนุญาติให้ควบคุมเซ็นเซอร์อื่นๆ',
    },
    MajorControl: {
        value: 'MajorControl',
        describe: 'เซ็นเซอร์อนุญาติให้ควบคุมเซ็นเซอร์อื่นๆ',
    },
    TimerControl: {
        value: 'TimerControl',
        describe: 'เซ็นเซอร์ควบคุมแบบตั้งเวลา',
    },
}

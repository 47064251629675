export const NumberSensorType = {
    Normal: {
        "value": "Normal",
        "describe": "เซ็นเซอร์ปกติ"
    },
    Counter: {
        "value": "Counter",
        "describe": "เซ็นเซอร์สำหรับนับ"
    },
}

import _ from 'lodash'
export default function FetchingGraphData(dataArr, variableId) {
  let tempValueStack = []
  // console.log('variable Id ', variableId)
  _.map(dataArr, (dataItem) => {
    _.map(dataItem?.value, (variable) => {
      // console.log('variableID', variable.variableId)
      // console.log('variableId is', variableId)
      if (variable?.variableId === variableId) {
        // console.log('It is Matching !!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
        // console.log('XAxis Data', dataItem.date)
        // console.log('YAXis Data', dataItem.value)
        tempValueStack.push({
          x: dataItem?.date,
          y: variable?.value === null ? 0 : variable?.value,
        })
      }
    })
  })
  return tempValueStack
}

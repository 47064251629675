import React, { useState, useEffect } from 'react'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Button, Link as LinkUI } from '@chakra-ui/react'
import * as actions from '../../redux/actions/'
import CountingSummaryLists from './components/CountingSummaryList'
import { Link, useParams } from 'react-router-dom'
import _ from 'lodash'
import { stationAll } from '../../redux/actions'
import TimeRangePicker from '@wojtekmaj/react-timerange-picker'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
export default function CountingSummary() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [value, setValue] = useState(['08:00', '17:00'])
  const [allowedDashboard, setAllowedDashboard] = useState()
  const stationId = useParams()
  const me = useSelector((state) => state.me)
  const stations = useSelector((state) => state.station)
  const countingSummary = useSelector((state) => state.countingSummary)
  const systemInfo = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)
  // ตรวจสอบว่า User ที่เข้ามามี Permission ที่เข้าสถานีไหนได้บ้าง
  // ตอนนี้ยังเก็บเป็น Text อยู่ เก็บเฉพาะใน User ไม่ได้เก็บในสถานี
  // ถ้าอนุญาติหมดจะเป็น ALLAREA
  // TODO: ถ้าเป็น Array จะดีมาก
  useEffect(() => {
    if (me && me._id && stations) {
      if (_.size(stations.arr)) {
        if (_.includes(me.duty, 'ALLAREA')) {
          let allowedStationStack = _.filter(
            stations.arr,
            (station, index) => station.moduleCounting === true
          )
          setAllowedDashboard(allowedStationStack)
        } else {
          let allowedStation = _.filter(
            stations.arr,
            (station, index) => station._id === me.duty[index]
          )
          setAllowedDashboard(allowedStation)
        }
      }
    }
    return () => {}
  }, [stations.arr, me])

  // eslint-disable-next-line no-unused-vars
  const [size, setSize] = useState(30)

  const [page, setPage] = useState(1)

  useEffect(() => {
    if (isLoading) {
      setValue([systemInfo.worktime?.start, systemInfo.worktime?.end])
    }
    return () => {}
  }, [isLoading])

  useEffect(() => {
    dispatch(actions.countingSummaryAll({ size, page })).then(() => {
      dispatch(actions.systemInfoGet()).then(() => {
        setIsLoading(true)
      })
    })
    return () => {}
  }, [size, page])

  const handleUpdateWorkTime = async () => {
    if (!value[0] || !value[1]) {
      alert('กรุณากำหนดช่วงเวลาให้ถูกต้อง')
      return
    }
    let confirm = window.confirm('อัพเดทข้อมูล')
    if (confirm) {
      const worktime = {
        start: value[0],
        end: value[1],
      }
      await dispatch(actions.systemInfoPut(systemInfo._id, { worktime }))
      alert('บันทึกสำเร็จ')
    }
  }

  useEffect(() => {
    dispatch(actions.stationAll({}))
    return () => {}
  }, [])

  const renderRangeTimePicker = () => (
    <div className="gap-2 flex flex-wrap my-4">
      <label className="block uppercase text-gray-700 text-xs font-bold my-2 mx-2">
        {t('counter.counterTime')}
      </label>
      <TimeRangePicker onChange={setValue} value={value} disableClock />
      <Button
        colorScheme="blue"
        size="sm"
        onClick={() => {
          handleUpdateWorkTime()
        }}
      >
        {t('utility.save')}
      </Button>
    </div>
  )

  return isLoading && stations?.isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center bg-gray-100">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              {t('counter.counterInfo')}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              {t('counter.counterInfo')}
            </h2>
          </div>
        </div>

        <div className="flex flex-wrap justify-end">
          {renderRangeTimePicker()}
        </div>

        <div className="p-4 w-full">
          {_.size(countingSummary.rows) ? (
            <>
                  <div className="text-base text-gray-700 font-sans overflow-x-auto">
                    <CountingSummaryLists
                      systemInfo={systemInfo}
                      countingSummary={countingSummary.rows}
                      stationId = {stationId.id}
                    />
                  </div>
            </>
          ) : (
            <div className="flex justify-center p-4">
              <div>{t('dataAndStat.empty')}</div>
            </div>
          )}

          <div className="my-2 flex flex-wrap justify-end gap-1">
            <div>
              <Button
                colorScheme="blue"
                size="sm"
                disabled={countingSummary.currPage === 1 ? true : false}
                onClick={() => {
                  setPage(page - 1)
                }}
              >
                {t('counter.back')}
              </Button>
            </div>
            <div>
              <Button
                colorScheme="blue"
                size="sm"
                disabled={page === countingSummary.lastPage ? true : false}
                onClick={() => {
                  setPage(page + 1)
                }}
              >
                {t('counter.next')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}

import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { Button } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import FormComponent from '../../../../components/Form/FormComponent'
import SpinnerLoading from '../../../../components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'
import { useParams, Link, useHistory } from 'react-router-dom'
import * as actions from '../../../../redux/actions'
import SubFormList from '../Components/SubFormList'

export default function EditSubForm() {
  const history = useHistory()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()

  const subForm = useSelector((state) => state.subForm)
  const dispatch = useDispatch()
  const [selectedForm, setSelectedForm] = useState({})

  useEffect(() => {
    dispatch(actions.subFormGet(params?.formId))
    return () => {}
  }, [])

  const genFormComponentList = () => {
    return (
      <div>
        <div className="flex justify-start gap-3 p-4">
          <Link to={`/system/stations/form/subForm/add/${params?.formId}`}>
            <Button colorScheme="blue" variant="solid" size="sm">
              {t('utility.add')}
            </Button>
          </Link>
          <Link>
            <Button colorScheme="yellow" variant="solid" size="sm">
              จัดเรียง
            </Button>
          </Link>
        </div>
        <div className="p-4 w-full">
          {_.map(subForm?.components, (eachComponent, index) => {
            return (
              <div key={index}>
                <FormComponent
                  componentData={eachComponent}
                  selectedForm={subForm}
                  subForm={true}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  if (subForm?.isLoading && !subForm.isCompleted) {
    return <SpinnerLoading />
  }

  return (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              {t('setting.systemManagement')}
            </h6>
            <h2 className="text-gray-800  font-bold font-sans">ฟอร์มย่อย</h2>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              {subForm?.name}
            </h2>
          </div>
        </div>
      </div>
      {genFormComponentList()}
    </div>
  )
}

export const ConveyorType = {
  Conveyor: {
    value: 'ConveyorType',
    describe: 'Conveyor',
    image: 'Shorthorizontalsegment.svg',
  },

  ConveyorSrc: [
    {
      name: '90° curve 1',
      value: '90curve1.svg',
    },
    {
      name: '90° curve 2',
      value: '90curve2.svg',
    },
    {
      name: '90° curve 3',
      value: '90curve3.svg',
    },
    {
      name: '90° curve 4',
      value: '90curve4.svg',
    },
    {
      name: 'Long Horizontal Segment',
      value: 'Longhorizontalsegment.svg',
    },
    {
      name: 'Long Vertical Segment',
      value: 'Longverticalsegment.svg',
    },
    {
      name: 'Medium Horizontal Segment',
      value: 'Mediumhorizontalsegment.svg',
    },
    {
      name: 'Medium Vertical Segment',
      value: 'Mediumverticalsegment.svg',
    },
    {
      name: 'Short Horizontal Segment',
      value: 'Shorthorizontalsegment.svg',
    },
    {
      name: 'Short Vertical Segment',
      value: 'Shortverticalsegment.svg',
    },
  ],
}

/* eslint-disable import/named */
import api from '../../../util/api'
import {
  PREDICTION_ALL,
  PREDICTION_GET,
  PREDICTION_PUT,
  PREDICTION_DEL,
  PREDICTION_POST,
  PREDICTION_ERROR,
} from '../types'

export const predictionAll = () => {
  return async (dispatch) => {
    await api
      .get(process.env.REACT_APP_API_URL + '/prediction')
      .then((res) => {
        console.log('Request Server to Get All prediction')
        dispatch({ type: PREDICTION_ALL, payload: res.data })
      })
      .catch((error) => {
        console.error(error)
        dispatch({ type: PREDICTION_ERROR })
        throw new Error(error)
      })
  }
}

export const predicionWithDataAll = ({ limit = 20 } = {}) => {
  return async (dispatch) => {
    await api
      .get(
        `${process.env.REACT_APP_API_URL}/prediction/with-data?limit=${limit}`
      )
      .then((res) => {
        console.log('Request Server to Get All prediction with log Data')
        dispatch({ type: PREDICTION_ALL, payload: res.data })
      })
      .catch((error) => {
        console.error(error)
        dispatch({ type: PREDICTION_ERROR })
        throw new Error(error)
      })
  }
}

export const predictionVarDetail = ({
  summarizedId = '',
  varDetailId = '',
  limit = 20,
}) => {
  return async (dispatch) => {
    await api
      .get(
        `${process.env.REACT_APP_API_URL}/prediction/var-detail/?limit=${limit}&summarizedId=${summarizedId}&varDetailId=${varDetailId}`
      )
      .then((res) => {
        console.log('Request Server to Get All prediction with log Data')
        dispatch({ type: PREDICTION_ALL, payload: res.data })
      })
      .catch((error) => {
        console.error(error)
        dispatch({ type: PREDICTION_ERROR })
        throw new Error(error)
      })
  }
}

export const predictionGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/prediction/${id}`
    )
    if (status === 200) {
      dispatch({ type: PREDICTION_GET, payload: data })
    }
  } catch (error) {
    console.error(error)
    dispatch({ type: PREDICTION_ERROR })
    throw new Error(error)
  }
}

export const predictionPut = (id, payload) => async (dispatch) => {
  try {
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/prediction/${id}`,
      payload
    )
    dispatch({ type: PREDICTION_PUT, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: PREDICTION_ERROR })
    throw new Error(error)
  }
}

export const predictionPost = (payload) => async (dispatch) => {
  try {
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/prediction/`,
      payload
    )
    dispatch({ type: PREDICTION_POST, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: PREDICTION_ERROR })
    throw new Error(error)
  }
}

export const predictionDelete = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/prediction/${id}`
    )
    dispatch({ type: PREDICTION_DEL, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: PREDICTION_ERROR })
    throw new Error(error)
  }
}

import api from './api'

export const uploadImageFile = async (file) => {
  try {
    const formData = new FormData()
    formData.append('file', file)
    const { data: uploadData } = await api.post(
      `${process.env.REACT_APP_API_URL}/upload`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )
    console.log('Upload Data', uploadData)
    return uploadData?.url
  } catch (error) {
    alert('อัพโหลดไม่สำเร็จ ' + error.message)
    throw new Error(error)
  }
}

export default uploadImageFile

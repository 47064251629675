export const FlowMeterType = {
  FlowMeter: {
    value: 'FlowMeterType',
    describe: 'Flow Meter',
  },

  FlowMeterSrc: [
    {
      name: 'Flow Meter',
      value: 'Flowmeter.svg',
    },
    {
      name: 'Flow Meter 2',
      value: 'Flowmeter2.svg',
    },
    {
      name: 'Flow Meter 3',
      value: 'Flowmeter3.svg',
    },
    {
      name: 'Flow Totalizer',
      value: 'FlowTotalizer.svg',
    },
    {
      name: 'Magnetic Flowmeter',
      value: 'MagneticFlowmeter.svg',
    },
    {
      name: 'Magnetic Flowmeter 1',
      value: 'MagneticFlowmeter1.svg',
    },
    {
      name: 'Magnetic Flowmeter 2',
      value: 'MagneticFlowmeter2.svg',
    },
    {
      name: 'Thermal Flowmeter',
      value: 'ThermalFlowmeter.svg',
    },
    {
      name: 'Turbine Meter',
      value: 'Turbinemeter.svg',
    },
    {
      name: 'Turbine Meter 2',
      value: 'Turbinemeter2.svg',
    },
    {
      name: 'Turbine Meter 3',
      value: 'Turbinemeter3.svg',
    },
    {
      name: 'Turbine Meter 4',
      value: 'Turbinemeter4.svg',
    },
  ],
}

import React, { useState } from 'react'
import Chart from 'react-apexcharts'
import { Box } from '@chakra-ui/react'

/**
 * @function Meter
 * @deprecated since 2021-04-15
 */
export default function Meter({
  sensorData,
  graphLabel,
  unit,
  highResolution,
  lowResolution,
  valueLable,
}) {
  // console.log('High Resolution ' + highResolution)
  // console.log('Low Resolution ' + lowResolution)
  const series = [
    (sensorData.numericStatus * 100) / (highResolution - lowResolution),
  ]

  const options = {
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#e7e7e7',
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
        },
        dataLabels: {
          name: {
            show: true,
          },
          value: {
            show: true,
            formatter: (val) =>
              parseFloat(sensorData.numericStatus).toFixed(2) + ' ' + unit,
            offsetY: 5,
          },
        },
      },
    },
    // stroke: {
    //   dashArray: 4,
    // },
    labels: [valueLable ? valueLable : ''],
  }

  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      className=" ml-4 my-2 p-4 w-full  h-64"
    >
      <h5 className="font-bold text-xl "> {graphLabel} </h5>
      <Chart
        height="270"
        series={series}
        options={options}
        type="radialBar"
      />{' '}
    </Box>
  )
}

import {
    COUNTING_SUMMARY_ALL,
    COUNTING_SUMMARY_GET,
    COUNTING_SUMMARY_PUT,
    COUNTING_SUMMARY_DEL,
    COUNTING_SUMMARY_POST,
} from '../../actions/types'

const initialState = {
    countingSummary: null,
    isLoading: false,
    isCompleted: true,
}
export default function (state = initialState, action) {
    switch (action.type) {
        case COUNTING_SUMMARY_ALL:
            return {
                ...action.payload,
                isLoading: true,
                isCompleted: true,
            }
        case COUNTING_SUMMARY_GET:
            return {
                ...action.payload,
                isLoading: true,
                isCompleted: true,
            }
        case COUNTING_SUMMARY_POST:
            return { isLoading: true, isCompleted: true }
        case COUNTING_SUMMARY_PUT:
            return action.payload
        case COUNTING_SUMMARY_DEL:
            return { isLoading: true, isCompleted: true }
        default:
            return state
    }
}

import React, { useEffect, useState } from 'react'
import { useForm, useFieldArray, useWatch, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { Link, useParams, useHistory } from 'react-router-dom'
import Draggable from 'react-draggable'

// components
import { Button } from '@chakra-ui/react'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import DiagramRenderingComponent from 'components/Diagram/Function/DiagramRenderingComponent'
import _ from 'lodash'

import { useTranslation } from 'react-i18next'

export default function NewStationDiagram({ sidebarStatus, setIsShowSidebar }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [currentStation, setcurrentStation] = useState()
  const [isFetchNewOne, setisFetchNewOne] = useState(true)
  const dispatch = useDispatch()
  const params = useParams()
  const allStations = useSelector((state) => state.station)
  const me = useSelector((state) => state.me)
  const history = useHistory()
  useEffect(() => {
    if (me && me._id && currentStation) {
      if (_.includes(me.duty, 'ALLAREA') || me.duty === currentStation._id) {
      } else {
        console.log('ผู้ใช้ไม่มีสิทธิเข้าใช้', currentStation)
      }
    }
    return () => {}
  }, [me, currentStation])

  useEffect(() => {
    if (isFetchNewOne === true) {
      dispatch(actions.stationAll())
      //FIXME: Uncommen to Enable Auto Refresh
      setisFetchNewOne(false)
    }
    return () => {}
  }, [isFetchNewOne])

  useEffect(() => {
    if (allStations) {
      const tempcurrentStatus = _.find(
        allStations.arr,
        (station) => station._id === params.stationId
      )
      setcurrentStation(tempcurrentStatus)
      setIsLoading(true)
    }
    return () => {}
  }, [params, allStations])

  useEffect(() => {
    if (isFetchNewOne === false) {
      setTimeout(() => {
        setisFetchNewOne(true)
      }, 1000)
    }
    return () => {}
  }, [isFetchNewOne])

  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {},
  })

  const { fields } = useFieldArray({
    control,
    name: 'diagram',
  })

  useEffect(() => {
    if (currentStation) {
      console.log('Order diagram by order level')
      const orderedDiagram = _.map(currentStation.diagrams, (member) => {
        return member
      })
      setValue('diagram', orderedDiagram)
    }
  }, [currentStation])

  const genDiagramList = (provided, snapshot) => {
    return fields.map((items, index) => (
      <div key={items.id} className="w-56">
        <Draggable
          key={items._id}
          defaultPosition={{ x: items?.axisX, y: items?.axisY }}
          disabled="true"
        >
          <div>
            <DiagramRenderingComponent
              attributeData={items?.attribute[0]}
              stationData={currentStation}
              diagramData={items}
            />
          </div>
        </Draggable>
      </div>
    ))
  }

  return isLoading && currentStation ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              {t('dashboard.flowchart')}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold  font-sans">
              {t('dashboard.flowchart')}
              {currentStation.name}
            </h2>
            <p className="text-base text-gray-500 mb-1 font-sans  ">
              {currentStation.location}
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-between p-4 ">
        <div className="flex justify-items-start"></div>
        <div className="flex justify-items-end">
          <Button
            colorScheme="gray"
            variant="solid"
            size="sm"
            onClick={() => setIsShowSidebar(!sidebarStatus)}
          >
            {t('dashboard.toggleSidebar')}
          </Button>
        </div>
      </div>
      <div className="bg-gray-500  p-6 m-6 overflow-auto">
        <div className="h-4/6 w-1280 ">{genDiagramList()}</div>
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}

import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../../redux/actions'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// components
import { Button, Link as LinkUI, Box, Badge } from '@chakra-ui/react'

import SpinnerLoading from '../../../../components/Loading/SpinnerLoading'
import _ from 'lodash'

export default function ManagementForm() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const form = useSelector((state) => state.form)
  const station = useSelector((state) => state.station)
  const params = useParams()

  useEffect(() => {
    dispatch(actions.formAll({ station: params.stationId }))
    return () => {}
  }, [])

  if (form.isLoading && !form.isCompleted) {
    return <SpinnerLoading />
  }

  return (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              การจัดการแบบฟอร์ม
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans ">
              รายการแบบฟอร์ม {station?.name || ''}
            </h2>
          </div>
        </div>
      </div>
      <div className="flex justify-start gap-3 p-4">
        <Link to={`/system/stations/form/create/${params.stationId}`}>
          <Button colorScheme="blue" variant="solid" size="sm">
            {t('utility.add')}
          </Button>
        </Link>
      </div>
      <div className="p-4 w-full">
        {_.map(form.arr, (eachForm, index) => (
          <Box
            borderRadius="lg"
            borderWidth="1px"
            padding="4"
            className="mb-4"
            key={index}
          >
            <Link
              to={`/system/stations/form/${params.stationId}/${eachForm?._id}`}
            >
              <h2 className="text-xl font-bold font-sans">
                <LinkUI>
                  <i className="fas fa-search text-sm "> </i> {eachForm?.name}
                  {eachForm?.inputWithSensor?.status && (
                    <Badge background={'red.300'} borderRadius={'lg'}>
                      รับค่าอัตโนมัติ
                    </Badge>
                  )}
                </LinkUI>
              </h2>
            </Link>
            <h5 className="text-base text-gray-700 font-sans">
              {eachForm?.description}
            </h5>
          </Box>
        ))}
      </div>
    </div>
  )
}

export const BoilerType = {
  Boiler: {
    value: 'BoilerType',
    describe: 'Boiler',
    image: 'Boiler.svg',
  },

  BoilerSrc: [
    {
      name: 'Boiler',
      value: 'Boiler.svg',
    },
    {
      name: 'Boiler 1',
      value: 'Boiler1.svg',
    },
    {
      name: 'Boiler 2',
      value: 'Boiler2.svg',
    },
    {
      name: 'Boiler Invert',
      value: 'BoilerInvert.svg',
    },
    {
      name: 'Boiler Invert 2',
      value: 'BoilerInvert2.svg',
    },
    {
      name: 'Boiler with flames',
      value: 'Boilerwithflames.svg',
    },
    {
      name: 'Water tube Boiler',
      value: 'Watertubeboiler.svg',
    },
    {
      name: 'Water tube Boiler Invert',
      value: 'WatertubeboilerInvert.svg',
    },
  ],
}

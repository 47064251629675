import React, { useState, useEffect } from 'react'
import ReportSettingForm from '../Components/ReportSettingForm'
import { useParams, useHistory } from 'react-router-dom'
import * as actions from '../../../../redux/actions'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from '../../../../components/Loading/SpinnerLoading'
import { Button } from '@chakra-ui/react'

export default function ReportSetting() {
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const station = useSelector((state) => state.station)
  const form = useSelector((state) => state.form)
  const [orientations, setOrientations] = React.useState(
    form?.pdfSetting?.orientations
  )
  const [footer, setFooter] = useState(form?.pdfSetting?.footer)
  const [header, setHeader] = useState(form?.pdfSetting?.header)
  const [fixForm, setFixform] = useState('generate')
  const [fixFormName, setFixFormName] = useState('')

  useEffect(() => {
    dispatch(actions.stationGet(params.stationId))
    dispatch(actions.formGet(params?.formId))
    return () => {}
  }, [params])

  const prop = {
    footer,
    setFooter,
    header,
    setHeader,
    fixForm,
    setFixform,
    handleSubmit: async () => {
      const confirm = window.confirm('บันทึกข้อมูล')
      if (confirm) {
        const dataSubmit = {
          pdfSetting: {
            orientations,
            footer,
            header,
            formType: fixForm,
            fixFormName,
          },
        }
        await dispatch(actions.formPut(params.formId, dataSubmit))
        dispatch(actions.formGet(params.formId))
        history.goBack()
      }
    },
    fixFormName,
    setFixFormName,
  }
  console.log(fixFormName)

  const renderReportForm = () => (
    <ReportSettingForm
      station={station}
      orientations={orientations}
      setOrientations={setOrientations}
      {...prop}
    />
  )
  return <div>{renderReportForm()}</div>
}

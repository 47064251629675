import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderCheckContRows = (data) => {
  const content = _.map(data?.arr, (row, key) => [
    { text: key + 1 || '', style: 'tableContent' },
    { text: row?.No || '', style: 'tableContent' },
    {
      text: Intl.NumberFormat('en-US').format(row?.weight) || '',
      style: 'tableContent',
    },
    { text: row?.start || '', style: 'tableContent' },
    { text: row?.Min15.แรงดัน || '', style: 'tableContent' },
    { text: row?.Min15.อุณหภูมิ || '', style: 'tableContent' },
    { text: row?.Min30.แรงดัน || '', style: 'tableContent' },
    { text: row?.Min30.อุณหภูมิ || '', style: 'tableContent' },
    { text: row?.Timer || '', style: 'tableContent' },
    { text: row?.TimeOut || '', style: 'tableContent' },
    { text: row?.comment || '', style: 'tableContent' },
  ])
  return content
}

const renderTableBody = (data) => {
  const DataSet = renderCheckContRows(data)
  console.log(DataSet)

  if (DataSet?.length !== 32) {
    for (let i = DataSet?.length; i < 32; i += 1) {
      DataSet.push([
        { text: `${i + 1}` || '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        {
          text: '',
          style: 'tableContent',
        },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    }
    return DataSet
  }
  return DataSet
}

const FM_PD_001 = async (form, formInput, subForm, system, date) => {
  const Totalweight = _.sumBy(formInput.arr, (e) => parseInt(e.weight))
  const TableBody = renderTableBody(formInput)

  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [10, 73, 10, 10],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      {
        margin: [10, 8, 10, 0],
        style: 'tableExample',
        table: {
          widths: ['24.1%', '55.17%', '20.73%'],
          body: [
            [
              {
                image: 'logo_imge',
                rowSpan: 2,
                fit: [35, 35],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: `${form?.name || ''} - (FM-PD-001)`,
                alignment: 'center',
                bold: true,
                rowSpan: 2,
                font: 'AngsanaNew',
                fontSize: '18',
                margin: [0, 5],
                border: [true, true, true, false],
              },
              {
                text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
                fontSize: '12',
              },
            ],
            [
              { text: '', alignment: 'center' },
              { text: '', alignment: 'center' },
              {
                text: `วันที่บังคับใช้: ${
                  dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                }`,
                fontSize: '12',
              },
            ],
            [
              {
                text: 'THAITALLOW AND OIL CO.,LTD.',
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: '11',
                border: [true, false, true, true],
              },
              {
                text: `วันที่ ${dayjs(date).format(
                  'DD เดือน MMMM พ.ศ. BBBB '
                )}`,
                alignment: 'center',
                bold: true,
                fontSize: '14',
                border: [true, false, true, true],
              },
              {
                text: `แก้ไขครั้งที่: 0${form?.editVersion || '-'}`,
                fontSize: '12',
              },
            ],
          ],
        },
      },
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        table: {
          widths: [
            '9%',
            '6%',
            '9%',
            '9%',
            '11%',
            '9%',
            '9%',
            '9%',
            '7%',
            '8%',
            '14%',
          ],
          body: [
            [
              // Row 1
              {
                stack: [
                  { text: 'ชุดที่', bold: true },
                  {
                    canvas: [
                      {
                        type: 'line',
                        x1: 48, //เพิ่มเพื่อให้ด้านขวาออกไป
                        y1: -17, //เพิ่มเพื่อให้ด้านขวาลง
                        x2: -4,
                        y2: 32, //เพิ่มเพื่อให้ด้านซ้ายลง
                        lineWidth: 1,
                        lineColor: '#000000',
                      },
                    ],
                  },
                  {
                    text: 'ค่าควบคุม',
                    alignment: 'right',
                    margin: [0, -16, 0, 0],
                    bold: true,
                  },
                ],
                rowSpan: 3,
              },
              {
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: 'นํ้าหนัก',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: 'เริ่มอบ',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: '15 นาที',
                colSpan: 2,
                alignment: 'center',
                border: [true, true, true, false],
                bold: true,
              },
              {},
              {
                text: '30 นาที',
                colSpan: 2,
                alignment: 'center',
                border: [true, true, true, false],
                bold: true,
              },
              {},
              {
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
            ],
            [
              //Row 2
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'หม้อที่',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'แรงดัน',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'อุณหภูมิ',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'แรงดัน',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'อุณหภูมิ',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'เวลาอบ',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'เวลาออก',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'หมายเหตุ',
                style: 'headerTable',
                border: [true, false, true, false],
              },
            ],
            [
              //Row 3
              {
                text: 'ค่าควบคุม',
                alignment: 'right',
                margin: [0, -1],
                bold: true,
                border: [true, false, true, true],
              },
              { text: '', margin: [0, -1], border: [true, false, true, true] },
              {
                text: '25-30(ตัน)',
                alignment: 'center',
                margin: [0, -1],
                bold: true,
                border: [true, false, true, true],
              },
              {
                text: 'เวลา45-60M',
                alignment: 'center',
                margin: [0, -1],
                bold: true,
                border: [true, false, true, true],
              },
              {
                text: '0.80-1.20 bar',
                bold: true,
                alignment: 'center',
                margin: [0, -1],
                border: [true, false, true, true],
              },
              {
                text: '100-110 ํC',
                bold: true,
                alignment: 'center',
                margin: [0, -1],
                border: [true, false, true, true],
              },
              {
                text: '1.7-2.7 bar',
                bold: true,
                alignment: 'center',
                margin: [0, -1],
                border: [true, false, true, true],
              },
              {
                text: '130-140 ํC',
                bold: true,
                alignment: 'center',
                margin: [0, -1],
                border: [true, false, true, true],
              },
              { text: '', margin: [0, -1], border: [true, false, true, true] },
              { text: '', margin: [0, -1], border: [true, false, true, true] },
              {
                text: '',
                margin: [0, -1],
                border: [true, false, true, true],
              },
            ],
            ...TableBody,
          ],
        },
      },
      {
        alignment: 'between',
        table: {
          widths: [
            '9%',
            '6%',
            '9%',
            '9%',
            '11%',
            '9%',
            '9%',
            '9%',
            '7%',
            '8%',
            '14%',
          ],
          body: [
            [
              //Row 1
              {
                text: Intl.NumberFormat('en-US').format(Totalweight) || '',
                alignment: 'center',
                style: 'header',
                colSpan: 3,
                border: [true, false, false, true],
              },
              {},
              {},
              {
                text: 'รวม',
                alignment: 'center',
                style: 'header',
                border: [true, false, true, true],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },

              { text: '', border: [false, false, true, false] },
            ],
            [
              {
                text: 'หมายเหตุ          ....................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................',
                alignment: 'left',
                bold: true,
                margin: [0, 0, 0, 3],
                border: [true, false, true, true],
                colSpan: 11,
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
          ],
        },
      },
      {
        alignment: 'between',
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: '\nผู้รายงานกะ A 1.ลงชื่อ................................ 2.ลงชื่อ...............................',
                alignment: 'left',

                border: [true, true, true, false],
              },
              {
                text: '\nผู้รายงานกะ B 1.ลงชื่อ................................ 2.ลงชื่อ...............................',

                alignment: 'center',
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: '    (.......................................)    (.......................................)  ',
                alignment: 'center',
                border: [true, false, true, false],
              },
              {
                text: '    (.......................................)    (.......................................)  ',
                alignment: 'center',
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: 'หัวหน้างานกะ A ลงชื่อ.............................................',
                alignment: 'left',

                border: [true, false, true, false],
              },
              {
                text: 'หัวหน้างานกะ B ลงชื่อ.......................................',

                alignment: 'left',
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: '(.........................................................)   ',
                alignment: 'center',
                border: [true, false, true, true],
              },
              {
                text: '(.........................................................)    ',
                alignment: 'center',
                border: [true, false, true, true],
              },
            ],
          ],
        },
        styles: {
          header: {
            fontSize: 8,
            bold: true,
            alignment: 'right',
          },
          header1: {
            fontSize: 12,
            bold: true,
          },
        },
      },
    ],

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: { font: 'FontAwesome' },
      header: {
        fontSize: 14,
        bold: true,
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        fontSize: 12,
        margin: [0, -2],
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 14,
        margin: [0, -2],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_PD_001

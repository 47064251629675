export const MotorType = {
  Motor: {
    value: 'MotorType',
    describe: 'Motor',
  },

  MotorSrc: [
    {
      name: 'Brake Invert Motor',
      value: 'BrakeInvertmotor.svg',
    },
    {
      name: 'Brake Motor',
      value: 'Brakemotor.svg',
    },
    {
      name: 'Inverter Drive',
      value: 'Inverterdrive.svg',
    },
    {
      name: 'Inverter Invert Drive',
      value: 'InverterInvertdrive.svg',
    },
    {
      name: 'Motor 1',
      value: 'Motor1.svg',
    },
    {
      name: 'Motor 2',
      value: 'Motor2.svg',
    },
    {
      name: 'Motor 3',
      value: 'Motor3.svg',
    },
    {
      name: 'Motor 4',
      value: 'Motor4.svg',
    },
    {
      name: 'Motor 5',
      value: 'Motor5.svg',
    },
    {
      name: 'Motor Invert 1',
      value: 'MotorInvert1.svg',
    },
    {
      name: 'Motor Invert 2',
      value: 'MotorInvert2.svg',
    },
    {
      name: 'Motor Invert 3',
      value: 'MotorInvert3.svg',
    },
    {
      name: 'Motor Invert 4',
      value: 'MotorInvert4.svg',
    },
    {
      name: 'Motor Invert 5',
      value: 'MotorInvert5.svg',
    },
    {
      name: 'Servo Motor 1',
      value: 'Servomotor1.svg',
    },
    {
      name: 'Servo Motor 2',
      value: 'Servomotor2.svg',
    },
    {
      name: 'Servo Motor Invert 1',
      value: 'ServomotorInvert1.svg',
    },
    {
      name: 'Servo Motor Invert 2',
      value: 'ServomotorInvert2.svg',
    },
    {
      name: 'Stepping Motor',
      value: 'Steppingmotor.svg',
    },
    {
      name: 'Stepping Motor Invert',
      value: 'SteppingmotorInvert.svg',
    },
  ],
}

import StaticBoolean from './EditingStaticBooleanDisplay'
import StaticMeter from './EditStaticMeterDataDisplay'
import StaticMeterClassic from './EditStaticMeterClassicDataDisplay'
import StaticMeterColors from './EditStaticMeterColorsDataDisplay'
import StaticNumber from './EditStaticNumberDisplay'
import CountingNumberDisplay from './EditCountingNumberDisplay'
import StaticString from './EditStaticStringDisplay'
import StaticBooleanWater from './EditStaticBooleanWaterDisplay'

export default {
    StaticBoolean,
    StaticMeter,
    StaticMeterClassic,
    StaticMeterColors,
    StaticNumber,
    CountingNumberDisplay,
    StaticString,
    StaticBooleanWater,
}

import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../redux/actions'
import { ErrorBoundary } from 'react-error-boundary'
// components

import Sidebar from '../components/common/Sidebar/mainSidebar'
import FooterAdmin from '../components/common/Footers/FooterAdmin'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import Fallback from '../components/error/Fallback'

// views
import ManuDashboard from '../views/dashboard/ManuDashboard'
//import Dashboard from../views/dashboard/Old-Dashboardrd'
import DashboardGroup from '../views/dashboard/Components/DashboardGroup'
import GraphGroup from '../views/dashboard/Components/GraphGroup'
import DiagramGroup from '../views/dashboard/Components/DiagramGroup'
import StationDashboard from '../views/dashboard/StationDashboard'
import StationFavorite from '../views/dashboard/StationFavorite'
import StationGraph from '../views/dashboard/StationGraph'
import NewStationDiagram from '../views/dashboard/NewStationDiagram'

import * as UserRole from '../config/UserRoles'

export default function DashboardLayout() {
  const history = useHistory()
  const authorize_role = [
    UserRole.ROLES.USER,
    UserRole.ROLES.GENERAL,
    UserRole.ROLES.ADMIN,
    UserRole.ROLES.SUPER_USER,
  ] // Role ที่ได้รับอนุญาต

  const storage_remember = JSON.parse(window.localStorage.eiot_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me) // ข้อมูลสำหรับ authorize จาก db server
  const system = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowSidebar, setIsShowSidebar] = useState(true)

  useEffect(() => {
    console.log('SystemDashboard : ACTIVE')
    dispatch(actions.meGet(storage_remember.uid)).then(() => {})
    dispatch(actions.systemInfoGet())
    return () => {
      dispatch(actions.meReset())
    }
  }, [])

  useEffect(() => {
    if (me) {
      if (me?.role !== authorize_role[1]) {
        setIsLoading(true)
        console.log('ได้รับการอนุญาตเข้าถึง')
      } else {
        console.log('ไม่ได้รับอนุญาต')
        alert('ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้')
        history.push('/')
      }
    }
    return () => {}
  }, [me])

  return isLoading ? (
    <>
      <Sidebar isShow={isShowSidebar} />
      <div
        className={
          isShowSidebar === true
            ? ' relative bg-gray-200 md:ml-64'
            : 'relative bg-gray-200'
        }
      >
        <div className="px-4 py-4 md:px-10 mx-auto w-full min-h-screen ">
          <ErrorBoundary
            FallbackComponent={Fallback}
            onReset={() => {
              history.goBack()
            }}
          >
            <Switch>
              <Route
                path="/dashboards"
                exact
                component={() => (
                  <ManuDashboard
                    sidebarStatus={isShowSidebar}
                    setIsShowSidebar={setIsShowSidebar}
                  />
                )}
              />
              <Route
                path="/dashboards/board/:stationId"
                exact
                component={() => (
                  <StationDashboard
                    sidebarStatus={isShowSidebar}
                    setIsShowSidebar={setIsShowSidebar}
                  />
                )}
              />
              <Route
                path="/dashboards/boardgroup/:stationId/:groupId"
                exact
                component={() => (
                  <DashboardGroup
                    sidebarStatus={isShowSidebar}
                    setIsShowSidebar={setIsShowSidebar}
                  />
                )}
              />
              <Route
                path="/dashboards/graphgroup/:stationId/:groupId"
                exact
                component={() => (
                  <GraphGroup
                    sidebarStatus={isShowSidebar}
                    setIsShowSidebar={setIsShowSidebar}
                  />
                )}
              />{' '}
              <Route
                path="/dashboards/diagramgroup/:stationId/:groupId"
                exact
                component={() => (
                  <DiagramGroup
                    sidebarStatus={isShowSidebar}
                    setIsShowSidebar={setIsShowSidebar}
                  />
                )}
              />{' '}
              <Route
                path="/dashboards/favorite/:stationId"
                exact
                component={() => (
                  <StationFavorite
                    sidebarStatus={isShowSidebar}
                    setIsShowSidebar={setIsShowSidebar}
                  />
                )}
              />
              <Route
                path="/dashboards/graph/:stationId"
                exact
                component={() => (
                  <StationGraph
                    sidebarStatus={isShowSidebar}
                    setIsShowSidebar={setIsShowSidebar}
                  />
                )}
              />
              <Route
                path="/dashboards/diagram/:stationId"
                exact
                component={() => (
                  <NewStationDiagram
                    sidebarStatus={isShowSidebar}
                    setIsShowSidebar={setIsShowSidebar}
                  />
                )}
              />
              <Redirect from="/darshboards" to="/dashboards" />
            </Switch>
          </ErrorBoundary>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <SpinnerLoading />
  )
}
